const countries = {
    "countries": [
        {
            "name": "Afghanistan",
            "states": [
                "Badakhshan",
                "Badghis",
                "Baghlan",
                "Balkh",
                "Bamyan",
                "Daykundi",
                "Farah",
                "Faryab",
                "Ghazni",
                "Ghor",
                "Helmand",
                "Herat",
                "Jowzjan",
                "Kabul",
                "Kandahar",
                "Kapisa",
                "Khost",
                "Kunar",
                "Kunduz",
                "Laghman",
                "Logar",
                "Nangarhar",
                "Nimroz",
                "Nuristan",
                "Paktia",
                "Paktika",
                "Panjshir",
                "Parwan",
                "Samangan",
                "Sar-e Pol",
                "Takhar",
                "Urozgan",
                "Wardak",
                "Zabul"
            ]
        },
        {
            "name": "Albania",
            "states": [
                "Berat",
                "Dibër",
                "Durrës",
                "Elbasan",
                "Fier",
                "Gjirokastër",
                "Korçë",
                "Kukës",
                "Lezhë",
                "Shkodër",
                "Tiranë",
                "Vlorë"
            ]
        },
        {
            "name": "Andorra",
            "states": [
                "Andorra la Vella",
                "Canillo",
                "Encamp",
                "Escaldes-Engordany",
                "La Massana",
                "Ordino",
                "Sant Julià de Lòria"
            ]
        },
        {
            "name": "Argentina",
            "states": [
                "Buenos Aires",
                "Catamarca",
                "Chaco",
                "Chubut",
                "Córdoba",
                "Corrientes",
                "Entre Ríos",
                "Formosa",
                "Jujuy",
                "La Pampa",
                "La Rioja",
                "Mendoza",
                "Misiones",
                "Neuquén",
                "Río Negro",
                "Salta",
                "San Juan",
                "San Luis",
                "Santa Cruz",
                "Santa Fe",
                "Santiago del Estero",
                "Tierra del Fuego",
                "Tucumán"
            ]
        },
        {
            "name": "Armenia",
            "states": [
                "Aragatsotn",
                "Ararat",
                "Armavir",
                "Gegharkunik",
                "Kotayk",
                "Lori",
                "Shirak",
                "Syunik",
                "Tavush",
                "Vayots Dzor",
                "Yerevan"
            ]
        },
        {
            "name": "Australia",
            "states": [
                "Australian Capital Territory",
                "New South Wales",
                "Northern Territory",
                "Queensland",
                "South Australia",
                "Tasmania",
                "Victoria",
                "Western Australia"
            ]
        },
        {
            "name": "Austria",
            "states": [
                "Burgenland",
                "Carinthia",
                "Lower Austria",
                "Salzburg",
                "Styria",
                "Tyrol",
                "Upper Austria",
                "Vienna",
                "Vorarlberg"
            ]
        },
        {
            "name": "Azerbaijan",
            "states": [
                "Absheron",
                "Aran",
                "Baku",
                "Ganja-Qazakh",
                "Lankaran",
                "Nakhchivan",
                "Shaki-Zaqatala",
                "Upper Karabakh",
                "Yukhari-Karabakh"
            ]
        },
        {
            "name": "Bahrain",
            "states": [
                "Capital",
                "Central",
                "Muharraq",
                "Northern",
                "Southern"
            ]
        },
        {
            "name": "Bangladesh",
            "states": [
                "Barisal",
                "Chittagong",
                "Dhaka",
                "Khulna",
                "Mymensingh",
                "Rajshahi",
                "Rangpur",
                "Sylhet"
            ]
        },
        {
            "name": "Belarus",
            "states": [
                "Brest",
                "Gomel",
                "Grodno",
                "Minsk",
                "Mogilev",
                "Vitebsk"
            ]
        },
        {
            "name": "Belgium",
            "states": [
                "Antwerp",
                "East Flanders",
                "Flemish Brabant",
                "Hainaut",
                "Liège",
                "Limburg",
                "Luxembourg",
                "Namur",
                "Walloon Brabant",
                "West Flanders"
            ]
        },
        {
            "name": "Bhutan",
            "states": [
                "Bumthang",
                "Chukha",
                "Dagana",
                "Gasa",
                "Haa",
                "Lhuntse",
                "Mongar",
                "Paro",
                "Pemagatshel",
                "Punakha",
                "Samdrup Jongkhar",
                "Samtse",
                "Sarpang",
                "Thimphu",
                "Trashigang",
                "Trashiyangtse",
                "Trongsa",
                "Tsirang",
                "Wangdue Phodrang",
                "Zhemgang"
            ]
        },
        {
            "name": "Bolivia",
            "states": [
                "Chuquisaca",
                "Cochabamba",
                "El Beni",
                "La Paz",
                "Oruro",
                "Pando",
                "Potosí",
                "Santa Cruz",
                "Tarija"
            ]
        },
        {
            "name": "Bosnia and Herzegovina",
            "states": [
                "Una-Sana",
                "Posavina",
                "Tuzla",
                "Zenica-Doboj",
                "Bosnian Podrinje",
                "Central Bosnia",
                "Herzegovina-Neretva",
                "West Herzegovina",
                "Sarajevo",
                "West Bosnia"
            ]
        },
        {
            "name": "Brazil",
            "states": [
                "Acre",
                "Alagoas",
                "Amapá",
                "Amazonas",
                "Bahia",
                "Ceará",
                "Distrito Federal",
                "Espírito Santo",
                "Goiás",
                "Maranhão",
                "Mato Grosso",
                "Mato Grosso do Sul",
                "Minas Gerais",
                "Pará",
                "Paraíba",
                "Paraná",
                "Pernambuco",
                "Piauí",
                "Rio de Janeiro",
                "Rio Grande do Norte",
                "Rio Grande do Sul",
                "Rondônia",
                "Roraima",
                "Santa Catarina",
                "São Paulo",
                "Sergipe",
                "Tocantins"
            ]
        },
        {
            "name": "Brunei",
            "states": [
                "Belait",
                "Brunei-Muara",
                "Temburong",
                "Tutong"
            ]
        },
        {
            "name": "Bulgaria",
            "states": [
                "Blagoevgrad",
                "Burgas",
                "Dobrich",
                "Gabrovo",
                "Haskovo",
                "Kardzhali",
                "Kyustendil",
                "Lovech",
                "Montana",
                "Pazardzhik",
                "Pernik",
                "Pleven",
                "Plovdiv",
                "Razgrad",
                "Ruse",
                "Shumen",
                "Silistra",
                "Sliven",
                "Smolyan",
                "Sofia",
                "Stara Zagora",
                "Targovishte",
                "Varna",
                "Veliko Tarnovo",
                "Vidin",
                "Vratsa",
                "Yambol"
            ]
        },
        {
            "name": "Cambodia",
            "states": [
                "Banteay Meanchey",
                "Battambang",
                "Kampong Cham",
                "Kampong Chhnang",
                "Kampong Speu",
                "Kampong Thom",
                "Kampot",
                "Kandal",
                "Koh Kong",
                "Kratie",
                "Mondulkiri",
                "Oddar Meanchey",
                "Pailin",
                "Phnom Penh",
                "Preah Vihear",
                "Prey Veng",
                "Pursat",
                "Ratanakiri",
                "Siem Reap",
                "Preah Sihanouk",
                "Stung Treng",
                "Svay Rieng",
                "Takeo",
                "Tbong Khmum"
            ]
        },
        {
            "name": "Canada",
            "states": [
                "Alberta",
                "British Columbia",
                "Manitoba",
                "New Brunswick",
                "Newfoundland and Labrador",
                "Nova Scotia",
                "Ontario",
                "Prince Edward Island",
                "Quebec",
                "Saskatchewan"
            ]
        },
        {
            "name": "Chile",
            "states": [
                "Arica y Parinacota",
                "Tarapacá",
                "Antofagasta",
                "Atacama",
                "Coquimbo",
                "Valparaíso",
                "Región Metropolitana de Santiago",
                "Libertador General Bernardo O'Higgins",
                "Maule",
                "Ñuble",
                "Biobío",
                "La Araucanía",
                "Los Ríos",
                "Los Lagos",
                "Aysén",
                "Magallanes y la Antártica Chilena"
            ]
        },
        {
            "name": "China",
            "states": [
                "Anhui",
                "Beijing",
                "Chongqing",
                "Fujian",
                "Gansu",
                "Guangdong",
                "Guangxi",
                "Guizhou",
                "Hainan",
                "Hebei",
                "Heilongjiang",
                "Henan",
                "Hong Kong",
                "Hubei",
                "Hunan",
                "Inner Mongolia",
                "Jiangsu",
                "Jiangxi",
                "Jilin",
                "Liaoning",
                "Macau",
                "Ningxia",
                "Qinghai",
                "Shaanxi",
                "Shandong",
                "Shanghai",
                "Shanxi",
                "Sichuan",
                "Tianjin",
                "Tibet",
                "Xinjiang",
                "Yunnan",
                "Zhejiang"
            ]
        },
        {
            "name": "Colombia",
            "states": [
                "Amazonas",
                "Antioquia",
                "Arauca",
                "Atlántico",
                "Bolívar",
                "Boyacá",
                "Caldas",
                "Caquetá",
                "Casanare",
                "Cauca",
                "Cesar",
                "Chocó",
                "Córdoba",
                "Cundinamarca",
                "Guainía",
                "Guaviare",
                "Huila",
                "La Guajira",
                "Magdalena",
                "Meta",
                "Nariño",
                "Norte de Santander",
                "Putumayo",
                "Quindío",
                "Risaralda",
                "San Andrés y Providencia",
                "Santander",
                "Sucre",
                "Tolima",
                "Valle del Cauca",
                "Vaupés",
                "Vichada"
            ]
        },
        {
            "name": "Croatia",
            "states": [
                "Bjelovar-Bilogora",
                "Brod-Posavina",
                "Dubrovnik-Neretva",
                "Istria",
                "Karlovac",
                "Koprivnica-Križevci",
                "Krapina-Zagorje",
                "Lika-Senj",
                "Međimurje",
                "Osijek-Baranja",
                "Požega-Slavonia",
                "Primorje-Gorski Kotar",
                "Šibenik-Knin",
                "Sisak-Moslavina",
                "Split-Dalmatia",
                "Varaždin",
                "Virovitica-Podravina",
                "Vukovar-Syrmia",
                "Zadar",
                "Zagreb",
                "Zagreb City"
            ]
        },
        {
            "name": "Cyprus",
            "states": [
                "Famagusta",
                "Kyrenia",
                "Larnaca",
                "Limassol",
                "Nicosia",
                "Paphos"
            ]
        },
        {
            "name": "Czech Republic",
            "states": [
                "Central Bohemian",
                "Hradec Králové",
                "Karlovy Vary",
                "Liberec",
                "Moravian-Silesian",
                "Olomouc",
                "Pardubice",
                "Plzeň",
                "Prague",
                "South Bohemian",
                "South Moravian",
                "Ústí nad Labem",
                "Vysočina",
                "Zlín"
            ]
        },
        {
            "name": "Denmark",
            "states": [
                "Capital",
                "Central Jutland",
                "North Denmark",
                "South Denmark",
                "Zealand"
            ]
        },
        {
            "name": "Ecuador",
            "states": [
                "Azuay",
                "Bolívar",
                "Cañar",
                "Carchi",
                "Chimborazo",
                "Cotopaxi",
                "El Oro",
                "Esmeraldas",
                "Galápagos",
                "Guayas",
                "Imbabura",
                "Loja",
                "Los Ríos",
                "Manabí",
                "Morona Santiago",
                "Napo",
                "Orellana",
                "Pastaza",
                "Pichincha",
                "Santa Elena",
                "Santo Domingo de los Tsáchilas",
                "Sucumbíos",
                "Tungurahua",
                "Zamora Chinchipe"
            ]
        },
        {
            "name": "Estonia",
            "states": [
                "Harju",
                "Hiiu",
                "Ida-Viru",
                "Järva",
                "Jõgeva",
                "Lääne",
                "Lääne-Viru",
                "Põlva",
                "Pärnu",
                "Rapla",
                "Saare",
                "Tartu",
                "Valga",
                "Viljandi",
                "Võru"
            ]
        },
        {
            "name": "Fiji",
            "states": [
                "Ba",
                "Bua",
                "Cakaudrove",
                "Kadavu",
                "Lau",
                "Lomaiviti",
                "Macuata",
                "Nadroga-Navosa",
                "Naitasiri",
                "Namosi",
                "Ra",
                "Rewa",
                "Rotuma",
                "Serua",
                "Tailevu"
            ]
        },
        {
            "name": "Finland",
            "states": [
                "Åland Islands",
                "Central Finland",
                "Central Ostrobothnia",
                "Eastern Finland",
                "Kainuu",
                "Kymenlaakso",
                "Lapland",
                "North Karelia",
                "Northern Ostrobothnia",
                "Ostrobothnia",
                "Pirkanmaa",
                "Satakunta",
                "South Karelia",
                "Southern Ostrobothnia",
                "Southern Savonia",
                "Uusimaa",
                "Varsinais-Suomi"
            ]
        },
        {
            "name": "France",
            "states": [
                "Auvergne-Rhône-Alpes",
                "Bourgogne-Franche-Comté",
                "Brittany",
                "Centre-Val de Loire",
                "Corsica",
                "Grand Est",
                "Hauts-de-France",
                "Île-de-France",
                "Normandy",
                "Nouvelle-Aquitaine",
                "Occitanie",
                "Pays de la Loire",
                "Provence-Alpes-Côte d'Azur"
            ]
        },
        {
            "name": "Georgia",
            "states": [
                "Abkhazia",
                "Adjara",
                "Guria",
                "Imereti",
                "Kakheti",
                "Kvemo Kartli",
                "Mtskheta-Mtianeti",
                "Racha-Lechkhumi and Kvemo Svaneti",
                "Samegrelo-Zemo Svaneti",
                "Samtskhe-Javakheti",
                "Shida Kartli",
                "Tbilisi"
            ]
        },
        {
            "name": "Germany",
            "states": [
                "Baden-Württemberg",
                "Bavaria",
                "Berlin",
                "Brandenburg",
                "Bremen",
                "Hamburg",
                "Hesse",
                "Lower Saxony",
                "Mecklenburg-Vorpommern",
                "North Rhine-Westphalia",
                "Rhineland-Palatinate",
                "Saarland",
                "Saxony",
                "Saxony-Anhalt",
                "Schleswig-Holstein",
                "Thuringia"
            ]
        },
        {
            "name": "Greece",
            "states": [
                "Achaea",
                "Aetolia-Acarnania",
                "Arcadia",
                "Argolis",
                "Arta",
                "Attica",
                "Boeotia",
                "Chalkidiki",
                "Chania",
                "Chios",
                "Corfu",
                "Corinthia",
                "Crete",
                "Drama",
                "Elis",
                "Euboea",
                "Florina",
                "Grevena",
                "Imathia",
                "Ioannina",
                "Karditsa",
                "Kastoria",
                "Kavala",
                "Kefalonia",
                "Kilkis",
                "Kozani",
                "Laconia",
                "Larissa",
                "Lasithi",
                "Lefkada",
                "Lesbos",
                "Magnesia",
                "Messenia",
                "Pella",
                "Phocis",
                "Phthiotis",
                "Pieria",
                "Preveza",
                "Rethymno",
                "Rhodes",
                "Serres",
                "Thesprotia",
                "Thessaloniki",
                "Trikala",
                "Xanthi",
                "Zakynthos"
            ]
        },
        {
            "name": "Guyana",
            "states": [
                "Barima-Waini",
                "Cuyuni-Mazaruni",
                "Demerara-Mahaica",
                "East Berbice-Corentyne",
                "Essequibo Islands-West Demerara",
                "Mahaica-Berbice",
                "Pomeroon-Supenaam",
                "Potaro-Siparuni",
                "Upper Demerara-Berbice",
                "Upper Takutu-Upper Essequibo"
            ]
        },
        {
            "name": "Hungary",
            "states": [
                "Bács-Kiskun",
                "Baranya",
                "Békés",
                "Békéscsaba",
                "Borsod-Abaúj-Zemplén",
                "Budapest",
                "Csongrád-Csanád",
                "Debrecen",
                "Dunaújváros",
                "Eger",
                "Fejér",
                "Győr",
                "Győr-Moson-Sopron",
                "Hajdú-Bihar",
                "Heves",
                "Hódmezővásárhely",
                "Jász-Nagykun-Szolnok",
                "Kaposvár",
                "Kecskemét",
                "Komárom-Esztergom",
                "Miskolc",
                "Nagykanizsa",
                "Nógrád",
                "Nyíregyháza",
                "Pécs",
                "Pest",
                "Salgótarján",
                "Somogy",
                "Sopron",
                "Szabolcs-Szatmár-Bereg",
                "Szeged",
                "Székesfehérvár",
                "Szekszárd",
                "Szolnok",
                "Szombathely",
                "Tatabánya",
                "Tolna",
                "Vas",
                "Veszprém",
                "Zala",
                "Zalaegerszeg"
            ]
        },
        {
            "name": "Iceland",
            "states": [
                "Capital Region",
                "Eastern Region",
                "Northeastern Region",
                "Northwestern Region",
                "Southern Peninsula",
                "Southern Region",
                "Western Region",
                "Westfjords"
            ]
        },
        {
            "name": "India",
            "states": [
                "Andhra Pradesh",
                "Arunachal Pradesh",
                "Assam",
                "Bihar",
                "Chhattisgarh",
                "Goa",
                "Gujarat",
                "Haryana",
                "Himachal Pradesh",
                "Jharkhand",
                "Karnataka",
                "Kerala",
                "Madhya Pradesh",
                "Maharashtra",
                "Manipur",
                "Meghalaya",
                "Mizoram",
                "Nagaland",
                "Odisha",
                "Punjab",
                "Rajasthan",
                "Sikkim",
                "Tamil Nadu",
                "Telangana",
                "Tripura",
                "Uttar Pradesh",
                "Uttarakhand",
                "West Bengal",
                "Andaman and Nicobar Islands",
                "Chandigarh",
                "Dadra and Nagar Haveli and Daman and Diu",
                "Lakshadweep",
                "Delhi",
                "Puducherry",
                "Ladakh",
                "Jammu and Kashmir"
            ]
        },
        {
            "name": "Indonesia",
            "states": [
                "Aceh",
                "Bali",
                "Bangka Belitung Islands",
                "Banten",
                "Bengkulu",
                "Central Java",
                "Central Kalimantan",
                "Central Sulawesi",
                "East Java",
                "East Kalimantan",
                "East Nusa Tenggara",
                "Gorontalo",
                "Jakarta",
                "Jambi",
                "Lampung",
                "Maluku",
                "North Kalimantan",
                "North Maluku",
                "North Sulawesi",
                "North Sumatra",
                "Papua",
                "Riau",
                "Riau Islands",
                "Southeast Sulawesi",
                "South Kalimantan",
                "South Sulawesi",
                "South Sumatra",
                "West Java",
                "West Kalimantan",
                "West Nusa Tenggara",
                "West Papua",
                "West Sulawesi",
                "West Sumatra",
                "Yogyakarta"
            ]
        },
        {
            "name": "Iran",
            "states": [
                "Alborz",
                "Ardabil",
                "Bushehr",
                "Chaharmahal and Bakhtiari",
                "East Azerbaijan",
                "Fars",
                "Gilan",
                "Golestan",
                "Hamadan",
                "Hormozgan",
                "Ilam",
                "Isfahan",
                "Kerman",
                "Kermanshah",
                "Khuzestan",
                "Kohgiluyeh and Boyer-Ahmad",
                "Kurdistan",
                "Lorestan",
                "Markazi",
                "Mazandaran",
                "North Khorasan",
                "Qazvin",
                "Qom",
                "Razavi Khorasan",
                "Semnan",
                "Sistan and Baluchestan",
                "South Khorasan",
                "Tehran",
                "West Azerbaijan",
                "Yazd",
                "Zanjan"
            ]
        },
        {
            "name": "Iraq",
            "states": [
                "Al Anbar",
                "Al Muthanna",
                "Al-Qadisiyyah",
                "Babylon",
                "Baghdad",
                "Basra",
                "Dhi Qar",
                "Diyala",
                "Dohuk",
                "Erbil",
                "Halabja",
                "Karbala",
                "Kirkuk",
                "Maysan",
                "Najaf",
                "Nineveh",
                "Saladin",
                "Sulaymaniyah",
                "Wasit"
            ]
        },
        {
            "name": "Ireland",
            "states": [
                "Carlow",
                "Cavan",
                "Clare",
                "Cork",
                "Donegal",
                "Dublin",
                "Galway",
                "Kerry",
                "Kildare",
                "Kilkenny",
                "Laois",
                "Leitrim",
                "Limerick",
                "Longford",
                "Louth",
                "Mayo",
                "Meath",
                "Monaghan",
                "Offaly",
                "Roscommon",
                "Sligo",
                "Tipperary",
                "Waterford",
                "Westmeath",
                "Wexford",
                "Wicklow"
            ]
        },
        {
            "name": "Israel",
            "states": [
                "Central District",
                "Haifa District",
                "Jerusalem District",
                "Northern District",
                "Southern District",
                "Tel Aviv District"
            ]
        },
        {
            "name": "Italy",
            "states": [
                "Abruzzo",
                "Aosta Valley",
                "Apulia",
                "Basilicata",
                "Calabria",
                "Campania",
                "Emilia-Romagna",
                "Friuli Venezia Giulia",
                "Lazio",
                "Liguria",
                "Lombardy",
                "Marche",
                "Molise",
                "Piedmont",
                "Sardinia",
                "Sicily",
                "Trentino-South Tyrol",
                "Tuscany",
                "Umbria",
                "Veneto"
            ]
        },
        {
            "name": "Japan",
            "states": [
                "Aichi",
                "Akita",
                "Aomori",
                "Chiba",
                "Ehime",
                "Fukui",
                "Fukuoka",
                "Fukushima",
                "Gifu",
                "Gunma",
                "Hiroshima",
                "Hokkaido",
                "Hyogo",
                "Ibaraki",
                "Ishikawa",
                "Iwate",
                "Kagawa",
                "Kagoshima",
                "Kanagawa",
                "Kochi",
                "Kumamoto",
                "Kyoto",
                "Mie",
                "Miyagi",
                "Miyazaki",
                "Nagano",
                "Nagasaki",
                "Nara",
                "Niigata",
                "Oita",
                "Okayama",
                "Okinawa",
                "Osaka",
                "Saga",
                "Saitama",
                "Shiga",
                "Shimane",
                "Shizuoka",
                "Tochigi",
                "Tokushima",
                "Tokyo",
                "Tottori",
                "Toyama",
                "Wakayama",
                "Yamagata",
                "Yamaguchi",
                "Yamanashi"
            ]
        },
        {
            "name": "Jordan",
            "states": [
                "Ajloun",
                "Amman",
                "Aqaba",
                "Balqa",
                "Irbid",
                "Jerash",
                "Karak",
                "Ma'an",
                "Madaba",
                "Mafraq",
                "Tafilah",
                "Zarqa"
            ]
        },
        {
            "name": "Kazakhstan",
            "states": [
                "Akmola",
                "Aktobe",
                "Almaty",
                "Atyrau",
                "Baikonur",
                "East Kazakhstan",
                "Jambyl",
                "Karaganda",
                "Kostanay",
                "Kyzylorda",
                "Mangystau",
                "North Kazakhstan",
                "Nur-Sultan",
                "Pavlodar",
                "Shymkent",
                "Turkestan",
                "West Kazakhstan"
            ]
        },
        {
            "name": "Kiribati",
            "states": [
                "Gilbert Islands",
                "Line Islands",
                "Phoenix Islands"
            ]
        },
        {
            "name": "Kosovo",
            "states": [
                "Ferizaj",
                "Gjakova",
                "Gjilan",
                "Mitrovica",
                "Peja",
                "Pristina",
                "Prizren"
            ]
        },
        {
            "name": "Kuwait",
            "states": [
                "Ahmadi",
                "Al Asimah",
                "Farwaniya",
                "Hawalli",
                "Jahra",
                "Mubarak Al-Kabeer"
            ]
        },
        {
            "name": "Kyrgyzstan",
            "states": [
                "Batken",
                "Bishkek",
                "Chuy",
                "Jalal-Abad",
                "Naryn",
                "Osh",
                "Osh City",
                "Talas",
                "Ysyk-Kol"
            ]
        },
        {
            "name": "Laos",
            "states": [
                "Attapeu",
                "Bokeo",
                "Bolikhamsai",
                "Champasak",
                "Houaphanh",
                "Khammouane",
                "Luang Namtha",
                "Luang Prabang",
                "Oudomxay",
                "Phongsaly",
                "Salavan",
                "Savannakhet",
                "Sekong",
                "Vientiane",
                "Vientiane Prefecture",
                "Xaignabouli",
                "Xaisomboun",
                "Xekong",
                "Xiangkhouang"
            ]
        },
        {
            "name": "Latvia",
            "states": [
                "Aglona",
                "Aizkraukle",
                "Aizpute",
                "Aknīste",
                "Aloja",
                "Alsunga",
                "Alūksne",
                "Amata",
                "Ape",
                "Auce",
                "Ādaži",
                "Babīte",
                "Baldone",
                "Baltinava",
                "Balvi",
                "Bauska",
                "Beverīna",
                "Brocēni",
                "Burtnieki",
                "Carnikava",
                "Cesvaine",
                "Cēsis",
                "Cibla",
                "Dagda",
                "Daugavpils",
                "Dobele",
                "Dundaga",
                "Durbe",
                "Engure",
                "Ērgļi",
                "Garkalne",
                "Grobiņa",
                "Gulbene",
                "Iecava",
                "Ikšķile",
                "Ilūkste",
                "Inčukalns",
                "Jaunjelgava",
                "Jaunpiebalga",
                "Jaunpils",
                "Jelgava",
                "Jēkabpils",
                "Kandava",
                "Kārsava",
                "Kocēni",
                "Koknese",
                "Krāslava",
                "Krimulda",
                "Krustpils",
                "Kuldīga",
                "Ķegums",
                "Ķekava",
                "Lielvārde",
                "Liepāja",
                "Limbaži",
                "Līgatne",
                "Līvāni",
                "Lubāna",
                "Ludza",
                "Madona",
                "Mazsalaca",
                "Mālpils",
                "Mārupes",
                "Mērsrags",
                "Naukšēni",
                "Nereta",
                "Nīca",
                "Ogre",
                "Olaine",
                "Ozolnieki",
                "Pārgauja",
                "Pāvilosta",
                "Pļaviņas",
                "Preiļi",
                "Priekule",
                "Priekuļi",
                "Rauna",
                "Rēzekne",
                "Riebiņi",
                "Rīga",
                "Roja",
                "Ropaži",
                "Rucava",
                "Rugāji",
                "Rundāle",
                "Rūjiena",
                "Sala",
                "Salacgrīva",
                "Salaspils",
                "Saldus",
                "Saulkrasti",
                "Sēja",
                "Sigulda",
                "Skrīveri",
                "Skrunda",
                "Smiltene",
                "Stopiņi",
                "Strenči",
                "Talsi",
                "Tērvete",
                "Tukums",
                "Vaiņode",
                "Valka",
                "Valmiera",
                "Varakļāni",
                "Vārkava",
                "Vecpiebalga",
                "Vecumnieki",
                "Ventspils",
                "Viesīte",
                "Viļaka",
                "Viļāni",
                "Zilupe"
            ]
        },
        {
            "name": "Lebanon",
            "states": [
                "Akkar",
                "Baalbek-Hermel",
                "Beirut",
                "Beqaa",
                "Keserwan-Jbeil",
                "Mount Lebanon",
                "North Lebanon",
                "South Lebanon"
            ]
        },
        {
            "name": "Lithuania",
            "states": [
                "Alytus",
                "Kaunas",
                "Klaipėda",
                "Marijampolė",
                "Panevėžys",
                "Šiauliai",
                "Tauragė",
                "Telšiai",
                "Utena",
                "Vilnius"
            ]
        },
        {
            "name": "Luxembourg",
            "states": [
                "Canton of Capellen",
                "Canton of Clervaux",
                "Canton of Diekirch",
                "Canton of Echternach",
                "Canton of Esch-sur-Alzette",
                "Canton of Grevenmacher",
                "Canton of Luxembourg",
                "Canton of Mersch",
                "Canton of Redange",
                "Canton of Remich",
                "Canton of Vianden",
                "Canton of Wiltz"
            ]
        },
        {
            "name": "Malaysia",
            "states": [
                "Johor",
                "Kedah",
                "Kelantan",
                "Kuala Lumpur",
                "Labuan",
                "Malacca",
                "Negeri Sembilan",
                "Pahang",
                "Perak",
                "Perlis",
                "Penang",
                "Putrajaya",
                "Sabah",
                "Sarawak",
                "Selangor",
                "Terengganu"
            ]
        },
        {
            "name": "Maldives",
            "states": [
                "Alif Alif",
                "Alif Dhaal",
                "Baa",
                "Dhaal",
                "Faafu",
                "Gaafu Alif",
                "Gaafu Dhaal",
                "Gnaviyani",
                "Haa Alif",
                "Haa Dhaal",
                "Kaafu",
                "Laamu",
                "Lhaviyani",
                "Malé",
                "Meemu",
                "Noonu",
                "Raa",
                "Seenu",
                "Shaviyani",
                "Thaa",
                "Vaavu"
            ]
        },
        {
            "name": "Malta",
            "states": [
                "Attard",
                "Balzan",
                "Birgu",
                "Birkirkara",
                "Birżebbuġa",
                "Bormla",
                "Dingli",
                "Fgura",
                "Floriana",
                "Fontana",
                "Għajnsielem",
                "Għarb",
                "Għargħur",
                "Għasri",
                "Għaxaq",
                "Gudja",
                "Gżira",
                "Ħamrun",
                "Iklin",
                "Isla",
                "Kalkara",
                "Kerċem",
                "Kirkop",
                "Lija",
                "Luqa",
                "Marsa",
                "Marsaskala",
                "Marsaxlokk",
                "Mdina",
                "Mellieħa",
                "Mġarr",
                "Mosta",
                "Mqabba",
                "Msida",
                "Mtarfa",
                "Munxar",
                "Nadur",
                "Naxxar",
                "Paola",
                "Pembroke",
                "Pietà",
                "Qala",
                "Qormi",
                "Qrendi",
                "Rabat",
                "Safi",
                "San Ġiljan",
                "San Ġwann",
                "San Lawrenz",
                "San Pawl il-Baħar",
                "Sannat",
                "Santa Luċija",
                "Santa Venera",
                "Siġġiewi",
                "Sliema",
                "Swieqi",
                "Ta' Xbiex",
                "Tarxien",
                "Valletta",
                "Victoria",
                "Xagħra",
                "Xewkija",
                "Xgħajra",
                "Żabbar",
                "Żebbuġ",
                "Żejtun",
                "Żurrieq"
            ]
        },
        {
            "name": "Marshall Islands",
            "states": [
                "Ailinglaplap",
                "Ailuk",
                "Arno",
                "Aur",
                "Bikini",
                "Ebon",
                "Enewetak",
                "Jabat",
                "Jaluit",
                "Kili",
                "Kwajalein",
                "Lae",
                "Lib",
                "Likiep",
                "Majuro",
                "Maloelap",
                "Mejit",
                "Mili",
                "Namdrik",
                "Namu",
                "Rongelap",
                "Ujae",
                "Utrik",
                "Wotho",
                "Wotje"
            ]
        },
        {
            "name": "Mexico",
            "states": [
                "Aguascalientes",
                "Baja California",
                "Baja California Sur",
                "Campeche",
                "Chiapas",
                "Chihuahua",
                "Coahuila",
                "Colima",
                "Durango",
                "Guanajuato",
                "Guerrero",
                "Hidalgo",
                "Jalisco",
                "Mexico City",
                "Michoacán",
                "Morelos",
                "Nayarit",
                "Nuevo León",
                "Oaxaca",
                "Puebla",
                "Querétaro",
                "Quintana Roo",
                "San Luis Potosí",
                "Sinaloa",
                "Sonora",
                "Tabasco",
                "Tamaulipas",
                "Tlaxcala",
                "Veracruz",
                "Yucatán",
                "Zacatecas"
            ]
        },
        {
            "name": "Micronesia",
            "states": [
                "Chuuk",
                "Kosrae",
                "Pohnpei",
                "Yap"
            ]
        },
        {
            "name": "Moldova",
            "states": [
                "Anenii Noi",
                "Basarabeasca",
                "Bender",
                "Briceni",
                "Cahul",
                "Călărași",
                "Cantemir",
                "Căușeni",
                "Chișinău",
                "Cimișlia",
                "Criuleni",
                "Dondușeni",
                "Drochia",
                "Dubăsari",
                "Edineț",
                "Fălești",
                "Florești",
                "Găgăuzia",
                "Glodeni",
                "Hîncești",
                "Ialoveni",
                "Leova",
                "Nisporeni",
                "Ocnița",
                "Orhei",
                "Rezina",
                "Rîbnița",
                "Rîșcani",
                "Sîngerei",
                "Șoldănești",
                "Soroca",
                "Ștefan Vodă",
                "Strășeni",
                "Taraclia",
                "Telenești",
                "Transnistria",
                "Ungheni"
            ]
        },
        {
            "name": "Monaco",
            "states": [
                "Fontvieille",
                "Jardin Exotique",
                "La Condamine",
                "La Rousse",
                "Larvotto",
                "Monaco-Ville",
                "Moneghetti",
                "Monte Carlo",
                "Port Hercule",
                "Saint Michel",
                "Sainte-Dévote"
            ]
        },
        {
            "name": "Mongolia",
            "states": [
                "Arkhangai",
                "Bayan-Ölgii",
                "Bayankhongor",
                "Bulgan",
                "Darkhan-Uul",
                "Dornod",
                "Dornogovi",
                "Dundgovi",
                "Govi-Altai",
                "Govisümber",
                "Khentii",
                "Khovd",
                "Khövsgöl",
                "Orkhon",
                "Ömnögovi",
                "Övörkhangai",
                "Selenge",
                "Sükhbaatar",
                "Töv",
                "Uvs",
                "Zavkhan",
                "Ulaanbaatar"
            ]
        },
        {
            "name": "Montenegro",
            "states": [
                "Andrijevica",
                "Bar",
                "Berane",
                "Bijelo Polje",
                "Budva",
                "Cetinje",
                "Danilovgrad",
                "Gusinje",
                "Herceg Novi",
                "Kolašin",
                "Kotor",
                "Mojkovac",
                "Nikšić",
                "Petnjica",
                "Plav",
                "Plužine",
                "Pljevlja",
                "Podgorica",
                "Rožaje",
                "Šavnik",
                "Tivat",
                "Tuzi",
                "Ulcinj",
                "Žabljak"
            ]
        },
        {
            "name": "Myanmar",
            "states": [
                "Ayeyarwady",
                "Bago",
                "Chin",
                "Kachin",
                "Kayah",
                "Kayin",
                "Magway",
                "Mandalay",
                "Mon",
                "Naypyidaw",
                "Rakhine",
                "Sagaing",
                "Shan",
                "Tanintharyi",
                "Yangon"
            ]
        },
        {
            "name": "Nauru",
            "states": [
                "Aiwo",
                "Anabar",
                "Anetan",
                "Anibare",
                "Baiti",
                "Boe",
                "Buada",
                "Denigomodu",
                "Ewa",
                "Ijuw",
                "Meneng",
                "Nibok",
                "Uaboe",
                "Yaren"
            ]
        },
        {
            "name": "Nepal",
            "states": [
                "Bagmati",
                "Bheri",
                "Dhawalagiri",
                "Gandaki",
                "Janakpur",
                "Karnali",
                "Kosi",
                "Lumbini",
                "Mahakali",
                "Mechi",
                "Narayani",
                "Rapti",
                "Sagarmatha",
                "Seti"
            ]
        },
        {
            "name": "Netherlands",
            "states": [
                "Drenthe",
                "Flevoland",
                "Friesland",
                "Gelderland",
                "Groningen",
                "Limburg",
                "North Brabant",
                "North Holland",
                "Overijssel",
                "South Holland",
                "Utrecht",
                "Zeeland"
            ]
        },
        {
            "name": "New Zealand",
            "states": [
                "Auckland",
                "Bay of Plenty",
                "Canterbury",
                "Gisborne",
                "Hawke's Bay",
                "Manawatu-Wanganui",
                "Marlborough",
                "Nelson",
                "Northland",
                "Otago",
                "Southland",
                "Taranaki",
                "Tasman",
                "Waikato",
                "Wellington",
                "West Coast"
            ]
        },
        {
            "name": "North Korea",
            "states": [
                "Chagang",
                "Kangwon",
                "North Hamgyong",
                "North Hwanghae",
                "North Pyongan",
                "Pyongyang",
                "Rason",
                "South Hamgyong",
                "South Hwanghae",
                "South Pyongan"
            ]
        },
        {
            "name": "North Macedonia",
            "states": [
                "Aerodrom",
                "Aračinovo",
                "Berovo",
                "Bitola",
                "Bogdanci",
                "Bogovinje",
                "Bosilovo",
                "Brvenica",
                "Butel",
                "Čair",
                "Čaška",
                "Centar",
                "Centar Župa",
                "Češinovo-Obleševo",
                "Čučer-Sandevo",
                "Debar",
                "Debarca",
                "Delčevo",
                "Demir Hisar",
                "Demir Kapija",
                "Dojran",
                "Dolneni",
                "Drugovo",
                "Gazi Baba",
                "Gevgelija",
                "Gjorče Petrov",
                "Gostivar",
                "Gradsko",
                "Ilinden",
                "Jegunovce",
                "Karbinci",
                "Karpoš",
                "Kavadarci",
                "Kičevo",
                "Konče",
                "Kočani",
                "Kratovo",
                "Kriva Palanka",
                "Krivogaštani",
                "Kruševo",
                "Kumanovo",
                "Lipkovo",
                "Lozovo",
                "Makedonska Kamenica",
                "Makedonski Brod",
                "Mavrovo and Rostuša",
                "Mogila",
                "Negotino",
                "Novaci",
                "Novo Selo",
                "Ohrid",
                "Petrovec",
                "Pehčevo",
                "Plasnica",
                "Prilep",
                "Probištip",
                "Radoviš",
                "Rankovce",
                "Resen",
                "Rosoman",
                "Saraj",
                "Sopište",
                "Staro Nagoričane",
                "Štip",
                "Struga",
                "Strumica",
                "Studeničani",
                "Šuto Orizari",
                "Sveti Nikole",
                "Tearce",
                "Tetovo",
                "Valandovo",
                "Vasilevo",
                "Veles",
                "Vevčani",
                "Vinica",
                "Vrapčište",
                "Zelenikovo",
                "Zrnovci"
            ]
        },
        {
            "name": "Norway",
            "states": [
                "Agder",
                "Innlandet",
                "Møre og Romsdal",
                "Nordland",
                "Oslo",
                "Rogaland",
                "Troms og Finnmark",
                "Trøndelag",
                "Vestfold og Telemark",
                "Vestland",
                "Viken"
            ]
        },
        {
            "name": "Oman",
            "states": [
                "Ad Dakhiliyah",
                "Al Batinah North",
                "Al Batinah South",
                "Al Buraimi",
                "Al Dhahirah",
                "Al Wusta",
                "Ash Sharqiyah North",
                "Ash Sharqiyah South",
                "Dhofar",
                "Muscat",
                "Musandam"
            ]
        },
        {
            "name": "Pakistan",
            "states": [
                "Azad Kashmir",
                "Balochistan",
                "Gilgit-Baltistan",
                "Islamabad",
                "Khyber Pakhtunkhwa",
                "Punjab",
                "Sindh"
            ]
        },
        {
            "name": "Palestine",
            "states": [
                "Bethlehem",
                "Deir al-Balah",
                "Gaza",
                "Hebron",
                "Jenin",
                "Jericho and Al Aghwar",
                "Jerusalem",
                "Khan Yunis",
                "Nablus",
                "North Gaza",
                "Qalqilya",
                "Rafah",
                "Ramallah and Al Bireh",
                "Salfit",
                "Tubas",
                "Tulkarm"
            ]
        },
        {
            "name": "Paraguay",
            "states": [
                "Alto Paraguay",
                "Alto Paraná",
                "Amambay",
                "Asunción",
                "Boquerón",
                "Caaguazú",
                "Caazapá",
                "Canindeyú",
                "Central",
                "Concepción",
                "Cordillera",
                "Guairá",
                "Itapúa",
                "Misiones",
                "Ñeembucú",
                "Paraguarí",
                "Presidente Hayes",
                "San Pedro"
            ]
        },
        {
            "name": "Peru",
            "states": [
                "Amazonas",
                "Áncash",
                "Apurímac",
                "Arequipa",
                "Ayacucho",
                "Cajamarca",
                "Callao",
                "Cusco",
                "Huancavelica",
                "Huánuco",
                "Ica",
                "Junín",
                "La Libertad",
                "Lambayeque",
                "Lima",
                "Loreto",
                "Madre de Dios",
                "Moquegua",
                "Pasco",
                "Piura",
                "Puno",
                "San Martín",
                "Tacna",
                "Tumbes",
                "Ucayali"
            ]
        },
        {
            "name": "Philippines",
            "states": [
                "Abra",
                "Agusan del Norte",
                "Agusan del Sur",
                "Aklan",
                "Albay",
                "Antique",
                "Apayao",
                "Aurora",
                "Basilan",
                "Bataan",
                "Batanes",
                "Batangas",
                "Benguet",
                "Biliran",
                "Bohol",
                "Bukidnon",
                "Bulacan",
                "Cagayan",
                "Camarines Norte",
                "Camarines Sur",
                "Camiguin",
                "Capiz",
                "Catanduanes",
                "Cavite",
                "Cebu",
                "Cotabato",
                "Davao de Oro",
                "Davao del Norte",
                "Davao del Sur",
                "Davao Occidental",
                "Davao Oriental",
                "Dinagat Islands",
                "Eastern Samar",
                "Guimaras",
                "Ifugao",
                "Ilocos Norte",
                "Ilocos Sur",
                "Iloilo",
                "Isabela",
                "Kalinga",
                "La Union",
                "Laguna",
                "Lanao del Norte",
                "Lanao del Sur",
                "Leyte",
                "Maguindanao",
                "Marinduque",
                "Masbate",
                "Metro Manila",
                "Misamis Occidental",
                "Misamis Oriental",
                "Mountain Province",
                "Negros Occidental",
                "Negros Oriental",
                "Northern Samar",
                "Nueva Ecija",
                "Nueva Vizcaya",
                "Occidental Mindoro",
                "Oriental Mindoro",
                "Palawan",
                "Pampanga",
                "Pangasinan",
                "Quezon",
                "Quirino",
                "Rizal",
                "Romblon",
                "Samar",
                "Sarangani",
                "Siquijor",
                "Sorsogon",
                "South Cotabato",
                "Southern Leyte",
                "Sultan Kudarat",
                "Sulu",
                "Surigao del Norte",
                "Surigao del Sur",
                "Tarlac",
                "Tawi-Tawi",
                "Zambales",
                "Zamboanga del Norte",
                "Zamboanga del Sur",
                "Zamboanga Sibugay"
            ]
        },
        {
            "name": "Poland",
            "states": [
                "Greater Poland",
                "Kuyavian-Pomeranian",
                "Lesser Poland",
                "Łódź",
                "Lower Silesian",
                "Lublin",
                "Lubusz",
                "Masovian",
                "Opole",
                "Podlaskie",
                "Pomeranian",
                "Silesian",
                "Świętokrzyskie",
                "Warmian-Masurian",
                "West Pomeranian"
            ]
        },
        {
            "name": "Portugal",
            "states": [
                "Aveiro",
                "Beja",
                "Braga",
                "Bragança",
                "Castelo Branco",
                "Coimbra",
                "Évora",
                "Faro",
                "Guarda",
                "Leiria",
                "Lisbon",
                "Portalegre",
                "Porto",
                "Santarém",
                "Setúbal",
                "Viana do Castelo",
                "Vila Real",
                "Viseu",
                "Azores",
                "Madeira"
            ]
        },
        {
            "name": "Qatar",
            "states": [
                "Al Daayen",
                "Al Khor",
                "Al Rayyan",
                "Al Shamal",
                "Al Wakrah",
                "Al-Shahaniya",
                "Doha",
                "Umm Salal"
            ]
        },
        {
            "name": "Romania",
            "states": [
                "Alba",
                "Arad",
                "Argeș",
                "Bacău",
                "Bihor",
                "Bistrița-Năsăud",
                "Botoșani",
                "Brașov",
                "Brăila",
                "Bucharest",
                "Buzău",
                "Caraș-Severin",
                "Călărași",
                "Cluj",
                "Constanța",
                "Covasna",
                "Dâmbovița",
                "Dolj",
                "Galați",
                "Giurgiu",
                "Gorj",
                "Harghita",
                "Hunedoara",
                "Ialomița",
                "Iași",
                "Ilfov",
                "Maramureș",
                "Mehedinți",
                "Mureș",
                "Neamț",
                "Olt",
                "Prahova",
                "Sălaj",
                "Satu Mare",
                "Sibiu",
                "Suceava",
                "Teleorman",
                "Timiș",
                "Tulcea",
                "Vâlcea",
                "Vaslui",
                "Vrancea"
            ]
        },
        {
            "name": "Russia",
            "states": [
                "Adygea",
                "Altai Krai",
                "Altai Republic",
                "Amur Oblast",
                "Arkhangelsk Oblast",
                "Astrakhan Oblast",
                "Bashkortostan",
                "Belgorod Oblast",
                "Bryansk Oblast",
                "Buryatia",
                "Chechen Republic",
                "Chelyabinsk Oblast",
                "Chukotka Autonomous Okrug",
                "Chuvashia",
                "Dagestan",
                "Ingushetia",
                "Irkutsk Oblast",
                "Ivanovo Oblast",
                "Jewish Autonomous Oblast",
                "Kabardino-Balkaria",
                "Kaliningrad Oblast",
                "Kalmykia",
                "Kaluga Oblast",
                "Kamchatka Krai",
                "Karachay-Cherkessia",
                "Karelia",
                "Kemerovo Oblast",
                "Khabarovsk Krai",
                "Khakassia",
                "Khanty-Mansi Autonomous Okrug",
                "Kirov Oblast",
                "Komi",
                "Kostroma Oblast",
                "Krasnodar Krai",
                "Krasnoyarsk Krai",
                "Kurgan Oblast",
                "Kursk Oblast",
                "Leningrad Oblast",
                "Lipetsk Oblast",
                "Magadan Oblast",
                "Mari El",
                "Mordovia",
                "Moscow",
                "Moscow Oblast",
                "Murmansk Oblast",
                "Nenets Autonomous Okrug",
                "Nizhny Novgorod Oblast",
                "North Ossetia-Alania",
                "Novgorod Oblast",
                "Novosibirsk Oblast",
                "Omsk Oblast",
                "Orenburg Oblast",
                "Oryol Oblast",
                "Penza Oblast",
                "Perm Krai",
                "Primorsky Krai",
                "Pskov Oblast",
                "Rostov Oblast",
                "Ryazan Oblast",
                "Saint Petersburg",
                "Sakha",
                "Sakhalin Oblast",
                "Samara Oblast",
                "Saratov Oblast",
                "Smolensk Oblast",
                "Stavropol Krai",
                "Sverdlovsk Oblast",
                "Tambov Oblast",
                "Tatarstan",
                "Tomsk Oblast",
                "Tula Oblast",
                "Tver Oblast",
                "Tyumen Oblast",
                "Tyva",
                "Udmurtia",
                "Ulyanovsk Oblast",
                "Vladimir Oblast",
                "Volgograd Oblast",
                "Vologda Oblast",
                "Voronezh Oblast",
                "Yamalo-Nenets Autonomous Okrug",
                "Yaroslavl Oblast",
                "Zabaykalsky Krai"
            ]
        },
        {
            "name": "Rwanda",
            "states": [
                "Eastern",
                "Kigali",
                "Northern",
                "Southern",
                "Western"
            ]
        },
        {
            "name": "Saint Kitts and Nevis",
            "states": [
                "Christ Church Nichola Town",
                "Saint Anne Sandy Point",
                "Saint George Basseterre",
                "Saint George Gingerland",
                "Saint James Windward",
                "Saint John Capisterre",
                "Saint John Figtree",
                "Saint Mary Cayon",
                "Saint Paul Capisterre",
                "Saint Paul Charlestown",
                "Saint Peter Basseterre",
                "Saint Thomas Lowland",
                "Saint Thomas Middle Island",
                "Trinity Palmetto Point"
            ]
        },
        {
            "name": "Saint Lucia",
            "states": [
                "Anse la Raye",
                "Canaries",
                "Castries",
                "Choiseul",
                "Dennery",
                "Gros Islet",
                "Laborie",
                "Micoud",
                "Soufrière",
                "Vieux Fort"
            ]
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "states": [
                "Charlotte",
                "Grenadines",
                "Saint Andrew",
                "Saint David",
                "Saint George",
                "Saint Patrick"
            ]
        },
        {
            "name": "Samoa",
            "states": [
                "A'ana",
                "Aiga-i-le-Tai",
                "Atua",
                "Fa'asaleleaga",
                "Gaga'emauga",
                "Gaga'ifomauga",
                "Palauli",
                "Satupa'itea",
                "Tuamasaga",
                "Va'a-o-Fonoti",
                "Vaisigano"
            ]
        },
        {
            "name": "San Marino",
            "states": [
                "Acquaviva",
                "Borgo Maggiore",
                "Chiesanuova",
                "Domagnano",
                "Faetano",
                "Fiorentino",
                "Montegiardino",
                "San Marino",
                "Serravalle"
            ]
        },
        {
            "name": "São Tomé and Príncipe",
            "states": [
                "Príncipe",
                "São Tomé"
            ]
        },
        {
            "name": "Saudi Arabia",
            "states": [
                "Al Bahah",
                "Al Jawf",
                "Al Madinah",
                "Al-Qassim",
                "Asir",
                "Eastern Province",
                "Hail",
                "Jizan",
                "Makkah",
                "Najran",
                "Northern Borders",
                "Riyadh",
                "Tabuk"
            ]
        },
        {
            "name": "Senegal",
            "states": [
                "Dakar",
                "Diourbel",
                "Fatick",
                "Kaffrine",
                "Kaolack",
                "Kédougou",
                "Kolda",
                "Louga",
                "Matam",
                "Saint-Louis",
                "Sédhiou",
                "Tambacounda",
                "Thiès",
                "Ziguinchor"
            ]
        },
        {
            "name": "Serbia",
            "states": [
                "Belgrade",
                "Bor",
                "Braničevo",
                "Central Banat",
                "Jablanica",
                "Kolubara",
                "Mačva",
                "Moravica",
                "Nišava",
                "North Bačka",
                "North Banat",
                "Pirot",
                "Podunavlje",
                "Pomoravlje",
                "Rasina",
                "Raška",
                "South Bačka",
                "South Banat",
                "Srem",
                "Šumadija",
                "Toplica",
                "West Bačka",
                "Zaječar",
                "Zlatibor"
            ]
        },
        {
            "name": "Seychelles",
            "states": [
                "Anse aux Pins",
                "Anse Boileau",
                "Anse Etoile",
                "Anse Royale",
                "Au Cap",
                "Baie Lazare",
                "Baie Sainte Anne",
                "Beau Vallon",
                "Bel Air",
                "Bel Ombre",
                "Cascade",
                "Glacis",
                "Grand Anse Mahe",
                "Grand Anse Praslin",
                "Inner Islands",
                "La Digue and Inner Islands",
                "Les Mamelles",
                "Mont Buxton",
                "Mont Fleuri",
                "Plaisance",
                "Pointe Larue",
                "Port Glaud",
                "Roche Caiman",
                "Saint Louis",
                "Takamaka"
            ]
        },
        {
            "name": "Sierra Leone",
            "states": [
                "Eastern",
                "Northern",
                "Southern",
                "Western Area"
            ]
        },
        {
            "name": "Singapore",
            "states": [
                "Central Singapore",
                "North East",
                "North West",
                "South East",
                "South West"
            ]
        },
        {
            "name": "Slovakia",
            "states": [
                "Banská Bystrica",
                "Bratislava",
                "Košice",
                "Nitra",
                "Prešov",
                "Trenčín",
                "Trnava",
                "Žilina"
            ]
        },
        {
            "name": "Slovenia",
            "states": [
                "Ajdovščina",
                "Beltinci",
                "Benedikt",
                "Bistrica ob Sotli",
                "Bled",
                "Bloke",
                "Bohinj",
                "Borovnica",
                "Bovec",
                "Braslovče",
                "Brda",
                "Brezovica",
                "Brežice",
                "Cankova",
                "Celje",
                "Cerklje na Gorenjskem",
                "Cerknica",
                "Cerkno",
                "Cerkvenjak",
                "Destrnik",
                "Divača",
                "Dobje",
                "Dobrepolje",
                "Dobrna",
                "Dobrova-Polhov Gradec",
                "Dobrovnik",
                "Dol pri Ljubljani",
                "Dolenjske Toplice",
                "Domžale",
                "Dornava",
                "Dravograd",
                "Duplek",
                "Gorenja Vas-Poljane",
                "Gorišnica",
                "Gorje",
                "Gornja Radgona",
                "Gornji Grad",
                "Gornji Petrovci",
                "Grad",
                "Grosuplje",
                "Hajdina",
                "Hoče-Slivnica",
                "Hodoš",
                "Horjul",
                "Hrastnik",
                "Hrpelje-Kozina",
                "Idrija",
                "Ig",
                "Ilirska Bistrica",
                "Ivančna Gorica",
                "Izola",
                "Jesenice",
                "Jezersko",
                "Juršinci",
                "Kamnik",
                "Kanal",
                "Kidričevo",
                "Kobarid",
                "Kobilje",
                "Kočevje",
                "Komen",
                "Komenda",
                "Koper",
                "Kostel",
                "Kozje",
                "Kranj",
                "Kranjska Gora",
                "Križevci",
                "Kungota",
                "Kuzma",
                "Laško",
                "Lenart",
                "Lendava",
                "Litija",
                "Ljubljana",
                "Ljubno",
                "Ljutomer",
                "Log-Dragomer",
                "Logatec",
                "Loška Dolina",
                "Loški Potok",
                "Lovrenc na Pohorju",
                "Luče",
                "Lukovica",
                "Majšperk",
                "Makole",
                "Maribor",
                "Markovci",
                "Medvode",
                "Mengeš",
                "Metlika",
                "Mežica",
                "Miklavž na Dravskem Polju",
                "Miren-Kostanjevica",
                "Mirna",
                "Mirna Peč",
                "Mislinja",
                "Mokronog-Trebelno",
                "Moravče",
                "Moravske Toplice",
                "Mozirje",
                "Murska Sobota",
                "Muta",
                "Naklo",
                "Nazarje",
                "Nova Gorica",
                "Novo Mesto",
                "Odranci",
                "Oplotnica",
                "Ormož",
                "Osilnica",
                "Pesnica",
                "Piran",
                "Pivka",
                "Podčetrtek",
                "Podlehnik",
                "Podvelka",
                "Poljčane",
                "Polzela",
                "Postojna",
                "Prebold",
                "Preddvor",
                "Prevalje",
                "Ptuj",
                "Puconci",
                "Rače-Fram",
                "Radeče",
                "Radenci",
                "Radlje ob Dravi",
                "Radovljica",
                "Ravne na Koroškem",
                "Razkrižje",
                "Renče-Vogrsko",
                "Rečica ob Savinji",
                "Ribnica",
                "Ribnica na Pohorju",
                "Rogaška Slatina",
                "Rogašovci",
                "Rogatec",
                "Ruše",
                "Selnica ob Dravi",
                "Semič",
                "Sevnica",
                "Sežana",
                "Slovenj Gradec",
                "Slovenska Bistrica",
                "Slovenske Konjice",
                "Sodražica",
                "Solčava",
                "Sveta Ana",
                "Sveta Trojica v Slovenskih Goricah",
                "Sveta Andraž v Slovenskih Goricah",
                "Sveti Jurij ob Ščavnici",
                "Sveti Jurij v Slovenskih Goricah",
                "Sveti Tomaž",
                "Tabor",
                "Tišina",
                "Tolmin",
                "Trbovlje",
                "Trebnje",
                "Trnovska Vas",
                "Trzin",
                "Tržič",
                "Turnišče",
                "Velenje",
                "Velika Polana",
                "Velike Lašče",
                "Veržej",
                "Videm",
                "Vipava",
                "Vitanje",
                "Vodice",
                "Vojnik",
                "Vransko",
                "Vrhnika",
                "Vuzenica",
                "Zagorje ob Savi",
                "Zavrč",
                "Zreče",
                "Žalec",
                "Železniki",
                "Žetale",
                "Žiri",
                "Žirovnica",
                "Žužemberk"
            ]
        },
        {
            "name": "Solomon Islands",
            "states": [
                "Central",
                "Choiseul",
                "Guadalcanal",
                "Honiara",
                "Isabel",
                "Makira-Ulawa",
                "Malaita",
                "Rennell and Bellona",
                "Temotu",
                "Western"
            ]
        },
        {
            "name": "Somalia",
            "states": [
                "Awdal",
                "Bakool",
                "Banaadir",
                "Bari",
                "Bay",
                "Galguduud",
                "Gedo",
                "Hiiraan",
                "Jubbada Dhexe",
                "Jubbada Hoose",
                "Mudug",
                "Nugaal",
                "Sanaag",
                "Shabeellaha Dhexe",
                "Shabeellaha Hoose",
                "Sool",
                "Togdheer",
                "Woqooyi Galbeed"
            ]
        },
        {
            "name": "South Africa",
            "states": [
                "Eastern Cape",
                "Free State",
                "Gauteng",
                "KwaZulu-Natal",
                "Limpopo",
                "Mpumalanga",
                "North West",
                "Northern Cape",
                "Western Cape"
            ]
        },
        {
            "name": "South Korea",
            "states": [
                "Busan",
                "Chungbuk",
                "Chungnam",
                "Daegu",
                "Daejeon",
                "Gangwon",
                "Gwangju",
                "Gyeongbuk",
                "Gyeonggi",
                "Gyeongnam",
                "Incheon",
                "Jeju",
                "Jeonbuk",
                "Jeonnam",
                "Sejong",
                "Seoul",
                "Ulsan"
            ]
        },
        {
            "name": "South Sudan",
            "states": [
                "Central Equatoria",
                "Eastern Equatoria",
                "Jonglei",
                "Lakes",
                "Northern Bahr el Ghazal",
                "Unity",
                "Upper Nile",
                "Warrap",
                "Western Bahr el Ghazal",
                "Western Equatoria"
            ]
        },
        {
            "name": "Spain",
            "states": [
                "Andalusia",
                "Aragon",
                "Asturias",
                "Balearic Islands",
                "Basque Country",
                "Canary Islands",
                "Cantabria",
                "Castile and León",
                "Castilla-La Mancha",
                "Catalonia",
                "Ceuta",
                "Extremadura",
                "Galicia",
                "La Rioja",
                "Madrid",
                "Melilla",
                "Murcia",
                "Navarre",
                "Valencian Community"
            ]
        },
        {
            "name": "Sri Lanka",
            "states": [
                "Central",
                "Eastern",
                "North Central",
                "Northern",
                "North Western",
                "Sabaragamuwa",
                "Southern",
                "Uva",
                "Western"
            ]
        },
        {
            "name": "Sudan",
            "states": [
                "Al Jazirah",
                "Al Qadarif",
                "Blue Nile",
                "Central Darfur",
                "East Darfur",
                "Kassala",
                "Khartoum",
                "North Darfur",
                "North Kordofan",
                "Northern",
                "Red Sea",
                "River Nile",
                "Sennar",
                "South Darfur",
                "South Kordofan",
                "West Darfur",
                "West Kordofan",
                "White Nile"
            ]
        },
        {
            "name": "Suriname",
            "states": [
                "Brokopondo",
                "Commewijne",
                "Coronie",
                "Marowijne",
                "Nickerie",
                "Para",
                "Paramaribo",
                "Saramacca",
                "Sipaliwini",
                "Wanica"
            ]
        },
        {
            "name": "Sweden",
            "states": [
                "Blekinge",
                "Dalarna",
                "Gävleborg",
                "Gotland",
                "Halland",
                "Jämtland",
                "Jönköping",
                "Kalmar",
                "Kronoberg",
                "Norrbotten",
                "Örebro",
                "Östergötland",
                "Skåne",
                "Södermanland",
                "Stockholm",
                "Uppsala",
                "Värmland",
                "Västerbotten",
                "Västernorrland",
                "Västmanland",
                "Västra Götaland"
            ]
        },
        {
            "name": "Switzerland",
            "states": [
                "Aargau",
                "Appenzell Ausserrhoden",
                "Appenzell Innerrhoden",
                "Basel-Landschaft",
                "Basel-Stadt",
                "Bern",
                "Fribourg",
                "Geneva",
                "Glarus",
                "Graubünden",
                "Jura",
                "Lucerne",
                "Neuchâtel",
                "Nidwalden",
                "Obwalden",
                "Schaffhausen",
                "Schwyz",
                "Solothurn",
                "St. Gallen",
                "Ticino",
                "Thurgau",
                "Uri",
                "Valais",
                "Vaud",
                "Zug",
                "Zürich"
            ]
        },
        {
            "name": "Syria",
            "states": [
                "Al-Hasakah",
                "Aleppo",
                "As-Suwayda",
                "Damascus",
                "Daraa",
                "Deir ez-Zor",
                "Hama",
                "Homs",
                "Idlib",
                "Latakia",
                "Quneitra",
                "Rif Dimashq",
                "Tartus"
            ]
        },
        {
            "name": "Tajikistan",
            "states": [
                "Dushanbe",
                "Gorno-Badakhshan",
                "Khatlon",
                "Sughd"
            ]
        },
        {
            "name": "Tanzania",
            "states": [
                "Arusha",
                "Dar es Salaam",
                "Dodoma",
                "Geita",
                "Iringa",
                "Kagera",
                "Katavi",
                "Kigoma",
                "Kilimanjaro",
                "Lindi",
                "Manyara",
                "Mara",
                "Mbeya",
                "Morogoro",
                "Mtwara",
                "Mwanza",
                "Njombe",
                "Pemba North",
                "Pemba South",
                "Pwani",
                "Rukwa",
                "Ruvuma",
                "Shinyanga",
                "Simiyu",
                "Singida",
                "Tabora",
                "Tanga",
                "Zanzibar Central/South",
                "Zanzibar North",
                "Zanzibar Urban/West"
            ]
        },
        {
            "name": "Thailand",
            "states": [
                "Amnat Charoen",
                "Ang Thong",
                "Bangkok",
                "Bueng Kan",
                "Buri Ram",
                "Chachoengsao",
                "Chai Nat",
                "Chaiyaphum",
                "Chanthaburi",
                "Chiang Mai",
                "Chiang Rai",
                "Chonburi",
                "Chumphon",
                "Kalasin",
                "Kamphaeng Phet",
                "Kanchanaburi",
                "Khon Kaen",
                "Krabi",
                "Lampang",
                "Lamphun",
                "Loei",
                "Lopburi",
                "Mae Hong Son",
                "Maha Sarakham",
                "Mukdahan",
                "Nakhon Nayok",
                "Nakhon Pathom",
                "Nakhon Phanom",
                "Nakhon Ratchasima",
                "Nakhon Sawan",
                "Nakhon Si Thammarat",
                "Nan",
                "Narathiwat",
                "Nong Bua Lam Phu",
                "Nong Khai",
                "Nonthaburi",
                "Pathum Thani",
                "Pattani",
                "Phang Nga",
                "Phatthalung",
                "Phayao",
                "Phetchabun",
                "Phetchaburi",
                "Phichit",
                "Phitsanulok",
                "Phrae",
                "Phuket",
                "Prachinburi",
                "Prachuap Khiri Khan",
                "Ranong",
                "Ratchaburi",
                "Rayong",
                "Roi Et",
                "Sa Kaeo",
                "Sakon Nakhon",
                "Samut Prakan",
                "Samut Sakhon",
                "Samut Songkhram",
                "Saraburi",
                "Satun",
                "Sing Buri",
                "Sisaket",
                "Songkhla",
                "Sukhothai",
                "Suphan Buri",
                "Surat Thani",
                "Surin",
                "Tak",
                "Trang",
                "Trat",
                "Ubon Ratchathani",
                "Udon Thani",
                "Uthai Thani",
                "Uttaradit",
                "Yala",
                "Yasothon"
            ]
        },
        {
            "name": "Togo",
            "states": [
                "Centrale",
                "Kara",
                "Maritime",
                "Plateaux",
                "Savanes"
            ]
        },
        {
            "name": "Tonga",
            "states": [
                "Eua",
                "Haapai",
                "Niuas",
                "Tongatapu",
                "Vava'u"
            ]
        },
        {
            "name": "Trinidad and Tobago",
            "states": [
                "Arima",
                "Chaguanas",
                "Couva-Tabaquite-Talparo",
                "Diego Martin",
                "Eastern Tobago",
                "Penal-Debe",
                "Point Fortin",
                "Port of Spain",
                "Princes Town",
                "Rio Claro-Mayaro",
                "San Fernando",
                "San Juan-Laventille",
                "Sangre Grande",
                "Siparia",
                "Tunapuna-Piarco",
                "Western Tobago"
            ]
        },
        {
            "name": "Tunisia",
            "states": [
                "Ariana",
                "Beja",
                "Ben Arous",
                "Bizerte",
                "Gabes",
                "Gafsa",
                "Jendouba",
                "Kairouan",
                "Kasserine",
                "Kebili",
                "Kef",
                "Mahdia",
                "Manouba",
                "Medenine",
                "Monastir",
                "Nabeul",
                "Sfax",
                "Sidi Bouzid",
                "Siliana",
                "Sousse",
                "Tataouine",
                "Tozeur",
                "Tunis",
                "Zaghouan"
            ]
        },
        {
            "name": "Turkey",
            "states": [
                "Adana",
                "Adıyaman",
                "Afyonkarahisar",
                "Ağrı",
                "Aksaray",
                "Amasya",
                "Ankara",
                "Antalya",
                "Ardahan",
                "Artvin",
                "Aydın",
                "Balıkesir",
                "Bartın",
                "Batman",
                "Bayburt",
                "Bilecik",
                "Bingöl",
                "Bitlis",
                "Bolu",
                "Burdur",
                "Bursa",
                "Çanakkale",
                "Çankırı",
                "Çorum",
                "Denizli",
                "Diyarbakır",
                "Düzce",
                "Edirne",
                "Elazığ",
                "Erzincan",
                "Erzurum",
                "Eskişehir",
                "Gaziantep",
                "Giresun",
                "Gümüşhane",
                "Hakkari",
                "Hatay",
                "Iğdır",
                "Isparta",
                "Istanbul",
                "Izmir",
                "Kahramanmaraş",
                "Karabük",
                "Karaman",
                "Kars",
                "Kastamonu",
                "Kayseri",
                "Kırıkkale",
                "Kırklareli",
                "Kırşehir",
                "Kilis",
                "Kocaeli",
                "Konya",
                "Kütahya",
                "Malatya",
                "Manisa",
                "Mardin",
                "Mersin",
                "Muğla",
                "Muş",
                "Nevşehir",
                "Niğde",
                "Ordu",
                "Osmaniye",
                "Rize",
                "Sakarya",
                "Samsun",
                "Siirt",
                "Sinop",
                "Şırnak",
                "Sivas",
                "Şanlıurfa",
                "Tekirdağ",
                "Tokat",
                "Trabzon",
                "Tunceli",
                "Uşak",
                "Van",
                "Yalova",
                "Yozgat",
                "Zonguldak"
            ]
        },
        {
            "name": "Turkmenistan",
            "states": [
                "Ahal",
                "Ashgabat",
                "Balkan",
                "Dashoguz",
                "Lebap",
                "Mary"
            ]
        },
        {
            "name": "Tuvalu",
            "states": [
                "Funafuti",
                "Nanumanga",
                "Nanumea",
                "Niutao",
                "Nui",
                "Nukufetau",
                "Nukulaelae",
                "Vaitupu"
            ]
        },
        {
            "name": "Uganda",
            "states": [
                "Abim",
                "Adjumani",
                "Agago",
                "Alebtong",
                "Amolatar",
                "Amudat",
                "Amuria",
                "Amuru",
                "Apac",
                "Arua",
                "Budaka",
                "Bududa",
                "Bugiri",
                "Bugweri",
                "Buhweju",
                "Buikwe",
                "Bukedea",
                "Bukomansimbi",
                "Bukwo",
                "Bulambuli",
                "Buliisa",
                "Bundibugyo",
                "Bunyangabu",
                "Bushenyi",
                "Busia",
                "Butaleja",
                "Butambala",
                "Butebo",
                "Buvuma",
                "Buyende",
                "Dokolo",
                "Gomba",
                "Gulu",
                "Hoima",
                "Ibanda",
                "Iganga",
                "Isingiro",
                "Jinja",
                "Kaabong",
                "Kabale",
                "Kabarole",
                "Kaberamaido",
                "Kalaki",
                "Kalangala",
                "Kaliro",
                "Kalungu",
                "Kampala",
                "Kamuli",
                "Kamwenge",
                "Kanungu",
                "Kapchorwa",
                "Kapelebyong",
                "Karenga",
                "Kasanda",
                "Kasese",
                "Katakwi",
                "Kayunga",
                "Kazo",
                "Kibaale",
                "Kiboga",
                "Kibuku",
                "Kikuube",
                "Kiruhura",
                "Kiryandongo",
                "Kisoro",
                "Kitagwenda",
                "Kitgum",
                "Koboko",
                "Kole",
                "Kotido",
                "Kumi",
                "Kween",
                "Kyankwanzi",
                "Kyegegwa",
                "Kyenjojo",
                "Kyotera",
                "Lamwo",
                "Lira",
                "Luuka",
                "Luwero",
                "Lwengo",
                "Lyantonde",
                "Madi-Okollo",
                "Manafwa",
                "Maracha",
                "Masaka",
                "Masindi",
                "Mayuge",
                "Mbale",
                "Mbarara",
                "Mitooma",
                "Mityana",
                "Moroto",
                "Moyo",
                "Mpigi",
                "Mubende",
                "Mukono",
                "Nabilatuk",
                "Nakapiripirit",
                "Nakaseke",
                "Nakasongola",
                "Namayingo",
                "Namisindwa",
                "Namutumba",
                "Napak",
                "Nebbi",
                "Ngora",
                "Ntoroko",
                "Ntungamo",
                "Nwoya",
                "Obongi",
                "Omoro",
                "Otuke",
                "Oyam",
                "Pader",
                "Pakwach",
                "Pallisa",
                "Rakai",
                "Rubanda",
                "Rubirizi",
                "Rukiga",
                "Rukungiri",
                "Rwampara",
                "Sembabule",
                "Serere",
                "Sheema",
                "Sironko",
                "Soroti",
                "Terego",
                "Tororo",
                "Wakiso",
                "Yumbe",
                "Zombo"
            ]
        },
        {
            "name": "Ukraine",
            "states": [
                "Cherkasy",
                "Chernihiv",
                "Chernivtsi",
                "Crimea",
                "Dnipropetrovsk",
                "Donetsk",
                "Ivano-Frankivsk",
                "Kharkiv",
                "Kherson",
                "Khmelnytskyi",
                "Kiev",
                "Kirovohrad",
                "Luhansk",
                "Lviv",
                "Mykolaiv",
                "Odessa",
                "Poltava",
                "Rivne",
                "Sumy",
                "Ternopil",
                "Vinnytsia",
                "Volyn",
                "Zakarpattia",
                "Zaporizhzhia",
                "Zhytomyr"
            ]
        },
        {
            "name": "United Arab Emirates",
            "states": [
                "Abu Dhabi",
                "Ajman",
                "Dubai",
                "Fujairah",
                "Ras Al Khaimah",
                "Sharjah",
                "Umm Al Quwain"
            ]
        },
        {
            "name": "United Kingdom",
            "states": [
                "England",
                "Northern Ireland",
                "Scotland",
                "Wales"
            ]
        },
        {
            "name": "United States",
            "states": [
                "Alabama",
                "Alaska",
                "Arizona",
                "Arkansas",
                "California",
                "Colorado",
                "Connecticut",
                "Delaware",
                "Florida",
                "Georgia",
                "Hawaii",
                "Idaho",
                "Illinois",
                "Indiana",
                "Iowa",
                "Kansas",
                "Kentucky",
                "Louisiana",
                "Maine",
                "Maryland",
                "Massachusetts",
                "Michigan",
                "Minnesota",
                "Mississippi",
                "Missouri",
                "Montana",
                "Nebraska",
                "Nevada",
                "New Hampshire",
                "New Jersey",
                "New Mexico",
                "New York",
                "North Carolina",
                "North Dakota",
                "Ohio",
                "Oklahoma",
                "Oregon",
                "Pennsylvania",
                "Rhode Island",
                "South Carolina",
                "South Dakota",
                "Tennessee",
                "Texas",
                "Utah",
                "Vermont",
                "Virginia",
                "Washington",
                "West Virginia",
                "Wisconsin",
                "Wyoming"
            ]
        },
        {
            "name": "Uruguay",
            "states": [
                "Artigas",
                "Canelones",
                "Cerro Largo",
                "Colonia",
                "Durazno",
                "Flores",
                "Florida",
                "Lavalleja",
                "Maldonado",
                "Montevideo",
                "Paysandú",
                "Río Negro",
                "Rivera",
                "Rocha",
                "Salto",
                "San José",
                "Soriano",
                "Tacuarembó",
                "Treinta y Tres"
            ]
        },
        {
            "name": "Uzbekistan",
            "states": [
                "Andijan",
                "Bukhara",
                "Fergana",
                "Jizzakh",
                "Karakalpakstan",
                "Namangan",
                "Navoiy",
                "Qashqadaryo",
                "Samarqand",
                "Sirdaryo",
                "Surxondaryo",
                "Tashkent",
                "Xorazm"
            ]
        },
        {
            "name": "Vanuatu",
            "states": [
                "Malampa",
                "Penama",
                "Sanma",
                "Shefa",
                "Tafea",
                "Torba"
            ]
        },
        {
            "name": "Vatican City",
            "states": [
                "Vatican City"
            ]
        },
        {
            "name": "Venezuela",
            "states": [
                "Amazonas",
                "Anzoátegui",
                "Apure",
                "Aragua",
                "Barinas",
                "Bolívar",
                "Carabobo",
                "Cojedes",
                "Delta Amacuro",
                "Falcón",
                "Federal Dependencies of Venezuela",
                "Guárico",
                "La Guaira",
                "Lara",
                "Mérida",
                "Miranda",
                "Monagas",
                "Nueva Esparta",
                "Portuguesa",
                "Sucre",
                "Táchira",
                "Trujillo",
                "Yaracuy",
                "Zulia"
            ]
        },
        {
            "name": "Vietnam",
            "states": [
                "An Giang",
                "Ba Ria-Vung Tau",
                "Bac Giang",
                "Bac Kan",
                "Bac Lieu",
                "Bac Ninh",
                "Ben Tre",
                "Binh Dinh",
                "Binh Duong",
                "Binh Phuoc",
                "Binh Thuan",
                "Ca Mau",
                "Can Tho",
                "Cao Bang",
                "Da Nang",
                "Dak Lak",
                "Dak Nong",
                "Dien Bien",
                "Dong Nai",
                "Dong Thap",
                "Gia Lai",
                "Ha Giang",
                "Ha Nam",
                "Ha Tinh",
                "Hai Duong",
                "Hai Phong",
                "Hanoi",
                "Hau Giang",
                "Ho Chi Minh City",
                "Hoa Binh",
                "Hung Yen",
                "Khanh Hoa",
                "Kien Giang",
                "Kon Tum",
                "Lai Chau",
                "Lam Dong",
                "Lang Son",
                "Lao Cai",
                "Long An",
                "Nam Dinh",
                "Nghe An",
                "Ninh Binh",
                "Ninh Thuan",
                "Phu Tho",
                "Phu Yen",
                "Quang Binh",
                "Quang Nam",
                "Quang Ngai",
                "Quang Ninh",
                "Quang Tri",
                "Soc Trang",
                "Son La",
                "Tay Ninh",
                "Thai Binh",
                "Thai Nguyen",
                "Thanh Hoa",
                "Thua Thien-Hue",
                "Tien Giang",
                "Tra Vinh",
                "Tuyen Quang",
                "Vinh Long",
                "Vinh Phuc",
                "Yen Bai"
            ]
        },
        {
            "name": "Yemen",
            "states": [
                "Abyan",
                "Ad Dali",
                "Aden",
                "Al Bayda",
                "Al Hudaydah",
                "Al Jawf",
                "Al Mahrah",
                "Al Mahwit",
                "Amanat Al Asimah",
                "Dhamar",
                "Hadhramaut",
                "Hajjah",
                "Ibb",
                "Lahij",
                "Marib",
                "Raymah",
                "Saada",
                "Sanaa",
                "Shabwah",
                "Socotra",
                "Taiz"
            ]
        },
        {
            "name": "Zambia",
            "states": [
                "Central",
                "Copperbelt",
                "Eastern",
                "Luapula",
                "Lusaka",
                "Muchinga",
                "Northern",
                "North-Western",
                "Southern",
                "Western"
            ]
        },
        {
            "name": "Zimbabwe",
            "states": [
                "Bulawayo",
                "Harare",
                "Manicaland",
                "Mashonaland Central",
                "Mashonaland East",
                "Mashonaland West",
                "Masvingo",
                "Matabeleland North",
                "Matabeleland South",
                "Midlands"
            ]
        }
    ]
}

export default countries;