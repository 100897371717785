import React from "react";
import './targetComponent.css'
const TargetComponent = ({ img, text, className }) => {
    return (
        //Add a roofsContainer as a container of these targets
        <div className={`targetComponentContainer ${className}`}>
            <img src={img} alt="" />
            <p className={`mainText`}>{text}</p>
        </div>
    )
}


export default TargetComponent;
