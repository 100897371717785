import React from "react";
import { useNavigate } from "react-router";
import "./services.css";
import { useTranslation } from 'react-i18next';
import BannerComponent from "../../bannerComponent/bannerComponent";

const Services = () => {
  const navigate = useNavigate();
  const handleRedirect = (route) => {
    navigate(route + "/", { replace: true });
  };


  const servicesData = [
    {
      type: 'ia',
      translateTitle1: 'HOME_SERVICE_IA_TITLE_1',
      translateTitle2: 'HOME_SERVICE_IA_TITLE_2',
      translateText: 'HOME_SERVICE_IA_TEXT',
      className: 'ia',
      url: 'ia',
      position: 'l',
    },
    {
      type: 'rpa',
      translateTitle1: 'HOME_SERVICE_RPA_TITLE_1',
      translateTitle2: 'HOME_SERVICE_RPA_TITLE_2',
      translateText: 'HOME_SERVICE_RPA_TEXT',
      className: 'rpa',
      url: 'rpa',
      position: 'r',
    },
    {
      type: 'cloud',
      translateTitle1: 'HOME_SERVICE_CLOUD_TITLE',
      translateText: 'HOME_SERVICE_CLOUD_TEXT',
      className: 'cloud',
      url: 'cloud',
      position: 'l',
    },
    {
      type: 'bi',
      translateTitle1: 'HOME_SERVICE_BI_TITLE_1',
      translateTitle2: 'HOME_SERVICE_BI_TITLE_2',
      translateText: 'HOME_SERVICE_BI_TEXT',
      className: 'bi',
      url: 'bi',
      position: 'r',
    },
    {
      type: 'web-app-dev',
      translateTitle1: 'HOME_SERVICE_WEB_DEV_TITLE_1',
      translateTitle2: 'HOME_SERVICE_WEB_DEV_TITLE_2',
      translateText: 'HOME_SERVICE_WEB_DEV_TEXT',
      className: 'wdad',
      url: 'web-app-dev',
      position: 'l',
    },
  ];


  const { t } = useTranslation();
  return (
    <>
      <div className="services-container" id="services-container">
        <div className="services-portfolio">
          {servicesData.map((service, index) => (
            <BannerComponent
              key={index}
              translateTitle={
                service.translateTitle1
                  ? `${t(service.translateTitle1)}${service.type !== "ia" ? ` ${t(service.translateTitle2)}` : ''}`
                  : ''
              }
              translateTitle2={service.type === "ia" && `${t(service.translateTitle2)}`}
              translateText={service.translateText && `${t(service.translateText)}`}
              className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
              classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
              translateButton={t('HOME_SERVICES_BUTTON')}
              url={service.url}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
