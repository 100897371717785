import React from "react";
import { useNavigate } from "react-router";
import "./specialist.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";

const SpecialistComponent = () => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const { t } = useTranslation();

    // OWN OBJECTS
    const healthcareCareCoordinationSuite = [
        {
            serviceName: t('CONTACT_CENTER_PATIENTS'),
            img: '/img/openart-image_WJoUryst_1716397116164_raw 1.jpg',
            description: t('CONTACT_CENTER_PATIENTS_DESC')
        },
        {
            serviceName: t('SCHEDULING_CENTER'),
            img: '/img/openart-image_KrItEU0K_1716397766100_raw 1.jpg',
            description: t('SCHEDULING_CENTER_DESC')
        },
        {
            serviceName: t('DIAGNOSTIC_REVIEWS_COMMUNICATIONS'),
            img: '/img/realistic-scene-with-health-worker-taking-care-elderly-patient 1.jpg',
            description: t('DIAGNOSTIC_REVIEWS_COMMUNICATIONS_DESC')
        },
        {
            serviceName: t('TREATMENT_MANAGEMENT'),
            img: '/img/scene-from-care-job-with-young-child-patient-being-take-care 1.jpg',
            description: t('TREATMENT_MANAGEMENT_DESC')
        },
        {
            serviceName: t('RISKS_MANAGEMENT'),
            img: '/img/risk management 1.jpg',
            description: t('RISKS_MANAGEMENT_DESC')
        },
        {
            serviceName: t('EMR_MAINTENANCE'),
            img: '/img/Designer 1.jpg',
            description: t('EMR_MAINTENANCE_DESC')
        },
        {
            serviceName: t('AUDITING'),
            img: '/img/auddit 1.jpg',
            description: t('AUDITING_DESC')
        },
        {
            serviceName: t('BILLING_COORDINATION'),
            img: '/img/billing 1.jpg',
            description: t('BILLING_COORDINATION_DESC')
        },
        {
            serviceName: t('FINANCIAL_COVERAGE_PRECHECKS'),
            img: '/img/paying-bills-planning-budget-acquire-wealth-generated-by-ai 1.jpg',
            description: t('FINANCIAL_COVERAGE_PRECHECKS_DESC')
        }
    ];

    // OWN COMPONENTS

    function CardServices({ img, title, subtitle, text, route }) {
        return (
            <div className='cardServiceContainer'>
                <div className='cardTitleContainer'>
                    <div className='imgCardService'>
                        {img && <img src={img} alt="" />}
                    </div>
                </div>
                <h2 className='titleBold'>{title}</h2>
                <p className='mainTextCardService'>{text}</p>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="specialistMaincontainer">
                <div className="banner"></div>
                <MainTitleComponent
                    title={t('VIRTUAL_MEDICAL_SERVICES_TITLE')}
                    text={t('VIRTUAL_MEDICAL_SERVICES_DESC')}
                    buttonText={t('HIRE_VMA')}
                    url={'/vma/specialist/hire'}
                />
                <section className="vmaSpecialistSection">
                    {healthcareCareCoordinationSuite.map((data, index) => (
                        <CardServices
                            key={index}
                            title={data.serviceName}
                            text={data.description}
                            img={data.img}
                            route={data.route}
                        />
                    ))}
                </section>
                <section className="buttonInfo centerContent">
                    <h2 className="titleH2">
                        {t('READY_ENHANCE_PRACTICE')}
                    </h2>
                    <div className="textContainer">
                        <h3 className="titleH3">{t('CONTACT_US_FOR_MORE_INFO_1')}</h3>
                        <h3 className="titleH3">{t('CONTACT_US_FOR_MORE_INFO_2')}</h3>
                    </div>
                    <ButtonLinkComponent
                        translateButton={t('HIRE_VMA')}
                        url={'/vma/specialist/hire'}
                    />
                </section>
            </main>
            <Footer />
        </>
    );
};

export default SpecialistComponent;
