import { Link } from 'react-router-dom';

const DropdownMenu = ({
    ref,
    title,
    options,
    isActive,
    handleToggle,
    dropdown,
    subMenuOptions,
    subMenuActive,
    handleSubMenuToggle,
}) => {
    return (
        <div className="servicesContainer">
            <div className="serviceTextContainer" onClick={handleToggle}>
                <button className={`secondary-header-button ${isActive && "activeWindow"}`}>
                    {title}
                </button>
                <img
                    className="imgHeaderservices"
                    src={`${isActive ? "/img/arrowUpHeader.svg" : "/img/arrowDownHeader.svg"}`}
                    alt=""
                />
            </div>
            <div className={`ServicesNavbar ${isActive && "active"} ${dropdown}`} ref={ref}>
                <div>
                    {dropdown === "Services" ? (
                        <>
                            <p className="serviceElement headerTitle">Services</p>
                            {subMenuOptions && (
                                <p className={`techServicesContainer ${subMenuActive ? "active" : ""}`}>
                                    <Link
                                        to={subMenuOptions.link}
                                        onClick={handleSubMenuToggle}
                                        className="serviceElement"
                                    >
                                        {subMenuOptions.title}
                                    </Link>
                                    <img
                                        className="imgHeaderservices"
                                        src={`${subMenuActive ? "/img/arrowRightHeader.svg" : "/img/arrowDownHeader.svg"}`}
                                        alt=""
                                        onClick={handleSubMenuToggle}
                                    />
                                    <div className={`techServicesNavBarContainer ${subMenuActive && "active"} mobile`}>
                                        <div className='headerDropdownLine'></div>
                                        <div className={`TechServicesNavbar ${subMenuActive && "active"}`}>
                                            {subMenuOptions.items.map((item, index) => (
                                                <ul key={index}>
                                                    <li>
                                                        <p>
                                                            <Link to={item.link}>
                                                                {item.name}
                                                                {item.name2}
                                                            </Link>
                                                        </p>
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    </div>
                                </p>
                            )}
                            {options
                                .filter(option => option.type === "tech")
                                .map((option, index) => (
                                    <p key={index} className="serviceElement">
                                        <Link to={option.link} className="serviceElement">
                                            {option.name}
                                        </Link>
                                    </p>
                                ))}
                            <p className="serviceElement headerTitle">Products</p>
                            {options
                                .filter(option => option.type === "product")
                                .map((option, index) => (
                                    <p key={index} className="serviceElement">
                                        <Link to={option.link} className="serviceElement">
                                            {option.name}
                                        </Link>
                                    </p>
                                ))}
                        </>
                    ) : (
                        options
                            .map((option, index) => (
                                <p key={index} className="serviceElement">
                                    <Link to={option.link} className="serviceElement">
                                        {option.name}
                                    </Link>
                                </p>
                            ))
                    )}
                </div>
                {subMenuOptions && (
                    <div className={`techServicesNavBarContainer ${subMenuActive && "active"} desk`}>
                        <div className='headerDropdownLine'></div>
                        <div className={`TechServicesNavbar ${subMenuActive && "active"}`}>
                            {subMenuOptions.items.map((item, index) => (
                                <ul key={index}>
                                    <li>
                                        <p>
                                            <Link to={item.link}>
                                                {item.name}
                                                {item.name2}
                                            </Link>
                                        </p>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
};


export default DropdownMenu