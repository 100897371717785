import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ButtonLinkComponent = ({ url, translateButton, className, index, blank }) => {
    const { t } = useTranslation();

    return (
        <button className={`${((index) && (index % 2 === 1)) ? `button second ${className}` : `button ${className}`}`}>
            <Link to={url} className="link" target={blank ? '_blank' : undefined}>
                {t(translateButton)}
            </Link>
        </button>
    );
};

export default ButtonLinkComponent;
