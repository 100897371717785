import React from "react";
import "./investment.css";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";
import ButtonLinkComponent from "../../components/buttonLink/buttonLink";
import ReactPlayer from "react-player";

const Investment = () => {
  const { t } = useTranslation();

  const investmentOpportunities = [
    {
      title: t('GROWING_ECONOMY'),
      description: t('GROWING_ECONOMY_DESC'),
      className: "growing-economy"
    },
    {
      title: t('STRATEGIC_LOCATION'),
      description: t('STRATEGIC_LOCATION_DESC'),
      className: "strategic-location"
    },
    {
      title: t('BUSINESS_FRIENDLY_ENVIRONMENT'),
      description: t('BUSINESS_FRIENDLY_ENVIRONMENT_DESC'),
      className: "business-friendly-environment"
    }
  ];

  const keyInvestmentOpportunities = [
    {
      title: t('TECHNOLOGY_INNOVATION'),
      description: t('TECHNOLOGY_INNOVATION_DESC'),
      image: "/img/openart-image_xnWFVV2s_1715977952466_raw 1.jpg",
      className: "technology-innovation"
    },
    {
      title: t('RENEWABLE_ENERGY'),
      description: t('RENEWABLE_ENERGY_DESC'),
      image: "/img/solar-panels-wind-mills-sunset-sustainable-energy-eco-environment 1.jpg",
      className: "renewable-energy"
    },
    {
      title: t('TOURISM_HOSPITALITY'),
      description: t('TOURISM_HOSPITALITY_DESC'),
      image: "/img/openart-image_mcqC1H1z_1715979129937_raw 1.jpg",
      className: "tourism-hospitality"
    },
    {
      title: t('AGRIBUSINESS'),
      description: t('AGRIBUSINESS_DESC'),
      image: "/img/openart-image_pWRsLJZV_1715979441349_raw 1.jpg",
      className: "agribusiness"
    },
    {
      title: t('REAL_ESTATE'),
      description: t('REAL_ESTATE_DESC'),
      image: "/img/openart-image_uMm4VzLw_1715979701510_raw 1.jpg",
      className: "real-estate"
    }
  ];

  const investmentStrategies = [
    {
      title: t('RESEARCH'),
      description: t('RESEARCH_DESC'),
      image: "/img/young-businesswoman-searches-data-with-magnifying-glass-generated-by-ai 1.jpg",
      className: "research"
    },
    {
      title: t('LOCAL_PARTNERSHIPS'),
      description: t('LOCAL_PARTNERSHIPS_DESC'),
      image: "/img/openart-image_S2epkhXs_1715980233291_raw 1.jpg",
      className: "local-partnerships"
    },
    {
      title: t('DIVERSIFICATION'),
      description: t('DIVERSIFICATION_DESC'),
      image: "/img/openart-image_3rgtA7wH_1715980748015_raw 1.jpg",
      className: "diversification"
    }
  ];

  // Componente PositionsApplyContainer
  function PositionsApplyContainer({ img, title, text }) {
    return (
      <div className="positionsApplyContainer">
        <img src={`${img}`} alt="" className="positionsApplyImgBackground" />
        <div className="positionsApplyBackground">
          <p className="positionsApplyText">{title}</p>
          <p className="positionsApplyTextSecondary">{text}</p>
        </div>
      </div>
    )
  }

  function TargetComponent({ img, text, title, className }) {
    return (
      <div className={`targetComponentContainer ${className}`}>
        <img src={img} alt="" />
        <p className={`maintitle`}>{title}</p>
        <p className={`mainText`}>{text}</p>
      </div>
    )
  }

  return (
    <>
      <Header />
      <main className="investmentMainContainer">
        <MainTitleComponent
          title={t('UNLOCK_GROWTH')}
          text={'  '}
          buttonText={t('BOOK_APPOINTMENT')}
          url={'https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/'}
          blank={true}
        />
        <section className="invest">
          <h2 className="titleH2">{t('WHY_INVEST_COLOMBIA')}</h2>
          <div className="services-container" id="services-container">
            <div className="services-portfolio">
              {investmentOpportunities.map((service, index) => (
                <BannerComponent
                  key={index}
                  translateTitle={service.title}
                  className={`${service.className} ${index % 2 === 1 ? '' : 'second'}`}
                  classNameSide={`${index % 2 === 1 ? 'l' : 'r'}`}
                  translateText={service.description}
                />
              ))}
            </div>
          </div>
        </section>
        {/* <div className="videoContainer">
          <ReactPlayer
            url={'https://www.youtube.com/watch?v=D9EAZ6-qOu8'}
            className='VideoPlayer'
            controls
          />
        </div> */}
        <section className="keyInvestment">
          <h2 className="titleH2">{t('KEY_INVESTMENT_SECTORS')}</h2>
          <div className="roofsContainer">
            {keyInvestmentOpportunities.map((data, index) => (
              <PositionsApplyContainer
                key={index}
                img={data.image}
                title={data.title}
                text={data.description}
              />
            ))}
          </div>
        </section>
        <section className="help">
          <h2 className="titleH2">{t('HOW_WE_CAN_HELP')}</h2>
          <p className="textHelp">{t('HELP_TEXT')}</p>
          <div className="roofsContainer">
            {investmentStrategies.map((data, index) => (
              <TargetComponent
                key={index}
                img={data.image}
                title={data.title}
                text={data.description}
              />
            ))}
          </div>
        </section>
        <section className="start">
          <h1 className="title">{t('GET_STARTED')}</h1>
          <p className="text">{t('GET_STARTED_TEXT')}</p>
          <p className="textBlack">{t('GET_STARTED_CONTACT')}</p>
          <ButtonLinkComponent
            translateButton={'BOOK_APPOINTMENT_BUTTON'}
            url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
            blank={true}
          />
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Investment;
