import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Asumo que estás usando i18next para la internacionalización

const useSortedCountries = (keyFilterSpanish, filteredCountryCode) => {
    const { i18n } = useTranslation(); // Obtén la instancia de i18n
    const [sortedCountriesCode, setSortedCountriesCode] = useState([]);

    useEffect(() => {
        const sortedArray = [...filteredCountryCode].sort((a, b) => {
            if (i18n.language === 'es') {
                return a[keyFilterSpanish].localeCompare(b[keyFilterSpanish]);
            } else {
                return a.country_EN.localeCompare(b.country_EN);
            }
        });
        setSortedCountriesCode(sortedArray);
    }, [i18n.language, filteredCountryCode]);

    return sortedCountriesCode;
};

export default useSortedCountries;
