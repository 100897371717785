import React, { useState, useEffect } from "react";
import "./article.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import { useParams } from 'react-router-dom';
import axios from "axios";
import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Article_blog = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [currentArticle, setCurrentArticles] = useState([]);
    const [remainingArticles, setRemainingArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://prod-46.westus.logic.azure.com:443/workflows/c6d627995fd54eb9942a7f368a81fee7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JLe51vAib509ToTy0NmsiQcIOAL6aLjXwAqoObDPEQ0')
            .then((response) => {
                const cleanedArticles = response.data.map((article, index) => {
                    const cleanHTML = DOMPurify.sanitize(i18n.language === 'es' ? article.Article_body : article.Article_body_EN);
                    const plainText = parse(cleanHTML).props.children;
                    return { ...article, Article_body: plainText, index: index + 1 };
                });

                const currentArticles = cleanedArticles.filter(article => article.index == id); // Asumiendo que `id` es 1
                setCurrentArticles(currentArticles);
                const remainingArticles = cleanedArticles.filter(article => article.index != id).slice(0, 4); // Filtrando y obteniendo los primeros 4 artículos restantes
                setRemainingArticles(remainingArticles);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, [i18n.language, id]);

    function extractTextFromReactElement(element) {
        if (typeof element === 'string') {
            return element;
        }
        if (Array.isArray(element)) {
            return element.map(extractTextFromReactElement).join(' ');
        }
        if (element.props && element.props.children) {
            return extractTextFromReactElement(element.props.children);
        }
        return '';
    }

    function truncateText(text, charLimit) {
        if (text.length > charLimit) {
            return text.slice(0, charLimit) + '...';
        }
        return text;
    }

    function extractAndTruncateText(articleBody, charLimit) {
        let firstParagraph = '';

        if (Array.isArray(articleBody)) {
            if (articleBody.length > 0) {
                firstParagraph = articleBody[0].props ? extractTextFromReactElement(articleBody[0].props.children) : articleBody[0];
            }
        } else if (articleBody.props && articleBody.props.children) {
            firstParagraph = extractTextFromReactElement(articleBody.props.children);
        } else if (typeof articleBody === 'string') {
            firstParagraph = articleBody;
        }

        return truncateText(firstParagraph, charLimit);
    }

    function Articles({ img, title, text, index, className }) {
        return (
            <div className={`articleCardContainer ${className}`}>
                <img src={`data:image/png;base64,${img}`} alt="" />
                <Link to={`/blog/article/${index}`}>
                    <h2 className="titleH4Bold">{title}</h2>
                </Link>
                <Link to={`/blog/article/${index}`}>
                    <p className="mediumText">{text}</p>
                </Link>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="articleMainContainer">
                {isLoading ? (
                    <div className="loadincContainer">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                ) : (
                    <>
                        <div className="articleBanner">
                            <h1 className="title">{i18n.language === 'es' ? currentArticle[0]?.Article_Name : currentArticle[0]?.Article_Name_EN}</h1>
                        </div>
                        <div className="articleContainer">
                            <section className="mainTextContainer">
                                <p className="mainText">{currentArticle[0]?.Article_body}</p>
                                <div className="socialMediaShareLinks">
                                    <p>{t("SHARE")}</p>
                                    <div className="socialMediaIcons">
                                        <Link to={`https://www.facebook.com/sharer/sharer.php?u=https://5igsolutions.com/blog/article/${id}`}>
                                            <img src="/img/facebook-brands-solid.svg" alt="" />
                                        </Link>
                                        <Link to={`https://x.com/share?url=https://5igsolutions.com/blog/article/${id}`}>
                                            <img src="/img/linkedin-brands-solid.svg" alt="" />
                                        </Link>
                                        <Link to={`https://www.linkedin.com/shareArticle?mini=true&url=https://5igsolutions.com/blog/article/${id}`}>
                                            <img src="/img/square-x-twitter-brands-solid.svg" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </section>
                            <section className="latestNews">
                                <h2 className="titleH2">{t("BLOG_LATEST_TITLE")}</h2>
                                {remainingArticles.map((article) => {
                                    return (
                                        <Articles
                                            img={article.Article_img}
                                            title={i18n.language === 'es' ? article.Article_Name : article.Article_Name_EN}
                                            text={extractAndTruncateText(article.Article_body, 100)} // Ajusta el número de caracteres según tu necesidad
                                            index={article.index}
                                        />
                                    )
                                })}
                            </section>
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </>
    );
};

export default Article_blog;
