import React from "react";
import { useNavigate } from "react-router";
import "./consulting.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import TargetComponent from "../../../components/targetsComponent/targetComponent";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";
import PositionsApplySection from "../../../components/positionsApplyComponent/positionsApplyComponent";

const ConsultingComponent = () => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const { t } = useTranslation();

    const targetComponentsData = [
        {
            img: '/img/openart-image_h1CbpqyS_1715788549925_raw 1.jpg',
            text: t('CONSULTING_FLEXIBILITY')
        },
        {
            img: '/img/openart-image_38aV26Us_1715789909948_raw 1.jpg',
            text: t('CONSULTING_DIVERSE_PROJECTS')
        },
        {
            img: '/img/openart-image_J2vxSqoa_1715790786563_raw 1.jpg',
            text: t('CONSULTING_HIGHER_EARNINGS')
        }
    ];

    return (
        <>
            <Header />
            <main className="consultingMaincontainer">
                <MainTitleComponent
                    title={t('CONNECT_BEST_WORKHAND')}
                    text={t('EXPLORE_OPPORTUNITIES')}
                    buttonText={t('APPLY_NOW')}
                    url={"/contractors/consulting/apply"}
                />
                <section className="positionSection">
                    <h2 className="titleH2">{t('ABOUT_POSITION')}</h2>
                    <div className="roofsContainer">
                        {targetComponentsData.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.img}
                                text={data.text}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                </section>
                <PositionsApplySection 
                title={'POSITIONS_APPLY'}/>
                <ButtonLinkComponent
                    translateButton={'APPLY_NOW'}
                    url={"/contractors/consulting/apply"}
                />
            </main>
            <Footer />
        </>
    );
};

export default ConsultingComponent;
