import React from "react";
import './benefitsCardsComponent.css'
const BenefitsCardsComponent = ({ title, cardsData }) => {
    return (
        //Add a roofsContainer as a container of these targets
        <section className="benefitsCardsComponentSection">
            <h2 className="title">{title}</h2>
            <div className="cardsContainer">
                {cardsData.map((data, index) => (
                    <div className="benfitContainer">
                        <div className="benefitTitle">
                            <div className='imgCardBenefit'>
                                {data.icon && <img src={"/img/" + data.icon} alt="" />}
                            </div>
                            {data.title && <p className="titleText">{data.title}</p>}
                        </div>
                        <p className={`${data.title && 'secondaryText'}`}>{data.text}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}


export default BenefitsCardsComponent;
