import React from "react";
import { useNavigate } from "react-router";
import "./contractor.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";

const ContractingComponent = () => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const { t } = useTranslation();

    function CardServices({ imgBackground, text, route, title }) {
        return (
            <div className='cardServiceContainer'>
                <div className='cardTitleContainer'>
                    <div className="positionsApplyContainer">
                        <img src={`${imgBackground}`} alt="" className="positionsApplyImgBackground" />
                        <div className="positionsApplyBackground">

                            <p className="positionsApplyText">{title}</p>
                        </div>
                    </div>
                </div>
                <p className='mainTextCardService'>{text}</p>
                <ButtonLinkComponent
                    translateButton={'HIRE_EXPERTS'}
                    url={route}
                />
            </div>
        )
    }

    const cardRolesToWorkData = [
        {
            title: t('SOFTWARE_DEVELOPER'),
            text: t('SOFTWARE_DEVELOPER_DESC'),
            imgBackground: '/img/openart-image_QMcNbC6M_1715792822145_raw 1.jpg',
            url: "/contractors/outsourcing/apply"
        },
        {
            title: t('CYBERSECURITY_EXPERT'),
            text: t('CYBERSECURITY_EXPERT_DESC'),
            imgBackground: '/img/openart-image_UhNKA27w_1715791682693_raw 1.jpg',
            url: "/contractors/outsourcing/apply"
        },
        {
            title: t('FULLSTACK_DEVELOPER'),
            text: t('FULLSTACK_DEVELOPER_DESC'),
            imgBackground: '/img/young-adult-programmer-typing-computer-office-generated-by-ai 1.jpg',
            url: "/contractors/outsourcing/apply"
        },
        {
            title: t('DATABASE_EXPERT'),
            text: t('DATABASE_EXPERT_DESC'),
            imgBackground: '/img/openart-image_YyQnCnLS_1715793412802_raw 1.jpg',
            url: "/contractors/outsourcing/apply"
        },
        {
            title: t('UX_UI_DESIGNER'),
            text: t('UX_UI_DESIGNER_DESC'),
            imgBackground: '/img/openart-image_vGkkH9SM_1715791353615_raw 1.jpg',
            url: "/contractors/outsourcing/apply"
        }
    ];

    const cardBenefitsData = [
        {
            title: t('COST_SAVINGS'),
            text: t('COST_SAVINGS_DESC_OUT'),
            img: '/img/piggy-bank-overflowing-with-coins-symbolizes-savings-financial-education 1.jpg'
        },
        {
            title: t('ACCESS_SPECIALIZED_SKILLS'),
            text: t('ACCESS_SPECIALIZED_SKILLS_DESC'),
            img: '/img/openart-image_2Ln-rZZ6_1715806973560_raw 1.jpg'
        },
        {
            title: t('FLEXIBILITY_SCALABILITY'),
            text: t('FLEXIBILITY_SCALABILITY_DESC'),
            img: '/img/businessman-interacting-with-futuristic-graphics 1.jpg'
        },
        {
            title: t('TIME_SAVINGS'),
            text: t('TIME_SAVINGS_DESC'),
            img: '/img/3d-render-hourglass-with-gold-coins-background 1.jpg'
        }
    ];

    const TargetComponent = ({ img, text, className, title }) => {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p>{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="contractingMaincontainer">
                <MainTitleComponent
                    title={t('CONNECT_BEST_WORKHAND')}
                    text={t('FIND_SKILLED_TALENT')}
                    buttonText={t('HIRE_DEVELOPERS')}
                    url={'/contractors/outsourcing/apply'}
                />
                <section className="rolesContractingSection">
                    <h1 className="title">{t('ROLES_YOU_CAN_GET')}</h1>
                    <div className="ServicesContainer">
                        {cardRolesToWorkData.map((data, index) => (
                            <CardServices
                                key={index}
                                title={data.title}
                                text={data.text}
                                imgBackground={data.imgBackground}
                                route={data.url}
                            />
                        ))}
                    </div>
                </section>
                <section className="benefitsSection">
                    <h1 className="title">{t('ENDLESS_BENEFITS')}</h1>
                    <div className="roofsContainer">
                        {cardBenefitsData.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.img}
                                text={data.text}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                </section>
                <ButtonLinkComponent
                    translateButton={'HIRE_DEVELOPERS'}
                    url={"/contractors/outsourcing/apply"}
                />
            </main>
            <Footer />
        </>
    );
};

export default ContractingComponent;
