import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'; 

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang); 
  };

  return (
    <div className="language-switcher">
      <img 
        src="/OBJECTSES.png"  
        className="language-icon"
        onClick={() => handleLanguageChange('es')}
      />
      <img
        src="/OBJECTSEN.png" 
        className="language-icon"
        onClick={() => handleLanguageChange('en')}
      />
    </div>
  );
};

export default LanguageSwitcher;
