import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import './bannerComponent.css'
import { Link } from 'react-router-dom';

const BannerComponent = ({ url, translateText, className, translateTitle, translateTitle2, translateButton, classNameSide, index,blank }) => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route + "/", { replace: true });
    };

    const { t } = useTranslation();
    return (
        <div className={`services-portfolio-height ${className}`}>
            <div className={`max-container-services ${classNameSide}`}>
                <h1 className="titleBanner">{translateTitle}</h1>
                {translateTitle2 && <h1 className="titleBanner2 titleBanner">{translateTitle2}</h1>}
                {translateText && <p className="textBanner">{translateText}</p>}
                {translateButton && <button className={`${index % 2 === 1 ? 'button second' : 'button'}`}>
                    <Link to={url} className={`link`} target={blank ? '_blank' : undefined}>
                        {t(translateButton)}
                    </Link>
                </button>}
            </div>
        </div>
    );
};

export default BannerComponent;
