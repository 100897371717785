import React from "react";
import "./about-us.css";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";


const About_Us = () => {
    const { t } = useTranslation();

    const companyInfoArray = [
        {
            title: t("ABOUT_US_MISSION"),
            description: t("ABOUT_US_MISSION_DESCRIPTION"),
            image: "/img/mission 2 2.jpg"
        },
        {
            title: t("ABOUT_US_VISION"),
            description: t("ABOUT_US_VISION_DESCRIPTION"),
            image: "/img/mission 2 1.jpg"
        }
    ];


    const companyValuesArray = [
        {
            title: t("ABOUT_US_INNOVATION"),
            position: "left"
        },
        {
            title: t("ABOUT_US_EXCELLENCE"),
            position: "left"
        },
        {
            title: t("ABOUT_US_COMMITMENT"),
            position: "left"
        },
        {
            title: t("ABOUT_US_RESPONSIBILITY"),
            position: "left"
        },
        {
            title: t("ABOUT_US_COLLABORATION"),
            position: "left"
        },
        {
            title: t("ABOUT_US_ADAPTABILITY"),
            position: "button"
        },
        {
            title: t("ABOUT_US_CONTINUOUS"),
            position: "right"
        },
        {
            title: t("ABOUT_US_INTEGRITY"),
            position: "right"
        },
        {
            title: t("ABOUT_US_AUTONOMY"),
            position: "right"
        },
        {
            title: t("ABOUT_US_COMMUNICATION"),
            position: "right"
        },
        {
            title: t("ABOUT_US_IMPROVEMENT"),
            position: "right"
        }
    ];


    function TargetComponent({ img, text, title, className }) {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="textContainer">
                    <p className={`mainTitle`}>{title}</p>
                    <p className={`mainText`}>{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="aboutMaincontainer">
                <MainTitleComponent
                    title={t('ABOUT_US')}
                />
                <BannerComponent
                    translateTitle={t('GET_TO_KNOW')}
                    className={`${`bannerWorkWithUS`}`}
                    classNameSide={`${'r'}`}
                />

                <p className="textBlack aboutUS">{t('ABOUT_US_DESCRIPTION')}</p>


                <section className="about positionSection">
                    <div className="roofsContainer">
                        {companyInfoArray.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.image}
                                text={data.description}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                    <div className="aboutUSDescriptionContainer">
                        <img src="/img/cyber-security-experts-working-with-tech-devices-neon-lights 1.jpg" alt="" />
                        <div className="textContainer">
                            <p>{t('ABOUT_US_SOCIAL_RESPONSIBILITY_PART1')}</p>
                            <p>{t('ABOUT_US_SOCIAL_RESPONSIBILITY_PART2')}</p>
                        </div>
                    </div>
                </section>

                <MainTitleComponent title={t('ABOUT_US_OUR_VALUES')} />
                <section className="ourValuesAboutUs">
                    <div className="ourValuesMain">
                        <div>
                            {companyValuesArray
                                .filter((value) => value.position === "left")
                                .map((value) => (
                                    <p className="valueAboutUS">{`• ${value.title}`}</p>
                                ))}
                        </div>
                        <img src="/img/view-hand-holding-red-heart-symbolizing-affection-feelings 1.jpg" alt="" />
                        <div>
                            {companyValuesArray
                                .filter((value) => value.position === "right")
                                .map((value) => (
                                    <p className="valueAboutUS">{`• ${value.title}`}</p>
                                ))}
                        </div>
                    </div>
                    <div>
                        {companyValuesArray
                            .filter((value) => value.position === "button")
                            .map((value) => (
                                <p className="valueAboutUS">{`• ${value.title}`}</p>
                            ))}
                    </div>
                </section>
                <section className="ourTeam">
                    <MainTitleComponent title={t('ABOUT_US_MEET_OUR_TEAM')} />
                    <p className="textBlack aboutUS">{t('ABOUT_US_TEAM_DESCRIPTION')}</p>
                    <div className="teamMembers">
                        <div className="memberContainer">
                            <div className="borderContainer">
                                <div className="imageContainer">
                                    <img src="/img/Lorena Cordoba 1.png" alt="" />
                                </div>
                            </div>
                            <div className="infoContainer">
                                <p className="memberName">{t('ABOUT_US_LORENA_NAME')}</p>
                                <p className="memberPosition">{t('ABOUT_US_LORENA_POSITION')}</p>
                                <p className="memberDescription">{t('ABOUT_US_LORENA_DESCRIPTION_1')}</p>
                                <p className="memberDescription">{t('ABOUT_US_LORENA_DESCRIPTION_2')}</p>
                            </div>
                        </div>
                        {/* <div className="memberContainer">
                            <div className="borderContainer">
                                <div className="imageContainer">
                                    <img src="/img/Dhru Shah 1.png" alt="" />
                                </div>
                            </div>
                            <div className="infoContainer">
                                <p className="memberName">{t('ABOUT_US_DHRU_NAME')}</p>
                                <p className="memberPosition">{t('ABOUT_US_DHRU_POSITION')}</p>
                                <p className="memberDescription">{t('ABOUT_US_DHRU_DESCRIPTION_1')}</p>
                                <p className="memberDescription">{t('ABOUT_US_DHRU_DESCRIPTION_2')}</p>
                            </div>
                        </div> */}
                        <div className="memberContainer">
                            <div className="borderContainer">
                                <div className="imageContainer">
                                    <img src="/img/Julian Huertas 1.png" alt="" />
                                </div>
                            </div>
                            <div className="infoContainer">
                                <p className="memberName">{t('ABOUT_US_JULIAN_NAME')}</p>
                                <p className="memberPosition">{t('ABOUT_US_JULIAN_POSITION')}</p>
                                <p className="memberDescription">{t('ABOUT_US_JULIAN_DESCRIPTION_1')}</p>
                                <p className="memberDescription">{t('ABOUT_US_JULIAN_DESCRIPTION_2')}</p>
                            </div>
                        </div>
                        <div className="memberContainer">
                            <div className="borderContainer">
                                <div className="imageContainer">
                                    <img src="/img/Jose Bahamon 1.png" alt="" />
                                </div>
                            </div>
                            <div className="infoContainer">
                                <p className="memberName">{t('ABOUT_US_JOSUE_NAME')}</p>
                                <p className="memberPosition">{t('ABOUT_US_JOSUE_POSITION')}</p>
                                <p className="memberDescription">{t('ABOUT_US_JOSUE_DESCRIPTION_1')}</p>
                                <p className="memberDescription">{t('ABOUT_US_JOSUE_DESCRIPTION_2')}</p>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    );
};

export default About_Us;
