import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import InputComponent from "../../components/inputComponent";
import TextAreaComponent from "../../components/textAreaComponent";
import './techservicesForm.css'
import countries from "../../utilities/countries_states";
import axios from "axios";
import FetchSuccessComponent from "../../components/fetchComponent/fetchComponent";
import useDropdown from "../../hooks/searchDropdownHook";
import optionsCountries from "../../utilities/countries_code";
import { filterByFieldOption, filterByCountryObject, filterByFieldOptionMultiSelect, filterByFieldOptionCountries } from "../../utilities/filterfunctionsDropdowns";
import useServiceDropdown from "../../hooks/searchMultiSelectDropdownsHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Select } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import ContactWay from "../../components/contactOptionsComponent/contactOptionsComponent";
import useSortedCountries from "../../hooks/sortCountriesCode";


const TechServicesForm = () => {
    const { t, i18n } = useTranslation();
    // const [openDropdowns, setOpenDropdowns] = useState({});
    const [skillsArray, setSkillsArray] = useState([])

    const [filteredDepartments, setFilteredDepartments] = useState([])
    const initialData = {
        firstName: "",
        company: "",
        country: "",
        departmentState: "",
        phoneNumberCode: "",
        phoneNumber: "",
        candidateEmail: "",
        services: [],
        additionalInformation: "",
        policies: false
    };

    const combinedCountries = optionsCountries.map(country => {
        return {
            ...country,
            spanishName: country.country
        };
    });

    const {
        isOpen: isCountryDropdownOpen,
        setIsOpen: setIsCountryDropdownOpen,
        searchTerm: searchTermCountry,
        filteredArray: filteredCountries,
        inputRef: countryDropdownRef,
        handleSearchChange: handleSearchChangeCountry,
        selectedItem: selectedCountry,
        setSelectedItem: setSelectedCountry
    } = useDropdown(combinedCountries, filterByFieldOptionCountries);
    // } = useDropdown(countries.countries.map(country => country.name), filterByFieldOption);

    const {
        isOpen: isDepartmentDropdownOpen,
        setIsOpen: setIsDepartmentDropdownOpen,
        searchTerm: searchTermState,
        filteredArray: filteredState,
        inputRef: departmentDropdownRef,
        handleSearchChange: handleSearchChangeState,
        selectedItem: selectedState,
        setSelectedItem: setSelectedState
    } = useDropdown(filteredDepartments, filterByFieldOption);
    const {
        dropdowns,
        setDropdowns,
        setOpenDropdowns,
        openDropdowns,
        searchTerm,
        filteredArray,
        handleSearchChange,
        handleSelectOptionDropdown,
        addDropdown,
        setForm,
        form,
        servicesData,
        setServicesData,
        inputRef,
        removeDropdown,
        selectOptions,
        selectedService,
        handleChangeService
    } = useServiceDropdown(filterByFieldOptionMultiSelect, initialData, "services", "https://prod-04.westus.logic.azure.com:443/workflows/cfdd9ddca4334cdb8ea7a4c276041b10/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ux7vwhdPr3qDKQ6K52U8s2JC_DssuB7u4TjLzyV9gm8");

    const {
        isOpen: openDropdownCountryCode,
        setIsOpen: setOpenDropdownCountryCode,
        searchTerm: searchTermCountryCode,
        filteredArray: filteredCountryCode,
        inputRef: elementoRef,
        handleSearchChange: handleSearchChangeCountryCode,
        selectedItem: selectedCountryCode,
        setSelectedItem: setSelectedCountryCode
    } = useDropdown(optionsCountries, filterByCountryObject);

    const [errors, setErros] = useState({})

    const handleDropdown = (set) => {
        set((prev) => !prev);
    }


    const handleChange = (event) => {
        const { name, value, checked } = event.target

        if (name != "candidateEmail" && name !== "additionalInformation") {
            if (name === 'phoneNumber' || name === 'previousExperience') {
                if (value < 0) {
                    return;
                }
            } else {
                if (!/^[^\d]*$/.test(value)) {
                    return;
                }
            }
        }

        if (name === 'policies') {
            setForm({ ...form, [name]: checked })
        } else {
            setForm({ ...form, [name]: value })
        }

    }

    const handleSelectOption = (option, keyOption, set) => {
        if (keyOption == 'skills') {

            setSkillsArray([...skillsArray, option]);
            setForm({
                ...form,
                [keyOption]: [...skillsArray, option].join(",")
            });
        } else if (keyOption === "country") {
            setSelectedCountry(option);
            const country = countries.countries.find(country => country.name === option.country_EN);
            setFilteredDepartments(country ? country.states : []);
            setForm({ ...form, [keyOption]: option.country_EN })
        } else if (keyOption === "departmentState") {
            setSelectedState(option);
            setForm({ ...form, [keyOption]: option })
        } else if (keyOption === "phoneNumberCode") {
            setSelectedCountryCode(option);
            setForm({ ...form, [keyOption]: option })
        } else {
            setForm({ ...form, [keyOption]: option })
        }
        set((prev) => !prev);
    }

    const [selectedContactWay, setSelectedContactWay] = useState("");

    const onValidate = (form) => {
        let errors = {};

        if (!form.firstName.trim()) {
            errors.firstName = t('NAME_REQUIRED');
        }

        if (!form.company.trim()) {
            errors.company = t('COMPANY_REQUIRED');
        }

        if (!form.country.trim()) {
            errors.country = t('COUNTRY_REQUIRED');
        }

        if (!form.departmentState.trim()) {
            errors.departmentState = t('DEPARTMENT_STATE_REQUIRED');
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumberCode.trim())) {
            errors.phoneNumberCode = t('PHONE_CODE_REQUIRED');
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (!form.phoneNumber.trim())) {
            errors.phoneNumber = t('PHONE_NUMBER_REQUIRED');
        }

        if (((selectedContactWay === "1") || (selectedContactWay === "3")) && (!form.candidateEmail.trim())) {
            errors.candidateEmail = t('EMAIL_REQUIRED');
        }

        if (!selectedContactWay) {
            errors.contactWay = t("CONTACT_WAY_VALIDATION")
        }

        if (form.services.length === 0) {
            errors.services = t('SERVICE_REQUIRED');
        }

        if (!form.additionalInformation.trim()) {
            errors.additionalInformation = t('ADDITIONAL_INFO_REQUIRED');
        }

        if (!form.policies) {
            errors.policies = t('POLICIES_REQUIRED');
        }

        if (((selectedContactWay === "2") || (selectedContactWay === "3")) && (form.phoneNumber <= 0)) {
            errors.phoneNumber = t('PHONE_NUMBER_ZERO')
        }

        return errors;
    };



    const handleClickOutside = (event) => {
        if (countryDropdownRef.current && !countryDropdownRef.current.contains(event.target)) {
            setIsCountryDropdownOpen(false);
        }
        if (departmentDropdownRef.current && !departmentDropdownRef.current.contains(event.target)) {
            setIsDepartmentDropdownOpen(false);
        }
        if (elementoRef.current && !elementoRef.current.contains(event.target)) {
            setOpenDropdownCountryCode(false);
        }
        if (!event.target.classList.contains('inputNumber2') &&
            !event.target.classList.contains('dropdownOption')
        ) {
            setOpenDropdowns(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const form2 = useRef();

    onValidate(form)

    const [isFetchSuccessful, setIsFetchSuccessful] = useState(false);
    const [errorsMessage, setErrosMessage] = useState(false)

    const sendEmail = async (e) => {
        e.preventDefault();

        const validationErrors = onValidate(form);
        setErros(validationErrors);
        if (Object.keys(validationErrors).length > 0) {
            setErrosMessage(true);
        }

        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch('https://prod-191.westus.logic.azure.com:443/workflows/85c0198874204e96b64f645dfefb635a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=hVLwmNWx754fuc3jHFEQrIrYt0hNtDduIEpA8g1SAgY', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(form),
                });

                if (response.ok) {
                    setIsFetchSuccessful(true); // Cambia el estado a true si la respuesta es exitosa
                } else {
                    setIsFetchSuccessful(false); // Cambia el estado a false si la respuesta no es exitosa
                }

            } catch (error) {

            }
        }
    };

    const sortedCountriesCode = useSortedCountries("country", filteredCountryCode)
    const sortedCountries = useSortedCountries("country", filteredCountries)

    return (
        <>
            <Header />
            <div className="mainImgFormTechnologiesApply"></div>
            <main className="workWithUsFormMainContainer">
                <h2 className="titleH2">{t('FORM_STEP_TITLE')}</h2>
                <div className="contact-us-forms">
                    <form ref={form2} onSubmit={sendEmail}>
                        <p className="form-text">{t('FORM_INSTRUCTION')}</p>
                        <div className="nameContainer">
                            <div className="errorContainer">
                                <InputComponent
                                    className={`input-width`}
                                    name={'firstName'}
                                    type={'text'}
                                    value={form.firstName}
                                    handleChange={handleChange}
                                    label={`${t('FORM_LABEL_NAME')}*`}
                                />
                                {errors.firstName && <div className="alert alert-danger">{errors.firstName}</div>}
                            </div>
                        </div>
                        <div className="nameContainer">
                            <div className="errorContainer">
                                <InputComponent
                                    className={`input-width`}
                                    name={'company'}
                                    type={'text'}
                                    value={form.company}
                                    handleChange={handleChange}
                                    label={`${t('FORM_COMPANY_NAME')}*`}
                                />
                                {errors.company && <div className="alert alert-danger">{errors.company}</div>}
                            </div>
                        </div>
                        <div className="AddressRemoteContainer">
                            <div className="phoneContactContainer">
                                <div className="phoneContactDropdown" ref={countryDropdownRef}>
                                    <p>{t('FORM_LABEL_COUNTRY')}*</p>
                                    <div className="phoneContactInputContainer">
                                        <input
                                            type="text"
                                            className={`inputNumber1 ${isCountryDropdownOpen && "active"}`}
                                            onClick={() => handleDropdown(setIsCountryDropdownOpen)}
                                            onChange={handleSearchChangeCountry}
                                            placeholder={t('WORK_US_YOUR_COUNTRY')}
                                            name="country"
                                            value={i18n.language === 'es' ? selectedCountry.country : selectedCountry.country_EN ? i18n.language === 'es' ? selectedCountry.country : selectedCountry.country_EN : searchTermCountry}
                                            autoComplete="off"
                                        />
                                        <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                    </div>
                                    {errors.country && <div className="alert alert-danger">{errors.country}</div>}
                                    {isCountryDropdownOpen && (
                                        <div className="phoneContactOptions">
                                            <div className="optionsContainer">

                                                {sortedCountries.map(option => (
                                                    <p
                                                        onClick={() => handleSelectOption(option, "country", setIsCountryDropdownOpen)}
                                                        key={option.country_EN}
                                                    >
                                                        {i18n.language === 'es' ? option.spanishName : option.country_EN}
                                                    </p>
                                                ))}
                                                {(sortedCountries.length === 0) && (
                                                    <p style={{ color: 'grey' }}>
                                                        No Countries
                                                    </p>
                                                )}

                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="phoneContactContainer">
                                <div className="phoneContactDropdown" ref={departmentDropdownRef}>
                                    <p>{t('FORM_LABEL_DEPARTMENT')}*</p>
                                    <div className="phoneContactInputContainer">
                                        <input
                                            type="text"
                                            className={`inputNumber1 ${isDepartmentDropdownOpen && "active"}`}
                                            onClick={() => handleDropdown(setIsDepartmentDropdownOpen)}
                                            onChange={handleSearchChangeState}
                                            placeholder={t('FORM_DEPARTMENT_LOCATION')}
                                            name="departmentState"
                                            value={selectedState ? selectedState : searchTermState}
                                            autoComplete="off"
                                        />
                                        <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                    </div>
                                    {errors.departmentState && <div className="alert alert-danger">{errors.departmentState}</div>}
                                    {isDepartmentDropdownOpen && (
                                        <div className="phoneContactOptions">
                                            <div className="optionsContainer">
                                                {filteredState.length > 0 ? (
                                                    filteredState.map((option) => {
                                                        return (
                                                            <p
                                                                onClick={() => handleSelectOption(option, "departmentState", setIsDepartmentDropdownOpen)}
                                                                key={option}
                                                            >
                                                                {option}
                                                            </p>
                                                        );
                                                    })
                                                ) : null}

                                                {(filteredState.length === 0) && (
                                                    <p style={{ color: 'grey' }}>
                                                        No state
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <ContactWay
                            selectedContactWay={selectedContactWay}
                            setSelectedContactWay={setSelectedContactWay}
                        />
                        <div className="phoneEmailContainer">
                            {((selectedContactWay === "2") || (selectedContactWay === "3")) && (<div className="phoneContactContainerMain">
                                <p>{t('FORM_PHONE_NUMBER')}*</p>
                                <div className="phoneContactContainer phoneCode">
                                    <div className="phoneContactDropdown phoneCode" ref={elementoRef}>
                                        <div className="phoneContactInputContainer">
                                            <input
                                                type="text"
                                                className={`inputNumber ${openDropdownCountryCode && "active"}`}
                                                onClick={() => handleDropdown(setOpenDropdownCountryCode)}
                                                onChange={handleSearchChangeCountryCode}
                                                name="phoneNumberCode"
                                                value={selectedCountryCode ? selectedCountryCode : searchTermCountryCode}
                                                autoComplete="off"
                                            />
                                            <img src={'/img/arrowDownDropdown.svg'} alt="" />
                                        </div>
                                        {openDropdownCountryCode && (
                                            <div className="phoneContactOptions">
                                                <div className="optionsContainer">
                                                    {sortedCountriesCode.map((option) => {
                                                        return <p onClick={() => handleSelectOption(option.code, "phoneNumberCode", setOpenDropdownCountryCode)} key={option.country}>
                                                            {i18n.language === 'es' ? option.country : option.country_EN}
                                                        </p>
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="errorContainer">
                                        <InputComponent
                                            className={`input-width`}
                                            name={'phoneNumber'}
                                            type={'number'}
                                            handleChange={handleChange}
                                            value={form.phoneNumber}
                                        />
                                    </div>
                                </div>
                                {errors.phoneNumber && <div className="alert alert-danger">{errors.phoneNumber}</div>}
                                {errors.phoneNumberCode && <div className="alert alert-danger">{errors.phoneNumberCode}</div>}
                            </div>)}
                            {((selectedContactWay === "1") || (selectedContactWay === "3")) && (<div className="errorContainer email">
                                <InputComponent
                                    className={`input-width`}
                                    label={`${t('FORM_EMAIL')}*`}
                                    name={'candidateEmail'}
                                    type={'email'}
                                    value={form.candidateEmail}
                                    handleChange={handleChange}
                                />
                                {errors.candidateEmail && <div className="alert alert-danger">{errors.candidateEmail}</div>}
                            </div>)}
                        </div>
                        {errors.contactWay && <div className="alert alert-danger">{errors.contactWay}</div>}
                        <div className={`phoneContactContainer services ${(dropdowns.length > 1 || dropdowns[dropdowns.length - 1].value) && "edit"}`}>
                            <div className="dropdownsContainer" ref={inputRef}>
                                <p>{t('FORM_LABEL_SERVICE')}*</p>
                                {dropdowns.map((dropdown) => (
                                    <div key={dropdown.id} className="phoneContactDropdown">
                                        <div className="phoneContactInputContainer">
                                            <div className="servicesDropdownContainer">
                                                <Select
                                                    data={selectOptions}
                                                    value={selectedService}
                                                    selectMultiple={true}
                                                    inputProps={{
                                                        className: "inputNumber2"
                                                    }}
                                                    theme="ios"
                                                    themeVariant="light"
                                                    filter={true}
                                                    onChange={handleChangeService}
                                                    placeholder={t('WORK_US_VACANCY')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {dropdowns[dropdowns.length - 1].value && (
                                    <p className="buttonAddDropdown" onClick={addDropdown}>
                                        {t('FORM_ADD_SERVICE')}
                                    </p>
                                )}
                                {errors.services && <div className="alert alert-danger">{errors.services}</div>}
                            </div>
                        </div>
                        <p>{t('FORM_LABEL_EXTRA_INFO')}*</p>
                        <div className="errorContainer">
                            <TextAreaComponent
                                className={`input-width textArea`}
                                name={'additionalInformation'}
                                handleChange={handleChange}
                                value={form.additionalInformation}
                            />
                            {errors.additionalInformation && <div className="alert alert-danger">{errors.additionalInformation}</div>}
                        </div>
                        <div className="termsAndConditionsContainer">
                            <input type="checkbox" name="policies" onChange={handleChange} />
                            <p className="termsAndConditionsText">{t('FORM_POLICY')} <a href="/PoliticaDeTratamientoDeDatosPersonales.pdf" target="_Blank">{`${t('FORM_POLICY_LINK')}*`}</a></p>
                        </div>
                        {errors.policies && <div className="alert alert-danger">{errors.policies}</div>}
                        {errorsMessage && <div className="alert alert-danger">{t("ERROR_MSG")}</div>}

                        <button className="button">{t('FORM_BUTTON')}</button>
                        <FetchSuccessComponent isFetchSuccessful={isFetchSuccessful} setIsFetchSuccessful={setIsFetchSuccessful} url={'/techservices'} />
                    </form>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default TechServicesForm;
