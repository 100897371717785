import React, { useState } from "react";
import "./financial-services.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";
import Footer from "../../../components/footer/footer";

const FinancialServices = () => {
    const { t } = useTranslation();

    const services = [
        {
            title: t('FINANCIALS_MAIN_SERVICE_1_TITLE'),
            icon: "accounting-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_2_TITLE'),
            icon: "tax-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_3_TITLE'),
            icon: "payroll-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_4_TITLE'),
            icon: "budgeting-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_5_TITLE'),
            icon: "reporting-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_6_TITLE'),
            icon: "compliance-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_7_TITLE'),
            icon: "closing-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_8_TITLE'),
            icon: "call-center-icon.svg"
        },
        {
            title: t('FINANCIALS_MAIN_SERVICE_9_TITLE'),
            icon: "custom-financials-icon.svg"
        }
    ];


    const keyData = [
        {
            title: t('FINANCIALS_MAIN_KEY_DATA_1_TITLE'),
        },
        {
            title: t('FINANCIALS_MAIN_KEY_DATA_2_TITLE'),
        },
        {
            title: t('FINANCIALS_MAIN_KEY_DATA_2_TITLE'),
        }
    ];

    const featuresData = [
        {
            title: t('FINANCIALS_MAIN_FEATURE_1_TITLE'),
            text: t('FINANCIALS_MAIN_FEATURE_1_TEXT'),
            imgSrc: '/img/front-view-graph-made-out-coins 1.jpg',
            className: 'competitive-compensation'
        },
        {
            title: t('FINANCIALS_MAIN_FEATURE_2_TITLE'),
            text: t('FINANCIALS_MAIN_FEATURE_2_TEXT'),
            imgSrc: '/img/person-taking-notes-notebook-while-working-from-home 2.jpg',
            className: 'competitive-compensation'
        },
        {
            title: t('FINANCIALS_MAIN_FEATURE_3_TITLE'),
            text: t('FINANCIALS_MAIN_FEATURE_3_TEXT'),
            imgSrc: '/img/hand-man-playing-chess-business-planning-comparison-metaphor-selective-focus 1.jpg',
            className: 'support-training'
        },
        {
            title: t('FINANCIALS_MAIN_FEATURE_4_TITLE'),
            text: t('FINANCIALS_MAIN_FEATURE_4_TEXT'),
            imgSrc: '/img/top-viewtop-view-manager-employee-doing-teamwork-business-office-looking-charts-laptop-display 1.jpg',
            className: 'competitive-compensation'
        }
    ];


    function TargetHome({ img, text, title }) {
        return (
            <div className='targetHomeContainer'>
                <img src={img} alt="" />
                <div className="targetInfoContainer">
                    <h2>{title}</h2>
                    <p className="mainText">{text}</p>
                </div>
            </div>
        )
    }

    const cardBenefitsData = [
        {
            title: t('FINANCIALS_MAIN_BENEFIT_1_TITLE'),
            text: t('FINANCIALS_MAIN_BENEFIT_1_TEXT'),
            img: '/img/person-taking-notes-notebook-while-working-from-home 1.jpg'
        },
        {
            title: t('FINANCIALS_MAIN_BENEFIT_2_TITLE'),
            text: t('FINANCIALS_MAIN_BENEFIT_2_TEXT'),
            img: '/img/person-taking-notes-notebook-while-working-from-home 3.jpg'
        },
        {
            title: t('FINANCIALS_MAIN_BENEFIT_3_TITLE'),
            text: t('FINANCIALS_MAIN_BENEFIT_3_TEXT'),
            img: '/img/person-taking-notes-notebook-while-working-from-home 4.jpg'
        },
        {
            title: t('FINANCIALS_MAIN_BENEFIT_4_TITLE'),
            text: t('FINANCIALS_MAIN_BENEFIT_4_TEXT'),
            img: '/img/person-taking-notes-notebook-while-working-from-home 5.jpg'
        }
    ];

    const TargetComponent = ({ img, text, className, title }) => {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p className="textH4">{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="financialsMainContainer">
                <MainTitleComponent
                    title={t('FINANCIALS_MAIN_TITLE')}
                />
                <section className="services-container" id="services-container">
                    <div className="services-portfolio">
                        <BannerComponent
                            translateTitle={t("FINANCIALS_MAIN_BANNER_TITLE_1")}
                            translateTitle2={t("FINANCIALS_MAIN_BANNER_TITLE_2")}
                            translateButton={t('FINANCIALS_MAIN_BANNER_BUTTON')}
                            className={`financialsBanner`}
                            classNameSide={`l`}
                            url={'/financials/adquire'}
                        />
                    </div>
                </section>
                <p className="mainTextTitle top">{t('FINANCIALS_MAIN_SUB_DESCRIPTION')}</p>
                <h1 className="titleH2">
                    {t('FINANCIALS_MAIN_PROBLEM_TITLE')}
                </h1>
                <div className="financialsDescriptionContainer">
                    <div className="textContainer">
                        <p className="textH4">{t('FINANCIALS_MAIN_PROBLEM_DESCRIPTION_1')}</p>
                        <p className="textH4">{t('FINANCIALS_MAIN_PROBLEM_DESCRIPTION_2')}</p>
                    </div>
                    <img src="/img/smiley-woman-working-with-laptop-papers-office 1.jpg" alt="" />
                </div>
                <section className="problemsFeaturesSection">
                    <div className="problemsFeaturesContainer">
                        {keyData.map((benefit, index) => {
                            return (<div className="keyBeneffitsContainer" key={index}>
                                <p className="textH4">{t(benefit.title)}</p>
                            </div>)
                        })}
                    </div>
                </section>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('FINANCIALS_MAIN_SERVICES_TITLE')}
                        cardsData={services}
                    />
                </section>
                <h1 className="titleH2">
                    {t('FINANCIALS_MAIN_WHY_CHOOSE_US_TITLE')}
                </h1>
                <section className="targetsSection">
                    <div className="targetsContainer">
                        {featuresData.map((feature, index) => (
                            <TargetHome
                                key={index}
                                title={feature.title}
                                img={feature.imgSrc}
                                text={feature.text}
                                className={feature.className}
                            />
                        ))}
                    </div>
                </section>
                <section className="benefitsSection ApplySection">
                    <h1 className="titleH2">{t('FINANCIALS_MAIN_HOW_TO_GET_STARTED_TITLE')}</h1>
                    <div className="roofsContainer">
                        {cardBenefitsData.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.img}
                                text={data.text}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                </section>
                <ButtonLinkComponent
                    translateButton={t('FINANCIALS_MAIN_BUTTON_TEXT')}
                    className={'buttonFinancials'}
                    url={'/financials/adquire'}
                />
            </main>
            <Footer />
        </>
    );
};

export default FinancialServices;
