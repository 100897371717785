import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRedirect = (route) => {
    window.scrollTo(0, 0);
    navigate(route);
  };

  return (
    <>
      <div className="buttonWhatsapp">
        <a
          href="https://wa.me/+573025779018"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/img/WhatsappWhite.svg" alt="" className="whatsappIconMain" />
        </a>
      </div>
      <div className="footer-container">
        <div className="footer-content">
          <div className="sig-solutions-datapolicy-content">
            <img
              className="logo-5ig-footer"
              src={`/img/Logo5ig.png`}
              onClick={() => handleRedirect("/")}
              alt="Logo_5ig"
            ></img>
          </div>
          <div className="policy-approval">
            <div className="policy-logos-info">
              <div>
                <p>{t("FOOTER_CONTACT_US")}</p>
              </div>
              <div className="footer-logos">
                <a
                  href="https://www.instagram.com/5ig_solutions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={`/img/insta.svg`} alt="Instagram"></img>
                </a>
                <a
                  href="https://www.linkedin.com/company/5ig-solutions/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={`/img/linkedin.svg`} alt="LinkedIn"></img>
                </a>
                <a
                  href="https://x.com/5ig_solutions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={`/img/xIcon.svg`} alt="X"></img>
                </a>
                <a
                  href="https://wa.me/+573025779018"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={`/img/Whatsapp.svg`} alt="Whatsapp"></img>
                </a>
                <div className="emailFooterContainer">
                  <img src={`/img/emailIcon.svg`} alt="email5IG"></img>
                  <p>contact@5igsolutions.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="line-middle"></div>
          <div className="policy-title-links">
            <h3 className="policy-publication">{t("FOOTER_POLICIES")}</h3>
            <a href="/TerminosDeUso.pdf" className="policy-link" target="_blank">
              {t("FOOTER_TERMS")} <br />
            </a>
            <a
              href="/PoliticaDeTratamientoDeDatosPersonales.pdf"
              className="policy-link"
              target="_blank"
            >
              {t("FOOTER_DATA")}
            </a>
          </div>
        </div>
        <div className="footer-copyright">
          <p>{t("COPYRIGHT")}</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
