import React from "react";
import { useNavigate } from "react-router";
import "./outsourcing.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";

const Outsourcing = () => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const { t } = useTranslation();

    const servicesData = [
        {
            translateTitle: t('MARKET_EXPANSION'),
            translateTitle2: t('STRATEGIES'),
            translateText: t('MARKET_EXPANSION_DESC'),
            className: 'market',
            url: '/contractors/work/apply',
        },
        {
            translateTitle: t('BUSINESS_DEVELOPMENT'),
            translateTitle2: t('AND_GROWTH_PLANNING'),
            translateText: t('BUSINESS_DEVELOPMENT_DESC'),
            className: 'development',
            url: '/contractors/work/apply',
        },
        {
            translateTitle: t('DIGITAL_TRANSFORMATION'),
            translateTitle2: t('ADVISORY'),
            translateText: t('DIGITAL_TRANSFORMATION_DESC'),
            className: 'transformation',
            url: '/contractors/work/apply',
        },
        {
            translateTitle: t('TECHNOLOGY_ROADMAP'),
            translateTitle2: t('DEVELOPMENT'),
            translateText: t('TECHNOLOGY_ROADMAP_DESC'),
            className: 'roadmap',
            url: '/contractors/work/apply',
        },
        {
            translateTitle: t('STARTUP_CONSULTING'),
            translateText: t('STARTUP_CONSULTING_DESC'),
            className: 'consulting',
            url: '/contractors/work/apply',
        },
    ];

    const cardsData = [
        {
            icon: "shopIcon.svg",
            text: t('INCREASED_MARKET_SHARE'),
        },
        {
            icon: "growthChart.svg",
            text: t('STRATEGIC_PLANNING'),
        },
        {
            icon: "efficiencyIcon.svg",
            text: t('ENHANCED_DIGITAL_CAPABILITIES'),
        },
        {
            icon: "navigateIcon.svg",
            text: t('EXPERT_GUIDANCE'),
        },
        {
            icon: "strategiesIcon.svg",
            text: t('TAILORED_STRATEGIES'),
        },
    ];

    return (
        <>
            <Header />
            <main className="outsourcingMaincontainer">
                <MainTitleComponent
                    title={t('CONNECT_BEST_WORKHAND')}
                    text={t('EMPOWERING_IT_COMPANIES')}
                    buttonText={t('BOOK_APPOINTMENT_BUTTON')}
                    url={'https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/'}
                    blank={true}

                />
                <div className="services-container" id="services-container">
                    <div className="services-portfolio">
                        {servicesData.map((service, index) => (
                            <BannerComponent
                                key={index}
                                translateTitle={service.translateTitle}
                                translateTitle2={service.translateTitle2}
                                translateText={service.translateText}
                                className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
                                index={index}
                                classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                                translateButton={t('EXPLORE')}
                                url={service.url}
                            />
                        ))}
                    </div>
                </div>
                <BenefitsCardsComponent
                    title={t('GREAT_BENEFITS')}
                    cardsData={cardsData}
                />
                <ButtonLinkComponent
                    translateButton={'BOOK_APPOINTMENT_BUTTON'}
                    url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                    blank={true}
                />
            </main>
            <Footer />
        </>
    );
};

export default Outsourcing;
