import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Services from "../../components/services/main/services";
import './techServices.css'
const TechServicesMain = () => {
    const { t } = useTranslation();
    return (
        <>
            <Header />
            <main className="techServicesMainContainer">
                <h1 className="title">{t('TECH_SERVICES_TITLE')}</h1>
                <h2 className="title">{t('START_AUTOMATING_WORLD')}</h2>
                <Services />
            </main>
            <Footer />
        </>
    );
};

export default TechServicesMain;
