import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Events.css";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import EventCardsComponent from "../../components/EventCards/EventCardsComponent";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Events = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://prod-94.westus.logic.azure.com:443/workflows/45d529b349d3458c98ff7445314f802d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=LJ4GKyIWBEy3EyV9q8AAirIEtkqRFBIe5MfMr4eMEbk')
            .then((response) => {
                setEvents(response.data)
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, []);

    const liveEvents = events.filter(event => event.CATEGORY === 3);
    const upComingEvents = events.filter(event => event.CATEGORY === 1);
    const recordedEvents = events.filter(event => event.CATEGORY === 2);

    return (
        <>
            <Header />
            <main className="eventsMaincontainer">
                {isLoading ? (
                    <div className="loadincContainer">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                ) : (<>
                    <MainTitleComponent
                        title={t('UP_COMING_EVENTS_5IG')}
                    />

                    <section className="image-text-section">
                        <div className="image-container">
                            <img src="/img/people-taking-part-high-protocol-event 1.png" alt="Events" className="events_image" />
                        </div>
                        <div className="overlay-text">
                            <p>{t('STAY_UPDATED')}</p>
                        </div>
                    </section>

                    <MainTitleComponent title={t('LIVE_EVENTS')} isSmallTitle />
                    <EventCardsComponent
                        cardsData={liveEvents}
                    />
                    <MainTitleComponent title={t('UP_COMING_EVENTS')} isSmallTitle />
                    <EventCardsComponent
                        cardsData={upComingEvents}
                    />
                    <MainTitleComponent title={t('RECORDED_EVENTS')} isSmallTitle />
                    <EventCardsComponent
                        cardsData={recordedEvents}
                    />
                </>)}
            </main>
            <Footer />
        </>
    );
};

export default Events;
