import React, { useState, useEffect, useRef } from "react";
import "./blog.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";
import axios from "axios";
import DOMPurify from "dompurify";
import parse from 'html-react-parser';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Blog = () => {
    const { t, i18n } = useTranslation();
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios.get('https://prod-46.westus.logic.azure.com:443/workflows/c6d627995fd54eb9942a7f368a81fee7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JLe51vAib509ToTy0NmsiQcIOAL6aLjXwAqoObDPEQ0')
            .then((response) => {
                const cleanedArticles = response.data.map((article, index) => {
                    const cleanHTML = DOMPurify.sanitize(i18n.language === 'es' ? article.Article_body : article.Article_body_EN);
                    const plainText = parse(cleanHTML).props.children;
                    return { ...article, Article_body: plainText, index: index + 1 };
                });
                setArticles(cleanedArticles);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, [i18n.language]);
    const featuredArticles = articles.filter(article => article.Article_featured);

    function extractTextFromReactElement(element) {
        if (typeof element === 'string') {
            return element;
        }
        if (Array.isArray(element)) {
            return element.map(extractTextFromReactElement).join(' ');
        }
        if (element.props && element.props.children) {
            return extractTextFromReactElement(element.props.children);
        }
        return '';
    }

    function truncateText(text, charLimit) {
        if (text.length > charLimit) {
            return text.slice(0, charLimit) + '...';
        }
        return text;
    }


    function extractAndTruncateText(articleBody, wordLimit) {
        let firstParagraph = '';

        if (Array.isArray(articleBody)) {
            if (articleBody.length > 0) {
                firstParagraph = articleBody[0].props ? extractTextFromReactElement(articleBody[0].props.children) : articleBody[0];
            }
        } else if (articleBody.props && articleBody.props.children) {
            firstParagraph = extractTextFromReactElement(articleBody.props.children);
        } else if (typeof articleBody === 'string') {
            firstParagraph = articleBody;
        }

        return truncateText(firstParagraph, wordLimit);
    }



    function Articles({ img, title, text, className, index }) {
        const truncatedText = truncateText(text, 80); // Ajusta el número de palabras según tu necesidad

        return (
            <div className={`articleCardContainer ${className}`}>
                <div>
                    <img src={`data:image/png;base64,${img}`} alt="" />
                    <h2 className="titleH4Bold">{title}</h2>
                    <p className="mediumText">{truncatedText}</p>
                </div>
                <ButtonLinkComponent
                    translateButton={'BLOG_BUTTON'}
                    url={`/blog/article/${index}`}
                    blank={false}
                />
            </div>
        )
    }

    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 9;

    // Calcula el número total de páginas
    const totalPages = Math.ceil(articles.length / articlesPerPage);

    // Calcula los artículos que se deben mostrar en la página actual
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

    // Funciones para cambiar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const nextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const prevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const renderPageNumbers = () => {
        let pageNumbers = [];
        let uniqueNumbers = new Set();

        const addPageNumber = (number, isEllipsis = false) => {
            if (!uniqueNumbers.has(number)) {
                uniqueNumbers.add(number);
                if (isEllipsis) {
                    pageNumbers.push(<span key={`ellipsis${number}`}>...</span>);
                } else {
                    pageNumbers.push(
                        <button
                            key={number}
                            onClick={() => paginate(number)}
                            className={number === currentPage ? 'active' : ''}
                        >
                            {number}
                        </button>
                    );
                }
            }
        };

        addPageNumber(1);

        if (currentPage > 2) {
            addPageNumber('ellipsis1', true);
        }

        if (currentPage > 1) {
            addPageNumber(currentPage - 1);
        }

        addPageNumber(currentPage);

        if (currentPage < totalPages) {
            addPageNumber(currentPage + 1);
        }

        if (currentPage < totalPages - 1) {
            addPageNumber('ellipsis2', true);
        }

        addPageNumber(totalPages);

        return pageNumbers;
    };


    return (
        <>
            <Header />
            <main className="blogMainContainer">
                {isLoading ? (
                    <div className="loadincContainer">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                ) : (<>
                    <div className="blogMainBanner">
                        <h1 className="title">{t('BLOG_TITLE')}</h1>
                    </div>
                    <h2 className="titleH2">{t('BLOG_FEATURED_TITLE')}</h2>
                    <section className="featuredArticles">
                        {featuredArticles.length > 0 && (
                            featuredArticles.map((article, index) => (
                                <Articles
                                    key={index}
                                    img={article.Article_img}
                                    title={i18n.language === 'es' ? article.Article_Name : article.Article_Name_EN}
                                    text={extractAndTruncateText(article.Article_body)}
                                    index={article.index}
                                />
                            ))
                        )}
                    </section>
                    <h2 className="titleH2">{t("BLOG_LATEST_TITLE")}</h2>
                    <section className="LatestNews">
                        {currentArticles.length > 0 && (
                            currentArticles.map((article, index) => (
                                <Articles
                                    key={index}
                                    img={article.Article_img}
                                    title={i18n.language === 'es' ? article.Article_Name : article.Article_Name_EN}
                                    text={extractAndTruncateText(article.Article_body)}
                                    index={article.index}
                                />
                            ))
                        )}
                    </section>
                    <div className="pagination">
                        <div className="leftArrowContainer arrow" onClick={prevPage}>
                            <img src={`/img/${currentPage !== 1 ? 'leftArrow.svg' : 'leftArrowDisabled.svg'}`} alt="" />
                        </div>
                        <div>
                            {renderPageNumbers()}
                        </div>
                        <div className="rightArrowContainer arrow" onClick={nextPage}>
                            <img src={`/img/${currentPage !== totalPages ? 'rightArrow.svg' : 'rightArrowDisabled.svg'}`} alt="" />
                        </div>
                    </div>
                </>)}
            </main>
            <Footer />
        </>
    );
};

export default Blog;
