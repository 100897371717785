import axios from 'axios';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from "react-i18next";

function useServiceDropdown(filterFunction, initialForm, keyForm, api_url) {
    const { i18n } = useTranslation();
    const [dropdowns, setDropdowns] = useState([{ id: 1, value: '' }]);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [form, setForm] = useState(initialForm);
    const [servicesData, setServicesData] = useState([]);
    const [filteredArray, setFilteredArray] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const inputRef = useRef();

    useEffect(() => {
        let shouldUpdateForm = false;
        axios.get(api_url)
            .then((response) => {
                shouldUpdateForm = true;
                if (shouldUpdateForm) {
                    setServicesData(response.data);
                    setFilteredArray(response.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [api_url]);

    useEffect(() => {
        setForm((prev) => {
            const updatedValues = dropdowns.map((dropdown) => dropdown.value).filter(Boolean);
            return {
                ...prev,
                [keyForm]: updatedValues,
            };
        });
    }, [dropdowns, keyForm]);

    const selectOptions = useMemo(() => {
        if (!servicesData || !i18n.language) {
            return [];
        }
        return servicesData.map(service => ({
            text: i18n.language === "en" ? service.Service_Name_EN : service.Service_Name_ES,
            value: service.Service_GUID
        }));
    }, [i18n.language, servicesData]);


    const handleChangeService = (event) => {
        const selectedValues = Array.isArray(event.value) ? event.value : [event.value];
        setSelectedService(selectedValues);

        const selectedOptions = selectOptions
            .filter((item) => selectedValues.includes(item.value))
            .map((item) => item.text);

        setForm((prev) => ({
            ...prev,
            [keyForm]: selectedOptions,
        }));

    };

    const handleSearchChange = (event, dropdownId) => {
        const value = event.target.value;
        setSearchTerm(value);
        setFilteredArray(filterFunction(servicesData, value));

        setDropdowns((prev) =>
            prev.map((dropdown) =>
                dropdown.id === dropdownId ? { ...dropdown, value: '' } : dropdown
            )
        );
    };

    const handleSelectOptionDropdown = (option, dropdownId) => {
        setDropdowns((prev) =>
            prev.map((dropdown) =>
                dropdown.id === dropdownId ? { ...dropdown, value: option } : dropdown
            )
        );

        setOpenDropdowns((prev) => ({ ...prev, [dropdownId]: false }));
    };

    const addDropdown = () => {
        setDropdowns((prev) => [
            ...prev,
            { id: prev.length + 1, value: '' }
        ]);
    };

    const removeDropdown = (dropdownId, option) => {
        setDropdowns((prev) => prev.filter((dropdown) => dropdown.id !== dropdownId));
        setForm((prev) => ({
            ...prev,
            [keyForm]: prev[keyForm].filter((item) => item !== option),
        }));
    };

    return {
        dropdowns,
        setDropdowns,
        openDropdowns,
        setOpenDropdowns,
        searchTerm,
        filteredArray,
        handleSearchChange,
        handleSelectOptionDropdown,
        addDropdown,
        setForm,
        form,
        servicesData,
        setServicesData,
        inputRef,
        removeDropdown,
        selectOptions,
        selectedService,
        handleChangeService
    };
}

export default useServiceDropdown;
