import { useEffect, useState, useCallback } from 'react';

const useCustomMultiSelect = (initialOptions, reload, setForm) => {
    const [options, setOptions] = useState(initialOptions);
    const [selectedValues, setSelectedValues] = useState([]);
    const [isCustomInputVisible, setIsCustomInputVisible] = useState(false);
    const [customOption, setCustomOption] = useState('');
    const [change, setChange] = useState('');
    const [closeDropdown, setCloseDropdown] = useState('');

    useEffect(() => {
        setOptions(initialOptions)
    }, [initialOptions, reload])

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            skills: selectedValues,
        }));
    }, [selectedValues, change])
    
    const handleChange = (e) => {
        const selected = e.value || [];
        if (selected.includes('otro')) {
            setIsCustomInputVisible(true);
            setCloseDropdown(true)
        } else {
            setSelectedValues(selected);
        }
    };

    const handleCustomOptionSubmit = () => {
        if (customOption) {
            const newOption = { text: customOption, value: customOption.toLowerCase() };
            setOptions([...options.filter(opt => opt.value !== 'otro'), newOption]);
            const updatedSelectedValues = [
                ...selectedValues.filter(value => value !== 'otro'),
                newOption.value
            ];
            setForm((prev) => ({
                ...prev,
                skills: updatedSelectedValues,
            }));
            setSelectedValues(updatedSelectedValues);
            setIsCustomInputVisible(false);
            setCustomOption('');
        }
    };

    return {
        options,
        selectedValues,
        isCustomInputVisible,
        customOption,
        closeDropdown,
        setOptions,
        setCustomOption,
        handleChange,
        handleCustomOptionSubmit
    };
};

export default useCustomMultiSelect;
