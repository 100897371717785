import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonLinkComponent from "../buttonLink/buttonLink";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'; // Ajusta la ruta según tu estructura de carpetas
import DropdownMenu from "../dropdownHeader/dropdownHeader";

const Header = (props) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("")

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentLocation('home')
    }
    if (location.pathname === '/techservices' || location.pathname === '/techservices/ia/' || location.pathname === '/techservices/rpa/' || location.pathname === '/techservices/cloud/' || location.pathname === '/techservices/bi/' || location.pathname === '/techservices/web-app-dev/') {
      setCurrentLocation('services')
    }
  }, [location]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showServicesHeader, setShowServicesHeader] = useState(false);
  const [showTechServicesHeader, setShowTechServicesHeader] = useState(false);
  const [showAboutHeader, setShowAboutHeader] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleHeader = () => {
    setShowServicesHeader(false);
    setShowAboutHeader(false);
    setShowTechServicesHeader(false);
    setShowMenu(!showMenu)
  }
  const handleToggleServices = () => {
    setShowServicesHeader(!showServicesHeader);
    setShowAboutHeader(false)
  }
  const handleToggleTechServices = () => {
    setShowTechServicesHeader(!showTechServicesHeader);
    setShowAboutHeader(false)
  }
  const handleToggleAbout = () => {
    setShowAboutHeader(!showAboutHeader);
    setShowServicesHeader(false)
  }

  const handleRedirect = (route) => {
    navigate(route);
  };

  const elementoRef = useRef(null);
  const elementoRefServices = useRef(null);
  const elementoRefAbout = useRef(null);

  useEffect(() => {
    // Función para manejar los clics en toda la página
    const handleClickOutside = (event) => {
      if (elementoRef.current && !elementoRef.current.contains(event.target) &&
        !event.target.classList.contains('menuHeaderPhone') &&
        !event.target.classList.contains('servicesContainer') &&
        !event.target.classList.contains('secondary-header-button') &&
        !event.target.classList.contains('serviceElement') &&
        !event.target.classList.contains('techServicesContainer') &&
        !event.target.classList.contains('imgHeaderservices')) {
        setShowMenu(false);
        setShowServicesHeader(false)
        setShowAboutHeader(false)
        setShowTechServicesHeader(false)
      }
      if (event.target.classList.contains('header-contact-container_phone')) {
        setShowServicesHeader(false)
        setShowAboutHeader(false)
      }
    };

    // Agregar event listener al montar el componente
    document.addEventListener('click', handleClickOutside);

    // Limpiar event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const solutions = [
    // { name: t('TECH_SOLUTIONS'), link: "/techservices" },
    { name: t('CONSULTING_SOLUTIONS'), link: '/contractors', type: "tech" },
    { name: t('VIRTUAL_MEDICAL_ASSISTANT'), link: '/vma', type: "tech" },
    { name: t('RECRUITMENT_SERVICES'), link: '/recruitment', type: "tech" },
    { name: t('FINANCIALS_SOLUTIONS'), link: '/financials', type: "tech" },
    { name: t('INVESTMENT_SOLUTIONS'), link: '/investment', type: "tech" },
    { name: t('ERP'), link: '/moreservices/erp', type: "product" },
    { name: t('CRM'), link: '/moreservices/crm', type: "product" },
    { name: t('E-Commerce B2B'), link: '/moreservices/ecommerce', type: "product" },
  ];
  const solutionsTech = [
    // { name: t('TECH_SOLUTIONS'), link: "/techservices" },
    { name: t('HOME_SERVICE_IA_TITLE_1'), name2: t('HOME_SERVICE_IA_TITLE_2'), link: '/techservices/ia' },
    { name: t('HOME_SERVICE_RPA_TITLE_1'), name2: t('HOME_SERVICE_RPA_TITLE_2'), link: '/techservices/rpa' },
    { name: t('HOME_SERVICE_CLOUD_TITLE'), link: '/techservices/cloud' },
    { name: t('HOME_SERVICE_BI_TITLE_1'), name2: t('HOME_SERVICE_BI_TITLE_2'), link: '/techservices/bi' },
    { name: t('HOME_SERVICE_WEB_DEV_TITLE_1'), name2: t('HOME_SERVICE_WEB_DEV_TITLE_2'), link: '/techservices/web-app-dev' },
  ];

  const aboutOptions = [
    { name: t('ABOUT_US'), link: '/about-us' },
    { name: t('Blog'), link: '/blog' },
    { name: t('HEADER_EVENTS'), link: '/events' },
  ];
  return (
    <>
      <div className={`blur-background ${showMenu && "active"}`}></div>
      <div className="header-main-container">
        <div className="header-container">
          <div className="header-home-container">
            <img className="logo-5ig" src={`/img/Logo5ig.png`} onClick={() => handleRedirect('/')} alt="Logo_5ig"></img>
          </div>
          <div className="header-contact-container">
            <button className={`secondary-header-button ${currentLocation === 'home' && 'activeWindow'}`} onClick={() => handleRedirect('/')}>{t('HOME_BUTTON')}</button>
            <DropdownMenu
              ref={elementoRefServices}
              title={t('HEADER_SERVICES')}
              options={solutions}
              isActive={showServicesHeader}
              handleToggle={handleToggleServices}
              dropdown={"Services"}
              subMenuOptions={{
                title: t('TECH_SOLUTIONS'),
                link: '/techservices',
                items: solutionsTech
              }}
              subMenuActive={showTechServicesHeader}
              handleSubMenuToggle={handleToggleTechServices}
            />

            <DropdownMenu
              ref={elementoRefAbout}
              title={t('HOME_ABOUT_US')}
              options={aboutOptions}
              isActive={showAboutHeader}
              handleToggle={handleToggleAbout}
              dropdown={"About"}
            />
            <button className="secondary-header-button"><Link to={'/work-with-us'}>{t('GET_HIRED')}</Link></button>
          </div>
          <LanguageSwitcher className="language-switcher" />
          <img className="menuHeaderPhone" src="/img/Menu_svg.svg" alt="" onClick={() => handleToggleHeader()} />
        </div>
      </div>
      <div className={`header-contact-container_phone ${showMenu && "active"}`} ref={elementoRef}>
        <button className="secondary-header-button"><Link to={'/work-with-us'}>{t('GET_HIRED')}</Link></button>
        <DropdownMenu
          title={t('HEADER_SERVICES')}
          options={solutions}
          isActive={showServicesHeader}
          handleToggle={handleToggleServices}
          dropdown={"Services"}
          subMenuOptions={{
            title: t('TECH_SOLUTIONS'),
            link: '/techservices',
            items: solutionsTech
          }}
          subMenuActive={showTechServicesHeader}
          handleSubMenuToggle={handleToggleTechServices}
        />

        <DropdownMenu
          title={t('HOME_ABOUT_US')}
          options={aboutOptions}
          isActive={showAboutHeader}
          handleToggle={handleToggleAbout}
          dropdown={"About"}
        />
      </div>
    </>
  );

};

export default Header;
