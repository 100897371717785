import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './VMA_main.css'
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from "../../components/buttonLink/buttonLink";

const VMAComponent = () => {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route + "/", { replace: true });
    };

    // OWN OBJECTS:

    const servicesData = [
        {
          translateTitle: t('ENHANCE_PRACTICE_TITLE'),
          translateTitle2: t('ENHANCE_PRACTICE_TITLE2'),
          translateText: t('ENHANCE_PRACTICE_DESC'),
          className: 'VMA',
          url: '',
        }
      ];
    const cardBenefitsData = [
        {
            title: t('TIME_MANAGEMENT'),
            text: t('TIME_MANAGEMENT_DESC'),
            img: '/img/hourglass-dollar-depict-time-money 1.jpg'
        },
        {
            title: t('COST_SAVINGS'),
            text: t('COST_SAVINGS_DESC'),
            img: '/img/piggy-bank-s-overflow-coins-illustrates-savings-financial-learning 1.jpg'
        },
        {
            title: t('IMPROVED_ORGANIZATION'),
            text: t('IMPROVED_ORGANIZATION_DESC'),
            img: '/img/young-businesswoman-brainstorming-sticky-note-ideas-night-generated-by-ai 1.jpg'
        },
    ];

    const services = [
        {
            title: t('APPOINTMENT_SCHEDULING'),
            icon: "calendarWhite.svg",
            text: t('APPOINTMENT_SCHEDULING_DESC')
        },
        {
            title: t('ADMINISTRATIVE_SUPPORT'),
            icon: "supportWhite.svg",
            text: t('ADMINISTRATIVE_SUPPORT_DESC')
        },
        {
            title: t('COMMUNICATION_MANAGEMENT'),
            icon: "communicationWhite.svg",
            text: t('COMMUNICATION_MANAGEMENT_DESC')
        }
    ];

    const VmaServices = [
        {
            title: t('BROWSE_SERVICE_CATALOG'),
            route: '/vma/specialist',
            text: t('BROWSE_SERVICE_CATALOG_DESC'),
            buttonText: t('EXPLORE_SERVICES'),
            img: '/img/front-view-nurses-team-hospital 1.jpg'
        },
        {
            title: t('IMPULSE_CAREER'),
            route: '/vma/gethired',
            text: t('IMPULSE_CAREER_DESC'),
            buttonText: t('APPLY_NOW'),
            img: '/img/nurse-portrait-hospital 1.jpg'
        }
    ];

    // OWN COMPONENTS       

    const TargetComponent = ({ img, text, className, title }) => {
        return (
            //Add a roofsContainer as a container of these targets
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p className="textBlack">{text}</p>
                </div>
            </div>
        )
    }

    function CardServices({ img, title, subtitle, text, route }) {
        return (
            <div className='cardServiceContainer'>
                <div className='cardTitleContainer'>
                    <div className='imgCardService'>
                        {img && <img src={img} alt="" />}
                    </div>
                </div>
                <h3 className='titleH3Bold'>{title}</h3>
                <p className='mainTextCardService'>{text}</p>
                <ButtonLinkComponent
                    translateButton={'MORE_INFO_BUTTON'}
                    url={route}
                />
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="VMAMainContainer">
                <MainTitleComponent
                    title={t('VMA_TITLE')}
                    text={t('VMA_TEXT')}
                />
                <section className="services-container" id="services-container">
                    <div className="services-portfolio">
                        {servicesData.map((service, index) => (
                            <BannerComponent
                                key={index}
                                translateTitle={service.translateTitle}
                                translateTitle2={service.translateTitle2}
                                translateText={service.translateText}
                                className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
                                index={index}
                                classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                                translateButton={t('HIRE_VMA')}
                                url={'/vma/specialist/hire'}
                            />
                        ))}
                    </div>
                </section>
                <section className="benefitsSection">
                    <h2 className="titleH2">{t('ABOUT_SERVICE')}</h2>

                    <img src={i18n.language === 'es' ? `/img/Group 17 VMA ES.jpg` : `/img/Group 17New VMA.png`} alt="New VMA" className="imageVMA"/>

                    <h3 className="titleH3">{t('DISCOVER_BENEFITS')}</h3>
                    <div className="roofsContainer">
                        {cardBenefitsData.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.img}
                                text={data.text}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                    <h3 className="titleH3 secondH3">{t('STREAMLINE_TASKS')}</h3>
                </section>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('KEY_SERVICES_OFFERED')}
                        cardsData={services}
                    />
                </section>
                <section className="vmaServicesSection">
                    {VmaServices.map((data, index) => (
                        <CardServices
                            key={index}
                            title={data.title}
                            text={data.text}
                            img={data.img}
                            route={data.route}
                            buttonText={data.buttonText}
                        />
                    ))}
                </section>
            </main>
            <Footer />
        </>
    );
};

export default VMAComponent;
