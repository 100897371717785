
const InputComponent = ({label, value, placeholdel, type, className, name, handleChange, handleBlur}) => {

  return (
    <>
      <div className="inputComponentContainer">
        <p>{label}</p>
        <input
          type={type}
          className={className}
          value={value}
          placeholder={placeholdel}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
};

export default InputComponent;
