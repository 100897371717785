import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './recruitment.css'
import MainTitleComponent from "../../components/mainTitleComponent/mainTitleComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";
import PositionsApplySection from "../../components/positionsApplyComponent/positionsApplyComponent";
import ButtonLinkComponent from "../../components/buttonLink/buttonLink";
const Recruitment = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route + "/", { replace: true });
    };

    //futurism-perspective-digital-nomads-lifestyle 1

    // OWN OBJECTS:

    const servicesData = [
        {
            translateTitle2: t('JOIN_TALENT_POOL_TITLE'),
            className: 'Recruitment',
            url: '/find-top-talent',
        }
    ];

    const cardBenefitsData = [
        {
            icon: "Needs Assessment.svg",
            title: t('NEEDS_ASSESSMENT'),
            text: t('NEEDS_ASSESSMENT_DESC'),
        },
        {
            icon: "Candidate Search.svg",
            title: t('CANDIDATE_SEARCH'),
            text: t('CANDIDATE_SEARCH_DESC'),
        },
        {
            icon: "Screening and Interviewing.svg",
            title: t('SCREENING_AND_INTERVIEWING'),
            text: t('SCREENING_AND_INTERVIEWING_DESC'),
        },
        {
            icon: "CandidatePresentation.svg",
            title: t('CANDIDATE_PRESENTATION'),
            text: t('CANDIDATE_PRESENTATION_DESC'),
        },
        {
            icon: "FinalSelection.svg",
            title: t('FINAL_SELECTION_AND_ONBOARDING'),
            text: t('FINAL_SELECTION_AND_ONBOARDING_DESC'),
        },
    ];


    const featuresData = [
        {
            title: t('EXPERT_RECRUITERS'),
            text: t('EXPERT_RECRUITERS_DESC'),
            imgSrc: '/img/medium-shot-people-working-as-real-estate-agents 1.png',
            className: 'competitive-compensation'
        },
        {
            title: t('PERSONALIZED_APPROACH'),
            text: t('PERSONALIZED_APPROACH_DESC'),
            imgSrc: '/img/tailor solutions 1.png',
            className: 'support-training'
        },
        {
            title: t('PROVEN_TRACK_RECORD'),
            text: t('PROVEN_TRACK_RECORD_DESC'),
            imgSrc: '/img/empire-state-building-day 1.png',
            className: 'global-opportunities'
        }
    ];


    const positionsApply = [
        {
            name: 'UX/UI Designer',
            img: 'openart-image_vGkkH9SM_1715791353615_raw 1.webp'
        },
        {
            name: 'Cybersecurity Expert',
            img: 'openart-image_UhNKA27w_1715791682693_raw 1.webp'
        },
        {
            name: 'Full Stack Developer',
            img: 'young-adult-programmer-typing-computer-office-generated-by-ai 1.webp'
        },
        {
            name: 'Software Developer',
            img: 'openart-image_QMcNbC6M_1715792822145_raw 1.webp'
        },
        {
            name: 'Database Expert',
            img: 'openart-image_YyQnCnLS_1715793412802_raw 1.webp'
        }
    ];

    // OWN COMPONENTS:
    function TargetHome({ img, text, title }) {
        return (
            <div className='targetHomeContainer'>
                <h2>{title}</h2>
                <p className="mainText">{text}</p>
                <img src={img} alt="" />
            </div>
        )
    }

    const ImageGridSection = ({ title, imagesData }) => {
        return (
            <section className="imageGridSection">
                <h2 className="title">{title}</h2>
                <div className="imageGridContainer">
                    {imagesData.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}
                            alt={image.alt}
                            className={`image ${image.className}`}
                        />
                    ))}
                </div>
            </section>
        );
    };


    function PositionsApplyContainer({ img, text }) {
        return (
            <div className="positionsApplyContainer">
                <img src={`/img/${img}`} alt="" className="positionsApplyImgBackground" />
                <div className="positionsApplyBackground">

                    <p className="positionsApplyText">{text}</p>
                </div>
            </div>
        )
    }
    const BenefitsCardsComponent = ({ title, cardsData }) => {
        return (
            //Add a roofsContainer as a container of these targets
            <section className="benefitsCardsComponentSection">
                <h2 className="title">{title}</h2>
                <div className="cardsContainer">
                    {cardsData.map((data, index) => (
                        <div className="benfitContainer">
                            <div className="benefitTitle">
                                <div className='imgCardBenefit'>
                                    {data.icon && <img src={"/img/" + data.icon} alt="" />}
                                </div>
                                {data.title && <p className="titleText">{data.title}</p>}
                            </div>
                            <p className={`${data.title && 'secondaryText'}`}>{data.text}</p>
                        </div>
                    ))}
                </div>
            </section>
        )
    }

    return (
        <>
            <Header />
            <main className="RecruitmentMainContainer">
                <MainTitleComponent
                    title={t('RECRUITMENT_TITLE')}
                    className="main-title-component"
                />


                <section className="services-container" id="services-container">
                    <div className="services-portfolio">
                        {servicesData.map((service, index) => (
                            <BannerComponent
                            key={index}
                            translateText={service.translateTitle2}
                            className={`${service.className} second`}
                            index={index}
                            classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                            translateButton={t('FIND_YOUR_NEXT_TALENT')}
                            url={service.url}
                            />
                        ))}
                    </div>
                </section>

                <p className="mainTextTitle">{t('RECRUITMENT_TEXT')}</p>

                <h2 className="sectionTitle">{t('WE_OFFER')}</h2>
                
                <section className="targetsSection">
                    <div className="targetsContainer">
                        {featuresData.map((feature, index) => (
                            <TargetHome
                                key={index}
                                title={feature.title}
                                img={feature.imgSrc}
                                text={feature.text}
                                className={feature.className}
                            />
                        ))}
                    </div>
                </section>

                <section className="additionalCardsSection">
                    <div className="additionalCardsContainer">
                        <div className="additionalCard">
                            <h3 className="cardTitle">Access to elite candidates</h3>
                        </div>
                        <div className="additionalCard">
                            <h3 className="cardTitle">Industry-specific expertise</h3>
                        </div>
                        <div className="additionalCard">
                            <h3 className="cardTitle">Confidential and efficient process</h3>
                        </div>
                    </div>
                </section>


                <ImageGridSection
                    title={t('OUR_PARTNERS')}
                    imagesData={[
                        { src: "/img/image Microsoft.png", alt: "Microsoft", className: "imageMicrosoft" },
                        { src: "/img/image Udemy.png", alt: "Udemy", className: "imageUdemy" },
                        { src: "/img/image Coursera.png", alt: "Coursera", className: "imageCoursera" },
                        { src: "/img/image Google.png", alt: "Google", className: "imageGoogle" },
                        { src: "/img/image IBM.png", alt: "IBM", className: "imageIBM" },
                        { src: "/img/image Platzi.png", alt: "Platzi", className: "imagePlatzi" }
                    ]}
                />


                <BenefitsCardsComponent
                    title={t('STEP_BY_STEP')}
                    cardsData={cardBenefitsData}
                />

                <PositionsApplySection
                    title={t('FEATURED_JOBS')}
                />
                {//<h3 className="textH3">{t('RECRUITMENT_CTA')}</h3>
                }
                <ButtonLinkComponent
                    url='/find-top-talent'
                    translateButton={t('FIND_YOUR_NEXT_TALENT')}
                />
            </main>
            <Footer />
        </>
    );
};

export default Recruitment;
