import React from "react";
import { useNavigate } from "react-router";
import "./hire.css";
import { useTranslation } from 'react-i18next';
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from "../../../components/buttonLink/buttonLink";

const HireVMAComponent = () => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route, { replace: true });
    };

    const { t } = useTranslation();

    const cardBenefitsData = [
        {
            text: t('CAREER_GROWTH_DESC'),
            img: '/img/nurse-portrait-hospital (1) 1.jpg'
        },
        {
            text: t('VIRTUAL_HEALTHCARE_OPPORTUNITIES_DESC'),
            img: '/img/openart-image_DTfxBDRP_1716237432143_raw 1.jpg'
        },
        {
            text: t('REMOTE_HEALTHCARE_OPPORTUNITY_DESC'),
            img: '/img/flag-flies-front-city-skyline 1.jpg'
        },
    ];

    const services = [
        {
            text: t('SCHEDULE_MANAGE_APPOINTMENTS'),
            icon: "calendarWhite.svg",
        },
        {
            text: t('HANDLE_ADMIN_TASKS'),
            icon: "CheckCalendarWhite.svg",
        },
        {
            text: t('COORDINATE_WITH_PROVIDERS'),
            icon: "healthTarget.svg",
        }
    ];


    const TargetComponent = ({ img, text, className, title }) => {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={img} alt="" />
                <div className="mainText">
                    <h1>{title}</h1>
                    <p>{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="contractingMaincontainer">
                <div className="bannerhireVMA"></div>
                <MainTitleComponent
                    title={t('UNLOCK_REMOTE_HEALTHCARE')}
                    text={t('APPLY_NOW_VMA')}
                    buttonText={t('APPLY_NOW')}
                    url={'/vma/gethired/hire'}
                />
                <section className="benefitsSection ApplySection">
                    <h1 className="titleH2">{t('ABOUT_POSITION')}</h1>
                    <div className="roofsContainer">
                        {cardBenefitsData.map((data, index) => (
                            <TargetComponent
                                key={index}
                                img={data.img}
                                text={data.text}
                                title={data.title}
                                className={index % 2 === 1 ? 'second' : ''}
                            />
                        ))}
                    </div>
                </section>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('YOUR_DAILY_TASKS')}
                        cardsData={services}
                    />
                </section>
                <ButtonLinkComponent
                    translateButton={t('APPLY_NOW')}
                    url={'/vma/gethired/hire'}
                />
            </main>
            <Footer />
        </>
    );
};

export default HireVMAComponent;
