import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import './contractors.css'
import TargetComponent from "../../components/targetsComponent/targetComponent";
import BannerComponent from "../../components/bannerComponent/bannerComponent";
import ButtonLinkComponent from "../../components/buttonLink/buttonLink";
import BenefitsCardsComponent from "../../components/benefitsCardsComponent/benefitsCardsComponent";
const Contractors = () => {
    const { t } = useTranslation();

    const servicesData = [
        {
            type: 'consulting',
            translateTitle: t('CONTRACTING_MAIN_COMPREHENSIVE_CONSULTING'),
            className: 'consulting',
        },
        // Añade más objetos según sea necesario
    ];

    const featuresData = [
        {
            title: t('CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE'),
            text: t('CONTRACTING_MAIN_EXPERIENCE_AND_EXPERTISE_TEXT'),
            imgSrc: '/img/openart-image_2AkErhgH_1715873940524_raw 2.jpg',
            className: 'competitive-compensation'
        },
        {
            title: t('CONTRACTING_MAIN_STRATEGIC_FOCUS'),
            text: t('CONTRACTING_MAIN_STRATEGIC_FOCUS_TEXT'),
            imgSrc: '/img/tailor solutions 1.jpg',
            className: 'support-training'
        },
        {
            title: t('CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS'),
            text: t('CONTRACTING_MAIN_CUSTOMIZED_SOLUTIONS_TEXT'),
            imgSrc: '/img/medium-shot-people-working-as-real-estate-agents 3.jpg',
            className: 'support-training'
        },
        {
            title: t('CONTRACTING_MAIN_PROCESS_OPTIMIZATION'),
            text: t('CONTRACTING_MAIN_PROCESS_OPTIMIZATION_TEXT'),
            imgSrc: '/img/tailor solutions 2.jpg',
            className: 'support-training'
        },
    ];

    function TargetHome({ img, text, title }) {
        return (
            <div className='targetHomeContainer'>
                <h2 className="titleH3Bold">{title}</h2>
                <p className="mainText">{text}</p>
                <img src={img} alt="" />
            </div>
        )
    }

    const cardBenefitsData = [
        {
            title: t('CONTRACTING_MAIN_ADMINISTRATIVE_AND_OPERATIONAL_MANAGEMENT'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_BPO')}</li>
                    <li>{t('CONTRACTING_MAIN_RECORDS_MANAGEMENT')}</li>
                    <li>{t('CONTRACTING_MAIN_AUTOMATION_OF_REPETITIVE_PROCESSES')}</li>
                </ul>
            ),
            icon: 'administrative_management.svg'
        },
        {
            title: t('CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_DESIGN_AND_IMPLEMENTATION_OF_HEALTH_AND_SAFETY_PROGRAMS')}</li>
                    <li>{t('CONTRACTING_MAIN_RISK_ASSESSMENT_AND_TRAINING')}</li>
                </ul>
            ),
            icon: 'occupational_health.svg'
        },
        {
            title: t('CONTRACTING_MAIN_IT_SECURITY'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_CYBERSECURITY_CONSULTING')}</li>
                    <li>{t('CONTRACTING_MAIN_MONITORING_AND_INCIDENT_RESPONSE')}</li>
                </ul>
            ),
            icon: 'it_security.svg'
        },
        {
            title: t('CONTRACTING_MAIN_EQUIPMENT_MAINTENANCE_AND_MANAGEMENT'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_IT_INFRASTRUCTURE_MANAGEMENT_AND_MAINTENANCE')}</li>
                    <li>{t('CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_EQUIPMENT_UPGRADES')}</li>
                </ul>
            ),
            icon: 'equipment_maintenance.svg'
        },
        {
            title: t('CONTRACTING_MAIN_PROCESS_AUDITING'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_COMPREHENSIVE_PROCESS_REVIEW')}</li>
                    <li>{t('CONTRACTING_MAIN_IMPLEMENTATION_OF_IMPROVEMENTS')}</li>
                </ul>
            ),
            icon: 'process_auditing.svg'
        },
        {
            title: t('CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_MANAGEMENT'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_ACCOUNTING_AND_FINANCIAL_MANAGEMENT')}</li>
                    <li>{t('CONTRACTING_MAIN_PAYROLL_AND_HUMAN_RESOURCES_MANAGEMENT')}</li>
                </ul>
            ),
            icon: 'financial_management.svg'
        },
        {
            title: t('CONTRACTING_MAIN_REGULATORY_COMPLIANCE'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORY')}</li>
                    <li>{t('CONTRACTING_MAIN_AUDIT_AND_COMPLIANCE')}</li>
                </ul>
            ),
            icon: 'regulatory_compliance.svg'
        },
        {
            title: t('CONTRACTING_MAIN_DIGITAL_TRANSFORMATION_AND_OPTIMIZATION'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_ERP_IMPLEMENTATION')}</li>
                    <li>{t('CONTRACTING_MAIN_PROCESS_AUTOMATION_AND_CYBERSECURITY')}</li>
                </ul>
            ),
            icon: 'digital_transformation.svg'
        },
        {
            title: t('CONTRACTING_MAIN_STRATEGIC_CONSULTING_AND_BUSINESS_GROWTH'),
            text: (
                <ul>
                    <li>{t('CONTRACTING_MAIN_STRATEGIC_PLANNING')}</li>
                    <li>{t('CONTRACTING_MAIN_MARKET_AND_COMPETITOR_ANALYSIS')}</li>
                </ul>
            ),
            icon: 'strategic_consulting.svg'
        }
    ];

    const specialistsData = [
        {
            title: t('CONTRACTING_MAIN_STRATEGIC_CONSULTANTS'),
            position: 1
        },
        {
            title: t('CONTRACTING_MAIN_RECORDS_MANAGEMENT_SPECIALISTS'),
            position: 1
        },
        {
            title: t('CONTRACTING_MAIN_OCCUPATIONAL_HEALTH_AND_SAFETY_SPECIALISTS'),
            position: 1
        },
        {
            title: t('CONTRACTING_MAIN_CYBERSECURITY_SPECIALISTS'),
            position: 1
        },
        {
            title: t('CONTRACTING_MAIN_TECHNICAL_SUPPORT_AND_MAINTENANCE_TECHNICIANS'),
            position: 1
        },
        {
            title: t('CONTRACTING_MAIN_PROCESS_AUDITORS'),
            position: 2
        },
        {
            title: t('CONTRACTING_MAIN_FINANCIAL_AND_ACCOUNTING_ANALYSTS'),
            position: 2
        },
        {
            title: t('CONTRACTING_MAIN_ADMINISTRATIVE_MANAGERS'),
            position: 2
        },
        {
            title: t('CONTRACTING_MAIN_LEGAL_AND_TAX_ADVISORS'),
            position: 2
        },
        {
            title: t('CONTRACTING_MAIN_HUMAN_RESOURCES_CONSULTANTS'),
            position: 2
        }
    ];

    function CardServices({ img, title, text, className }) {
        return (
            <div className={`cardServiceContainer ${className}`}>
                <div className='cardMainContainer'>
                    <div className='cardTitleContainer'>
                        <div className='imgCardService'>
                            {img && <img src={`/img/${img}`} alt="" />}
                        </div>
                        <h2 className='h2'>{title}</h2>
                    </div>
                    <p className='mainTextCardService'>{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="contractorsMainContainer">
                <h1 className="title">{t('CONTRACTING_MAIN_CONSULTING')}</h1>
                <div className="services-container" id="services-container">
                    <div className="services-portfolio">
                        {servicesData.map((service, index) => (
                            <BannerComponent
                                key={index}
                                translateTitle={service.translateTitle}
                                className={`${service.className} `}
                                classNameSide={`l`}
                                url={service.url}
                            />
                        ))}
                    </div>
                </div>
                <p className="textMainContractor">{t('CONTRACTING_MAIN_AT_5IG_SOLUTIONS')}</p>
                <section className="contractorsData targetsSection">
                    <h2 className="titleH2">{t('CONTRACTING_MAIN_WHY_CHOOSE_US')}</h2>
                    <div className="targetsContainer">
                        {featuresData.map((feature, index) => (
                            <TargetHome
                                key={index}
                                title={feature.title}
                                img={feature.imgSrc}
                                text={feature.text}
                                className={feature.className}
                            />
                        ))}
                    </div>
                </section>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('CONTRACTING_MAIN_OUR_CONSULTING_SERVICES')}
                        cardsData={cardBenefitsData}
                    />
                </section>
                <section className="professionalsSection">
                    <h2 className="titleH2">{t('CONTRACTING_MAIN_OUR_TEAM_OF_PROFESSIONALS')}</h2>
                    <div className="professionalsImageContractors"></div>
                    <div className="professionalsText">
                        <p className="textH4">{t('CONTRACTING_MAIN_AT_5IG_SOLUTIONS_TEAM_DESCRIPTION')}</p>
                        <p className="textH4">{t('CONTRACTING_MAIN_TEAM_INCLUDES')}</p>
                    </div>
                    <div className="teamList">
                        <ul>
                            {specialistsData.map((specialist) => {
                                if (specialist.position === 1) {
                                    return (
                                        <li className="titleH4Bold" key={specialist.title}>{specialist.title}</li>
                                    )
                                }
                            })}
                        </ul>
                        <ul>
                            {specialistsData.map((specialist) => {
                                if (specialist.position === 2) {
                                    return (
                                        <li className="titleH4Bold" key={specialist.title}>{specialist.title}</li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                </section>
                <section className="servicesSection">
                    <h1 className='title benefits'>{t('CONTRACTING_MAIN_FOCUS_ON_GROWTH')}</h1>
                    <div className="ServicesContainer">
                        <CardServices
                            title={t('CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS')}
                            text={t('CONTRACTING_MAIN_TIME_AND_RESOURCE_SAVINGS_DESCRIPTION')}
                            route={'/techservices'}
                            img={'time_resource.svg'}
                            className={'tech'}
                        />
                        <CardServices
                            title={t('CONTRACTING_MAIN_INCREASED_EFFICIENCY')}
                            text={t('CONTRACTING_MAIN_INCREASED_EFFICIENCY_DESCRIPTION')}
                            img={'increased_efficiency.svg'}
                            route={'/vma'}
                        />
                        <CardServices
                            title={t('CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND')}
                            text={t('CONTRACTING_MAIN_LEGAL_COMPLIANCE_AND_PEACE_OF_MIND_DESCRIPTION')}
                            img={'legal_peace.svg'}
                            route={'/contractors'}
                        />
                        <CardServices
                            title={t('CONTRACTING_MAIN_FOCUS_ON_GROWTH')}
                            text={t('CONTRACTING_MAIN_FOCUS_ON_GROWTH_DESCRIPTION')}
                            img={'focus_growth.svg'}
                            route={'/recruitment'}
                        />
                    </div>
                </section>
                <ButtonLinkComponent
                    translateButton={'BOOK_APPOINTMENT_BUTTON'}
                    url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                    blank={true}
                    className={'buttonContactContractors'}
                />
            </main>

            <Footer />
        </>
    );
};

export default Contractors;
