export const info = [
  //IA
  {
    img_source: "/img/DreamShaper_v7_dashboards_and_kpi_4 1.jpg",
    title: "IA_SERVICE_FIRST_TITLE",
    info: "IA_SERVICE_FIRST_DESCR",
    technologies: {
      img: 'logo_python_1.svg',
      img1: 'Power_BI_1.svg',
      img2: 'Azure_logo_1.svg',
      img3: 'AI_builder_logo_1.svg',
    }
  },
  {
    img_source: "/img/DreamShaper_v7_holographic_assistant_5 2.jpg",
    title: "IA_SERVICE_SECOND_TITLE",
    info: "IA_SERVICE_SECOND_DESCR",
    technologies: {
      img: 'PVA_logo_1.svg',
      img1: 'Azure_logo_1.svg',
    }
  },
  {
    img_source: "/img/DreamShaper_v7_virtual_assistant_that_help_the_companies_to_in_3 1.jpg",
    title: "IA_SERVICE_THIRD_TITLE",
    info: "IA_SERVICE_THIRD_DESCR",
    technologies: {
      img: 'Azure_logo_1.svg',
    }
  },
  //RPA
  {
    img_source: "/img/DreamShaper_v7_White_robot_with_4_arms_coding_on_different_scr_1 1.jpg",
    title: "RPA_SERVICE_FIRST_TITLE",
    info: "RPA_SERVICE_FIRST_DESCR",
    technologies: {
      img: 'automation-anywhere-logo-corporate-two-line-lg.svg',
      img2: 'UiPath-partner-full.svg',
      img3: 'qgbJ08Y16II1jB0TKK7i-350x350.svg',
    }
  },
  {
    img_source: "/img/DreamShaper_v7_white_robot_giving_advices_to_a_business_man_on_3 1.jpg",
    title: "RPA_SERVICE_SECOND_TITLE",
    info: "RPA_SERVICE_SECOND_DESCR",
    technologies: {
      img: 'automation-anywhere-logo-corporate-two-line-lg.svg',
      img1: 'Power_automate_desktop_1.svg',

    }
  },
  //CLOUD
  {
    img_source: "/img/DreamShaper_v7_cloud_server_helping_a_growing_company_1 1.jpg",
    title: "CLOUD_SERVICE_FIRST_TITLE",
    info: "CLOUD_SERVICE_FIRST_DESCR",
    technologies: {
      img: 'AWS_icon.svg',
      img1: 'Microsoft_Office_SharePoint_(2019–present).svg 1.svg',
      img2: 'google-cloud-icon-1024x823-wiwlyizc.svg',
      img3: 'Azure_logo_1.svg',
    }
  },
  {
    img_source: "/img/DreamShaper_v7_cloud_server_helping_a_growing_company_0 1.jpg",
    title: "CLOUD_SERVICE_SECOND_TITLE",
    info: "CLOUD_SERVICE_SECOND_DESCR",
    technologies: {
      img: 'google-cloud-icon-1024x823-wiwlyizc.svg',
      img1: 'Azure_logo_1.svg',
    }
  },
  //BI
  {
    img_source: "/img/DreamShaper_v7_Holographic_lady_showing_companies_dashboards_a_3 2.jpg",
    title: "BI_SERVICE_FIRST_TITLE",
    info: "BI_SERVICE_FIRST_DESCR",
    technologies: {
      img: 'Power_BI_Icon_1.svg',
    }
  },

  {
    img_source: "/img/DreamShaper_v7_Holographic_lady_showing_companies_dashboards_a_1 1.jpg",
    title: "BI_SERVICE_SECOND_TITLE",
    info: "BI_SERVICE_SECOND_DESCR",
    technologies: {
      img: 'Power_BI_Icon_1.svg',
    }
  },
  //WEB-APP-DEV
  {
    img_source: "/img/DreamShaper_v7_huge_app_development_made_by_a_big_company_show_3 1.jpg",
    title: "WEB_DEV_SERVICE_FIRST_TITLE",
    info: "WEB_DEV_SERVICE_FIRST_DESCR",
    technologies: {
      img: 'react_1.svg',
      img1: 'power_apps_logo_1.svg',
    }
  },
  {
    img_source: "/img/DreamShaper_v7_huge_app_development_made_by_a_big_company_show_2 1.jpg",
    title: "WEB_DEV_SERVICE_SECOND_TITLE",
    info: "WEB_DEV_SERVICE_SECOND_DESCR",
    technologies: {
      img: 'power_apps_logo_1.svg',
    }
  },
];

export const info2 = [
  {
    id: "ia",
    contentServices: [
      {
        text1: ["HOME_SERVICE_RPA_TITLE_1", "HOME_SERVICE_RPA_TITLE_2"],
        img1: "more-services-robot",
        link1: "/techservices/rpa",
        text2: ["HOME_SERVICE_CLOUD_TITLE"],
        img2: "more-services-cloud",
        link2: "/techservices/cloud",
        text3: ["HOME_SERVICE_BI_TITLE_1", "HOME_SERVICE_BI_TITLE_2"],
        img3: "more-services-bi",
        link3: "/techservices/bi",
        text4: ["HOME_SERVICE_WEB_DEV_TITLE_1", "HOME_SERVICE_WEB_DEV_TITLE_2"],
        img4: "web_development",
        link4: "/techservices/web-app-dev"
      }
    ]
  },
  {
    id: "rpa",
    contentServices: [
      {
        text1: ["HOME_SERVICE_IA_TITLE_1", "HOME_SERVICE_IA_TITLE_2"],
        img1: "more-services-dev",
        link1: "/techservices/ia",
        text2: ["HOME_SERVICE_CLOUD_TITLE"],
        img2: "more-services-cloud",
        link2: "/techservices/cloud",
        text3: ["HOME_SERVICE_BI_TITLE_1", "HOME_SERVICE_BI_TITLE_2"],
        img3: "more-services-bi",
        link3: "/techservices/bi",
        text4: ["HOME_SERVICE_WEB_DEV_TITLE_1", "HOME_SERVICE_WEB_DEV_TITLE_2"],
        img4: "web_development",
        link4: "/techservices/web-app-dev"
      }
    ]
  },
  {
    id: "cloud",
    contentServices: [
      {
        text1: ["HOME_SERVICE_IA_TITLE_1", "HOME_SERVICE_IA_TITLE_2"],
        img1: "more-services-dev",
        link1: "/techservices/ia",
        text2: ["HOME_SERVICE_RPA_TITLE_1", "HOME_SERVICE_RPA_TITLE_2"],
        img2: "more-services-robot",
        link2: "/techservices/rpa",
        text3: ["HOME_SERVICE_BI_TITLE_1", "HOME_SERVICE_BI_TITLE_2"],
        img3: "more-services-bi",
        link3: "/techservices/bi",
        text4: ["HOME_SERVICE_WEB_DEV_TITLE_1", "HOME_SERVICE_WEB_DEV_TITLE_2"],
        img4: "web_development",
        link4: "/techservices/web-app-dev"
      }
    ]
  },
  {
    id: "bi",
    contentServices: [
      {
        text1: ["HOME_SERVICE_IA_TITLE_1", "HOME_SERVICE_IA_TITLE_2"],
        img1: "more-services-dev",
        link1: "/techservices/ia",
        text2: ["HOME_SERVICE_RPA_TITLE_1", "HOME_SERVICE_RPA_TITLE_2"],
        img2: "more-services-robot",
        link2: "/techservices/rpa",
        text3: ["HOME_SERVICE_CLOUD_TITLE"],
        img3: "more-services-cloud",
        link3: "/techservices/cloud",
        text4: ["HOME_SERVICE_WEB_DEV_TITLE_1", "HOME_SERVICE_WEB_DEV_TITLE_2"],
        img4: "web_development",
        link4: "/techservices/web-app-dev"
      }
    ]
  },
  {
    id: "web-app-dev",
    contentServices: [
      {
        text1: ["HOME_SERVICE_IA_TITLE_1", "HOME_SERVICE_IA_TITLE_2"],
        img1: "more-services-dev",
        link1: "/techservices/ia",
        text2: ["HOME_SERVICE_RPA_TITLE_1", "HOME_SERVICE_RPA_TITLE_2"],
        img2: "more-services-robot",
        link2: "/techservices/rpa",
        text3: ["HOME_SERVICE_CLOUD_TITLE"],
        img3: "more-services-cloud",
        link3: "/techservices/cloud",
        text4: ["HOME_SERVICE_BI_TITLE_1", "HOME_SERVICE_BI_TITLE_2"],
        img4: "more-services-bi",
        link4: "/techservices/bi"
      }
    ]
  }
]