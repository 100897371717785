import React from "react";
import Header from "../../header/header.jsx";
import Footer from "../../footer/footer.jsx";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./services_content.css";
import { info } from "../../../utilities/dataservices.js";
import { info2 } from "../../../utilities/dataservices.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ButtonLinkComponent from "../../buttonLink/buttonLink.jsx";

const Services_content = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirect = (route) => {
    navigate(route);
  };

  const DynamicImage = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState(null);


    // useEffect(() => {
    //   import(`${src}`)
    //     .then((image) => {
    //       setImageSrc(image.default);
    //     })
    //     .catch((error) => {
    //       console.error("Error al cargar la imagen:", error);
    //     });
    // }, [src]);
    return <img className="image_service" src={src} alt={alt} />;
  };

  const params = useParams();
  const [service, setService] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    setService(params.service);
  }, [params.service]);

  const getTitleByService = () => {
    switch (service) {
      case "ia":
        return (<>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_IA_TITLE_1')}
          </h1>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_IA_TITLE_2')}
          </h1>
        </>
        );
      case "rpa":
        return (<>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_RPA_TITLE_1')}
          </h1>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_RPA_TITLE_2')}
          </h1>
        </>
        );
      case "cloud":
        return (<>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_CLOUD_TITLE')}
          </h1>
        </>
        );
      case "bi":
        return (<>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_BI_TITLE_1')} {t('HOME_SERVICE_BI_TITLE_2')}
          </h1>
        </>
        );;
      case "web-app-dev":
        return (<>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_WEB_DEV_TITLE_1')}
          </h1>
          <h1 style={{ margin: "0" }}>
            {t('HOME_SERVICE_WEB_DEV_TITLE_2')}
          </h1>
        </>
        );
      default:
        return "";
    }
  };

  const miEstilo = {
    width: "",
  };

  const sectionTitle = getTitleByService();

  let content, route, startIdx, endIdx;

  if (service === "ia") {
    startIdx = 0;
    endIdx = startIdx + 3;
    route = "/img/ia.webp";
  } else if (service === "rpa") {
    startIdx = 3;
    endIdx = startIdx + 2;
    route = "/img/rpa.webp";
  } else if (service === "cloud") {
    startIdx = 5;
    endIdx = startIdx + 2;
    route = "/img/cloud.webp";
  } else if (service === "bi") {
    startIdx = 7;
    endIdx = startIdx + 2;
    route = "/img/bi.webp";
  } else if (service === "web-app-dev") {
    startIdx = 9;
    endIdx = startIdx + 2;
    route = "/img/wd&ad.webp";
  }

  if (startIdx !== undefined && endIdx !== undefined) {
    content = (
      <div className="content-services">
        <div className={`img-banner ${service}`}>
          {/*<div className="arrow-container">
            <img
              className="back-link"
              src={"/img/arrow_contract.webp"}
              onClick={() => handleRedirect("/")}
              alt="arrow_back"
            ></img>
          </div>*/}
          <div className="title_button_container">
            <div>{sectionTitle}</div>
            <ButtonLinkComponent
              translateButton={'HEADER_CONTRACT'}
              url={"/techservices/adquire"}
              className={service}
            />
          </div>
        </div>
        <div className="parent_container">
          {info.slice(startIdx, endIdx).map((item, index) => (
            <div key={index} className="container_content">
              {index % 2 === 0 ? (
                <>
                  <DynamicImage src={item.img_source} alt="Imagen" />
                  <div className="desc_service">
                    <h2>{t(item.title)}</h2>
                    <p>{t(item.info)}</p>
                    {item.technologies && (
                      <>
                        <h2 className="secondTitle">{t("Tools_That_We_Use")}</h2>
                        <div className="toolsContainter">
                          {item.technologies && Object.values(item.technologies).map((src, idx) => (
                            <img key={idx} src={`/img/${src}`} alt="" />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="desc_service">
                    <h2>{t(item.title)}</h2>
                    <p>{t(item.info)}</p>
                    {item.technologies && (
                      <>
                        <h2 className="secondTitle">{t("Tools_That_We_Use")}</h2>
                        <div className="toolsContainter">
                          {item.technologies && Object.values(item.technologies).map((src, idx) => (
                            <img key={idx} src={`/img/${src}`} alt="" />
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <DynamicImage src={item.img_source} alt="Imagen" />
                </>
              )}
            </div>
          ))}


          {/* <button className="button" onClick={() => handleRedirect("/techservices/adquire")}>{t('HEADER_CONTRACT')}</button> */}
          <ButtonLinkComponent
            translateButton={'HEADER_CONTRACT'}
            url={"/techservices/adquire"}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div className="content-services">
        <div className={`img-banner ${service}`}>
          <div className="arrow-container">
            <img
              className="back-link"
              src={"/img/arrow_contract.webp"}
              onClick={() => handleRedirect("/")}
              alt="arrow_back"
            ></img>
            <div className="superposition"></div>
          </div>
          <h1>{sectionTitle}</h1>
          <button>CONTRATA CON NOSOTROS </button>
          <h1 style={service === "ia" ? miEstilo : {}}>{sectionTitle}</h1>
          <button onClick={() => handleRedirect("/contractus")}>
            CONTRATA CON NOSOTROS
          </button>
        </div>
        <div className="parent_container">
          {info.slice(startIdx, endIdx).map((item, index) => (
            <div key={index} className={`container_content`}>
              {index % 2 === 0 ? (
                <>
                  <DynamicImage src={item.img_source} alt="Imagen" />
                  <div className="desc_service">
                    <h2>{t(item.title)}</h2>
                    <p>{t(item.info)}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="desc_service">
                    <h2>{t(item.title)}</h2>
                    <p>{t(item.info)}</p>
                  </div>
                  <DynamicImage src={item.img_source} alt="Imagen" />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  const findServices = (idService) => {
    const foundService = info2.find(service => service.id === idService);
    return foundService ? foundService.contentServices : null;
  };
  const services = findServices(params.service);


  const getConcatenatedText = (keys) => {
    return keys.map(key => t(key)).join(" ");
  };

  return (
    <div>
      <Header></Header>
      {content}
      <div className="more-services-background">
        <div className="more-services-container">
          <div className="more-services-title"><h1>{t('HOME_SERVICES_TITLE')}</h1></div>
          <div className="more-services-content-grid">
            <div className="more-services-content-grid-item">
              <div className="more-services-content-grid-item-content" >
                <div className="moreServicesImgContainer">
                  <img src={`/img/${services[0].img1}.svg`} alt="" />
                </div>
                <h2>{getConcatenatedText(services[0].text1)}</h2>
              </div>
              <ButtonLinkComponent
                translateButton={'HOME_SERVICES_BUTTON'}
                url={services[0].link1}
                className={'button more-services-content-grid-item-content-button'}
              />
            </div>
            <div className="more-services-content-grid-item">
              <div className="more-services-content-grid-item-content">
                <div className="moreServicesImgContainer">
                  <img src={`/img/${services[0].img2}.svg`} alt="" />
                </div>
                <h2>{getConcatenatedText(services[0].text2)}</h2>
              </div>
              <ButtonLinkComponent
                translateButton={'HOME_SERVICES_BUTTON'}
                url={services[0].link2}
                className={'button more-services-content-grid-item-content-button'}
              />
            </div>
            <div className="more-services-content-grid-item">
              <div className="more-services-content-grid-item-content">
                <div className="moreServicesImgContainer">
                  <img src={`/img/${services[0].img3}.svg`} alt="" />
                </div>
                <h2>{getConcatenatedText(services[0].text3)}</h2>
              </div>
              <ButtonLinkComponent
                translateButton={'HOME_SERVICES_BUTTON'}
                url={services[0].link3}
                className={'button more-services-content-grid-item-content-button'}
              />
            </div>
            <div className="more-services-content-grid-item">
              <div className="more-services-content-grid-item-content">
                <div className="moreServicesImgContainer">
                  <img src={`/img/${services[0].img4}.svg`} alt="" />
                </div>
                <h2> {getConcatenatedText(services[0].text4)}</h2>
              </div>
              <ButtonLinkComponent
                translateButton={'HOME_SERVICES_BUTTON'}
                url={services[0].link4}
                className={'button more-services-content-grid-item-content-button'}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Services_content;
