import React from "react";
import './mainTitleComponent.css'
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import ButtonLinkComponent from "../buttonLink/buttonLink";

const MainTitleComponent = ({ title, text, buttonText, url, blank, isSmallTitle }) => {
    const navigate = useNavigate();
    const handleRedirect = (route) => {
        navigate(route + "/", { replace: true });
    };

    const { t } = useTranslation();
    return (
        <section className="mainTitleComponentSection">
            {title && <h1 className={`title ${isSmallTitle ? 'small-title' : ''}`}>{title}</h1>}
            {text && <p className="mainTextTitle">{text}</p>}
            {buttonText && <ButtonLinkComponent
                translateButton={buttonText}
                url={url}
                blank={blank}
            />}
        </section>
    )
}

export default MainTitleComponent;
