import React from 'react';
import { useTranslation } from 'react-i18next';
import './positionsApplyComponent.css'

const PositionsApplySection = ({title}) => {
    const { t } = useTranslation();

    const positionsApply = [
        {
          name: t('UX_UI_DESIGNER'),
          img: '/img/openart-image_vGkkH9SM_1715791353615_raw 1.jpg'
        },
        {
          name: t('CYBERSECURITY_EXPERT'),
          img: '/img/openart-image_UhNKA27w_1715791682693_raw 1.jpg'
        },
        {
          name: t('FULL_STACK_DEVELOPER'),
          img: '/img/young-adult-programmer-typing-computer-office-generated-by-ai 1.jpg'
        },
        {
          name: t('SOFTWARE_DEVELOPER'),
          img: '/img/openart-image_QMcNbC6M_1715792822145_raw 1.jpg'
        },
        {
          name: t('DATABASE_EXPERT'),
          img: '/img/openart-image_YyQnCnLS_1715793412802_raw 1.jpg'
        }
      ];
    // Componente PositionsApplyContainer
    function PositionsApplyContainer({ img, text }) {
        return (
            <div className="positionsApplyContainer">
                <img src={`${img}`} alt="" className="positionsApplyImgBackground" />
                <div className="positionsApplyBackground">

                    <p className="positionsApplyText">{text}</p>
                </div>
            </div>
        )
    }


    return (
        <section className="positionsApplySection">
            <h2 className="titleH2">{t(title)}</h2>
            <div className="roofsContainer">
                {positionsApply.map((data, index) => (
                    <PositionsApplyContainer
                        key={index}
                        img={data.img}
                        text={data.name}
                    />
                ))}
            </div>
        </section>
    )
};

export default PositionsApplySection;
