import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import './erp.css'
import Header from "../../../components/header/header";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import Footer from "../../../components/footer/footer";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from '../../../components/buttonLink/buttonLink.jsx';


const ERP_MoreServices = () => {
    const { t } = useTranslation();
    const servicesData = [
        {
            translateTitle: t('MORE_SERVICE_ERP_SUBTITLE1'),
            translateTitle2: t('MORE_SERVICE_ERP_SUBTITLE2'),
            className: 'VMA',
            url: '',
        }
    ];
    const cardBenefitsData = [
        {
            title: t('MORE_SERVICE_ERP_FINANCE_MANAGEMENT'),
            text: t('MORE_SERVICE_ERP_FINANCE_MANAGEMENT_DESC'),
            icon: 'money_Icon.svg'
        },
        {
            title: t('MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION'),
            text: t('MORE_SERVICE_ERP_SUPPLY_CHAIN_OPTIMIZATION_DESC'),
            icon: 'transport_Icon.svg'
        },
        {
            title: t('MORE_SERVICE_ERP_HUMAN_RESOURCES'),
            text: t('MORE_SERVICE_ERP_HUMAN_RESOURCES_DESC'),
            icon: 'HR_Icon.svg'
        },
    ];
    const processData = [
        {
            title: t('MORE_SERVICE_ERP_ASSESS_NEEDS'),
            text: t('MORE_SERVICE_ERP_ASSESS_NEEDS_DESC'),
            img: 'Default_Generate_a_realistic_image_of_a_corporate_setting_with_1 2.jpg'
        },
        {
            title: t('MORE_SERVICE_ERP_TAILOR_SOLUTION'),
            text: t('MORE_SERVICE_ERP_TAILOR_SOLUTION_DESC'),
            img: 'busy-businessman-typing-computer-keyboard-indoors-night-generated-by-artificial-intelligence 1.jpg'
        },
        {
            title: t('MORE_SERVICE_ERP_IMPLEMENT_TRAIN'),
            text: t('MORE_SERVICE_ERP_IMPLEMENT_TRAIN_DESC'),
            img: 'hombre-pasando-rato-robot 1.jpg'
        },
        {
            title: t('MORE_SERVICE_ERP_ONGOING_SUPPORT'),
            text: t('MORE_SERVICE_ERP_ONGOING_SUPPORT_DESC'),
            img: 'portrait-man-working-as-telemarketer 1.jpg'
        },
    ]

    const keyData = [
        {
            title: t('MORE_SERVICE_ERP_CENTRALIZED_DATA_MANAGEMENT'),
        },
        {
            title: t('MORE_SERVICE_ERP_IMPROVED_EFFICIENCY_PRODUCTIVITY'),
        },
        {
            title: t('MORE_SERVICE_ERP_REAL_TIME_REPORTING_ANALYTICS'),
        },
        {
            title: t('MORE_SERVICE_ERP_SCALABLE_SOLUTIONS_GROWING_BUSINESSES'),
        },
    ]


    const TargetComponent = ({ img, text, className, title }) => {
        return (
            //Add a roofsContainer as a container of these targets
            <div className={`targetComponentContainer ${className}`}>
                <img src={`/img/${img}`} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p className="textBlack">{text}</p>
                </div>
            </div>
        )
    }



    return (
        <>
            <Header />
            <main className="ERPMainContainer">
                <MainTitleComponent
                    title={t('MORE_SERVICE_ERP_TITLE')}
                />
                {servicesData.map((service, index) => (
                    <BannerComponent
                        key={index}
                        translateTitle={service.translateTitle}
                        translateTitle2={service.translateTitle2}
                        className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
                        index={index}
                        classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                        translateButton={t('MORE_SERVICE_ERP_CTA')}
                        url={'https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/'}
                        blank={true}
                    />
                ))}
                <p className="ERPText">{t('MORE_SERVICE_ERP_DESCRIPTION')}</p>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('MORE_SERVICE_ERP_KEY_FEATURES_TITLE')}
                        cardsData={cardBenefitsData}
                    />
                </section>
                <section className="stepsProcessSection">
                    <h2 className="title">{t('MORE_SERVICE_ERP_PROCESS_TITLE')}</h2>
                    <div className="stepProcessContainer">
                        {processData.map((process, index) => {
                            return (
                                <TargetComponent
                                    key={index}
                                    title={process.title}
                                    text={process.text}
                                    img={process.img}
                                />
                            )
                        })}
                    </div>
                </section>
                <section className="keyFeaturesSection">
                    <h2 className="title">{t('MORE_SERVICE_ERP_KEY_FEATURES_TITLE')}</h2>
                    <div className="keyFeaturesContainer">
                        {keyData.map((benefit, index) => {
                            return (<div className="keyBeneffitsContainer" key={index}>
                                <p>{t(benefit.title)}</p>
                            </div>)
                        })
                        }
                    </div>
                </section>
                <div className="Finalbtn">
                    <ButtonLinkComponent
                        translateButton={'MORE_SERVICE_ECOMMERCE_CTA'}
                        url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                        blank={true}
                    />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ERP_MoreServices;
