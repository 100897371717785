import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import './crm.css'
import Header from "../../../components/header/header";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import Footer from "../../../components/footer/footer";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from '../../../components/buttonLink/buttonLink.jsx';

const CRM_MoreServices = () => {
    const { t } = useTranslation();
    const servicesData = [
        {
            translateTitle: t('MORE_SERVICE_CRM_SUBTITLE'),
            className: 'VMA',
            url: '',
        }
    ];

    const cardBenefitsData = [
        {
            title: t('MORE_SERVICE_CRM_LEAD_MANAGEMENT'),
            text: t('MORE_SERVICE_CRM_LEAD_MANAGEMENT_DESC'),
            icon: 'investments.svg'
        },
        {
            title: t('MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING'),
            text: t('MORE_SERVICE_CRM_CUSTOMER_INTERACTION_TRACKING_DESC'),
            icon: 'interaction_tracking.svg'
        },
        {
            title: t('MORE_SERVICE_CRM_SALES_AUTOMATION'),
            text: t('MORE_SERVICE_CRM_SALES_AUTOMATION_DESC'),
            icon: 'sales_automation.svg'
        },
        {
            title: t('MORE_SERVICE_CRM_ANALYTICS_REPORTING'),
            text: t('MORE_SERVICE_CRM_ANALYTICS_REPORTING_DESC'),
            icon: 'analytics_reporting.svg'
        },
        {
            title: t('MORE_SERVICE_CRM_CUSTOMER_SUPPORT'),
            text: t('MORE_SERVICE_CRM_CUSTOMER_SUPPORT_DESC'),
            icon: 'customer_support.svg'
        }
    ];

    const processData = [
        {
            title: t('MORE_SERVICE_CRM_UNDERSTAND_NEEDS'),
            text: t('MORE_SERVICE_CRM_UNDERSTAND_NEEDS_DESC'),
            img: 'woman-man-shake-hands-partners-made-deal-created-with-generative-ai-technology 1.jpg'
        },
        {
            title: t('MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION'),
            text: t('MORE_SERVICE_CRM_CUSTOMIZE_SOLUTION_DESC'),
            img: 'young-adult-businessman-working-late-night-computer-technology-generated-by-artificial-intelligence 1.jpg'
        },
        {
            title: t('MORE_SERVICE_CRM_IMPLEMENT_TRAIN'),
            text: t('MORE_SERVICE_CRM_IMPLEMENT_TRAIN_DESC'),
            img: 'DreamShaper_v7_white_robot_giving_advices_to_a_business_man_on_3 2.jpg'
        },
        {
            title: t('MORE_SERVICE_CRM_ONGOING_SUPPORT'),
            text: t('MORE_SERVICE_CRM_ONGOING_SUPPORT_DESC'),
            img: 'dia-internacional-educacion-estilo-futurista-robots 1.jpg'
        }
    ];

    const keyData = [
        {
            title: t('MORE_SERVICE_CRM_CENTRALIZED_CUSTOMER_DATA'),
        },
        {
            title: t('MORE_SERVICE_CRM_ENHANCED_CUSTOMER_ENGAGEMENT'),
        },
        {
            title: t('MORE_SERVICE_CRM_IMPROVED_SALES_EFFICIENCY'),
        },
        {
            title: t('MORE_SERVICE_CRM_DATA_DRIVEN_INSIGHTS'),
        },
    ];

    const TargetComponent = ({ img, text, className, title }) => {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={`/img/${img}`} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p className="textBlack">{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="CRMMainContainer">
                <MainTitleComponent
                    title={t('MORE_SERVICE_CRM_TITLE')}
                />
                {servicesData.map((service, index) => (
                    <BannerComponent
                        key={index}
                        translateTitle={service.translateTitle}
                        className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
                        index={index}
                        classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                        translateButton={t('MORE_SERVICE_CRM_CTA')}
                        url={'https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/'}
                        blank={true}
                    />
                ))}
                <p className="CRMText">{t('MORE_SERVICE_CRM_DESCRIPTION')}</p>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('MORE_SERVICE_CRM_KEY_FEATURES_TITLE')}
                        cardsData={cardBenefitsData}
                    />
                </section>
                <section className="stepsProcessSection">
                    <h2 className="title">{t('MORE_SERVICE_CRM_PROCESS_TITLE')}</h2>
                    <div className="stepProcessContainer">
                        {processData.map((process, index) => {
                            return (
                                <TargetComponent
                                    key={index}
                                    title={process.title}
                                    text={process.text}
                                    img={process.img}
                                />
                            )
                        })}
                    </div>
                </section>
                <section className="keyFeaturesSection">
                    <h2 className="title">{t('MORE_SERVICE_CRM_BENEFITS_TITLE')}</h2>
                    <div className="keyFeaturesContainer">
                        {keyData.map((benefit, index) => {
                            return (
                                <div className="keyBenefitsContainer" key={index}>
                                    <p>{benefit.title}</p>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <div className="Finalbtn">
                    <ButtonLinkComponent
                        translateButton={'MORE_SERVICE_ECOMMERCE_CTA'}
                        url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                        blank={true}
                    />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default CRM_MoreServices;
