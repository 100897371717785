import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import './EventCardsComponent.css';
import ButtonLinkComponent from "../buttonLink/buttonLink";

const EventCardsComponent = ({ title, cardsData }) => {
    const { t, i18n } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const displayedCards = showAll ? cardsData : cardsData.slice(0, 3);
    const isSingleCard = displayedCards.length === 1;

    function extractTextFromReactElement(element) {
        if (typeof element === 'string') {
            return element;
        }
        if (Array.isArray(element)) {
            return element.map(extractTextFromReactElement).join(' ');
        }
        if (element && element) {
            return extractTextFromReactElement(element);
        }
        return '';
    }

    function truncateText(text, charLimit) {
        if (text.length > charLimit) {
            return text.slice(0, charLimit) + '';
        }
        return text;
    }


    function extractAndTruncateText(articleBody, wordLimit) {
        let firstParagraph = '';

        if (Array.isArray(articleBody)) {
            if (articleBody.length > 0) {
                firstParagraph = articleBody[0] ? extractTextFromReactElement(articleBody[0]) : articleBody[0];
            }
        } else if (articleBody && articleBody) {
            firstParagraph = extractTextFromReactElement(articleBody);
        } else if (typeof articleBody === 'string') {
            firstParagraph = articleBody;
        }

        return truncateText(firstParagraph, wordLimit);
    }

    return (
        <section className="EventCardsComponentSection">
            <h2 className="title">{title}</h2>
            <div className={`cardsContainer ${isSingleCard ? 'singleCard' : ''}`}>
                {displayedCards.map((data, index) => {
                    const dateFrom = new Date(data.DETAILS_DATE_FROM);
                    const dateTo = new Date(data.DETAILS_DATE_TO);
                    
                    const dayOfWeek = dateFrom.toLocaleString('default', { weekday: 'short' });
                    const month = dateFrom.toLocaleString('default', { month: 'short' });
                    const day = dateFrom.getDate();

                    const daySuffix = (day) => {
                        if (day > 3 && day < 21) return 'th';
                        switch (day % 10) {
                            case 1: return "st";
                            case 2: return "nd";
                            case 3: return "rd";
                            default: return "th";
                        }
                    };
                    const year = dateFrom.getFullYear();

                    dateFrom.setHours(dateFrom.getHours() + 1);
                    dateTo.setHours(dateTo.getHours() + 1);


                    const formatTime = (date) => {
                        let hours = date.getHours();
                        const minutes = date.getMinutes().toString().padStart(2, '0');
                        const ampm = hours >= 12 ? 'pm' : 'am';
                        hours = hours % 12;
                        hours = hours ? hours : 12;
                        return `${hours}:${minutes} ${ampm}`;
                    };

                    const timeFrom = formatTime(dateFrom);
                    const timeTo = formatTime(dateTo);
                    return (
                        <div className={`eventContainer ${isSingleCard ? 'singleCardContainer' : ''}`} key={index}>
                            {isSingleCard && (
                                <img src="/img/VectorLive.svg" alt="Live Icon" className="liveIcon" />
                            )}
                            <div>
                                <div className="mainEventInfo">
                                    <div className="topLeftCard">
                                        <p className="topText">{day}</p>
                                        <div className="lineWhite"></div>
                                        <p className="bottomText">{month}</p>
                                    </div>
                                    <div className="eventTitle">
                                        {data.TITLE && <h3 className="titleText">{i18n.language === 'es' ? data.TITLE : data.TITLE_EN}</h3>}
                                    </div>
                                </div>
                                <p className="eventDescription">{extractAndTruncateText(i18n.language === 'es' ? data.DESCRIPCION : data.DESCRIPTION_EN, 90)}</p>
                            </div>
                            <div>
                                <div className="eventDetails">
                                    <div className="eventDetail">
                                        <img src={"/img/VectorCalendarCheck.svg"} alt="" className="eventLogo" />
                                        <p className="eventDate">{`${dayOfWeek}, ${month} ${day}${daySuffix(day)}, ${year}`}</p>
                                    </div>
                                    <div className="eventDetail">
                                        <img src={"/img/VectorClock.svg"} alt="" className="eventLogo" />
                                        <p className="eventDate">{`${timeFrom} to ${timeTo}`}</p>
                                    </div>
                                    <div className="eventDetail">
                                        <img src={"/img/VectorPerson.svg"} alt="" className="eventLogo" />
                                        <p className="eventDate">{data.DETAILS_PRESENTER}</p>
                                    </div>
                                </div>
                                <ButtonLinkComponent
                                    url={data.BOTONURL}
                                    translateButton={data.BUTTONTEXT}
                                    className="eventButton"
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
            {cardsData.length > 3 && (
                <div className="buttonContainer">
                    <button
                        className="watchMoreButton"
                        onClick={handleShowMore}
                    >
                        {showAll ? t('BUTTON_WATCH_LESS') : t('BUTTON_WATCH_MORE')}
                    </button>
                </div>
            )}
        </section>
    );
};


export default EventCardsComponent;
