export const filterByCountryObject = (data, value) => {
    const lowerCaseValue = value.toLowerCase();
    return data.filter(item =>
        item.country.toLowerCase().includes(lowerCaseValue) ||
        item.country_EN.toLowerCase().includes(lowerCaseValue) ||
        item.code.replace('+', '').includes(lowerCaseValue)
    );
};

export const filterByFieldOption = (data, value) => {
    if (data) {
        return data.filter(item =>
            item.toLowerCase().includes(value.toLowerCase())
        );
    }
};

export const filterByFieldOptionCountries = (data, value) => {
    if (data) {
        return data.filter(item =>
            item.country.toLowerCase().includes(value.toLowerCase()) ||
            item.spanishName.toLowerCase().includes(value.toLowerCase()) 
        );
    }
};

export const filterByFieldOptionMultiSelect = (data, value) => {
    if (data) {
        const savedLanguage = localStorage.getItem('language');
        const searchValue = value.toLowerCase();

        return data.filter(item => {
            // Selecciona el nombre correcto según el idioma
            const nameField = savedLanguage === 'es' ? item.Service_Name_ES : item.Service_Name_EN;

            // Verifica si el nombre incluye el término de búsqueda
            return nameField.toLowerCase().includes(searchValue);
        });
    }
};