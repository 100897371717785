import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import './ecommerce.css'
import Header from "../../../components/header/header";
import MainTitleComponent from "../../../components/mainTitleComponent/mainTitleComponent";
import Footer from "../../../components/footer/footer";
import BannerComponent from "../../../components/bannerComponent/bannerComponent";
import BenefitsCardsComponent from "../../../components/benefitsCardsComponent/benefitsCardsComponent";
import ButtonLinkComponent from '../../../components/buttonLink/buttonLink.jsx';

const ECommerce_MoreServices = () => {
    const { t } = useTranslation();
    const servicesData = [
        {
            translateTitle: t('MORE_SERVICE_ECOMMERCE_SUBTITLE'),
            className: 'VMA',
            url: '',
        }
    ];

    const cardBenefitsData = [
        {
            title: t('MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS'),
            text: t('MORE_SERVICE_ECOMMERCE_CUSTOM_CATALOGS_DESC'),
            icon: 'custom_catalogs.svg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING'),
            text: t('MORE_SERVICE_ECOMMERCE_AUTOMATED_ORDERING_DESC'),
            icon: 'automated_ordering.svg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT'),
            text: t('MORE_SERVICE_ECOMMERCE_INVENTORY_MANAGEMENT_DESC'),
            icon: 'inventory_management.svg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS'),
            text: t('MORE_SERVICE_ECOMMERCE_FLEXIBLE_PAYMENT_OPTIONS_DESC'),
            icon: 'flexible_payment_options.svg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING'),
            text: t('MORE_SERVICE_ECOMMERCE_ANALYTICS_REPORTING_DESC'),
            icon: 'analytics_reporting.svg'
        }
    ];

    const processData = [
        {
            title: t('MORE_SERVICE_ECOMMERCE_CONSULTATION'),
            text: t('MORE_SERVICE_ECOMMERCE_CONSULTATION_DESC'),
            img: 'DreamShaper_v7_virtual_assistant_that_help_the_companies_to_in_3 3.jpg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN'),
            text: t('MORE_SERVICE_ECOMMERCE_SOLUTION_DESIGN_DESC'),
            img: 'young-adult-programmer-typing-computer-office-generated-by-ai 3.jpg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION'),
            text: t('MORE_SERVICE_ECOMMERCE_SEAMLESS_INTEGRATION_DESC'),
            img: 'contact us 1.jpg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_STAFF_TRAINING'),
            text: t('MORE_SERVICE_ECOMMERCE_STAFF_TRAINING_DESC'),
            img: 'DreamShaper_v7_Robot_doing_repetitive_tasks_on_a_computer_serv_3 3.jpg'
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT'),
            text: t('MORE_SERVICE_ECOMMERCE_LAUNCH_SUPPORT_DESC'),
            img: 'view-people-addicted-their-smartphone-looking-scrolling-through-screens 1.jpg'
        }
    ];

    const keyData = [
        {
            title: t('MORE_SERVICE_ECOMMERCE_SIMPLIFIED_BULK_ORDERING'),
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_EFFICIENT_INVENTORY_MANAGEMENT'),
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_SEAMLESS_TRANSACTIONS'),
        },
        {
            title: t('MORE_SERVICE_ECOMMERCE_ENHANCED_CUSTOMER_EXPERIENCE'),
        },
    ];

    const TargetComponent = ({ img, text, className, title }) => {
        return (
            <div className={`targetComponentContainer ${className}`}>
                <img src={`/img/${img}`} alt="" />
                <div className="mainText">
                    <h1 className="titleH3Bold">{title}</h1>
                    <p className="textBlack">{text}</p>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <main className="ECommerceMainContainer">
                <MainTitleComponent
                    title={t('MORE_SERVICE_ECOMMERCE_TITLE')}
                />
                {servicesData.map((service, index) => (
                    <BannerComponent
                        key={index}
                        translateTitle={service.translateTitle}
                        className={`${service.className} ${index % 2 === 1 ? 'second' : ''}`}
                        index={index}
                        classNameSide={`${index % 2 === 1 ? 'r' : 'l'}`}
                        translateButton={t('MORE_SERVICE_ECOMMERCE_CTA')}
                        url={'https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/'}
                        blank={true}
                    />
                ))}
                <p className="ECommerceText">{t('MORE_SERVICE_ECOMMERCE_DESCRIPTION')}</p>
                <section className="keyServicesSection">
                    <BenefitsCardsComponent
                        title={t('MORE_SERVICE_ECOMMERCE_KEY_FEATURES_TITLE')}
                        cardsData={cardBenefitsData}
                    />
                </section>
                <section className="stepsProcessSection">
                    <h2 className="title">{t('MORE_SERVICE_ECOMMERCE_PROCESS_TITLE')}</h2>
                    <div className="stepProcessContainer">
                        {processData.map((process, index) => {
                            return (
                                <TargetComponent
                                    key={index}
                                    title={process.title}
                                    text={process.text}
                                    img={process.img}
                                />
                            )
                        })}
                    </div>
                </section>
                <section className="keyFeaturesSection">
                    <h2 className="title">{t('MORE_SERVICE_ECOMMERCE_BENEFITS_TITLE')}</h2>
                    <div className="keyFeaturesContainer">
                        {keyData.map((benefit, index) => {
                            return (
                                <div className="keyBenefitsContainer" key={index}>
                                    <p>{benefit.title}</p>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <div className="Finalbtn">
                    <ButtonLinkComponent
                        translateButton={'MORE_SERVICE_ECOMMERCE_CTA'}
                        url={"https://outlook.office365.com/book/G07e86683359a41f58322cab8b2047723@5igsolutions.com/"}
                        blank={true}
                    />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ECommerce_MoreServices;
